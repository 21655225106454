/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  FormFeedback,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import Moment from 'moment';
import ReactToPrint from 'react-to-print';
import { isThisTypeNode } from 'typescript';


      //test in stock
      //sales table,stock table;

      
      



class AddNewSales extends React.Component {
  
  state = {
    loading:false,

    selectedcategories1:'',
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    paymentmethodlist:[],
    unitprice1:0,
    subtotal1:0,
    productname1:'',
    quantity1:0,
    productnumbersection:1,
    remark:'',
    customername:'',
    creditcharges1:0.00,
    creditcharges2:0.00,
    creditcharges3:0.00,
    paymentmethodoneortwo:"",
    modellist:[],
    tradeinphonem:'',
    tradeinphonei:'',
    tradeinphoneb:'',
    tradeinprice:'',
    faceidhaveornot:'',
    fingerprinthaveornot:'',
    storagevalue:'',
    colorphonevalue:'',
    tradeinphonem2:'',
    tradeinphonei2:'',
    tradeinphoneb2:'',
    tradeinprice2:'',
    faceidhaveornot2:'',
    fingerprinthaveornot2:'',
    storagevalue2:'',
    colorphonevalue2:'',
    tradeinphonem3:'',
    tradeinphonei3:'',
    tradeinphoneb3:'',
    tradeinprice3:'',
    faceidhaveornot3:'',
    fingerprinthaveornot3:'',
    storagevalue3:'',
    colorphonevalue3:'',
    totalpriceall:0,
    totalprice:0,
    paymentmethodvalue:"",
    customerlist:[],
    storagelist:[],
    colorlist:[],
    commission:0,
    cashrebate:0,
    memberdiscountprice:0,
    server:localStorage.getItem('server'),
    userid:localStorage.getItem('userid'),
    invoicedate:Moment().format("DD/MM/YYYY"),
    linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    //linkurl:"https://cors-anywhere.herokuapp.com/http://pos.mzprotrading.com/api/mzprotrading.php?",
    
  };
  

  refreshallthingsale = () =>{

    for(let i = 0;i<this.state.productnumbersection;i++){
      this.setState({
        ["selectedcategories"+(i+1)]:'',
        ["productname"+(i+1)]:'',
        ["imei"+(i+1)]:'',
        ["quantity"+(i+1)]:'',
        ["unitprice"+(i+1)]:'',
        ["subtotal"+(i+1)]:'',
        ["warrantystart"+(i+1)]:'',
        ["warrantyend"+(i+1)]:'',
        ["salesdate"+(i+1)]:'',
      })
    }
    this.setState({     
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    productnumbersection:1,
    aftersubmitstatus:0,
    phonenumbervalue:'',
    customername:'',
    remark:'',
    invoicenumber:'',
    tradein:'',
    totalprice:0,
    paymentmethodoneortwo:"",
    paymentmethodvalue:"",
    memberdiscountprice:0,
    })
  }
  componentDidMount(){
    // this.getarealist();
    this.selectuser();
    //this.getcustomerlist();
    this.getinvoicenumberlist();
    this.selectcolorlist();
    this.selectstorage();
  }

  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
 
  addproductbutton = () =>{
    if(this.state.productnumbersection >= 10){

    }else{
      this.setState({productnumbersection:this.state.productnumbersection+1})    
    }    
  }

  minusproductbutton = () =>{
    if(this.state.productnumbersection <= 1){

    }else{
      this.setState({productnumbersection:this.state.productnumbersection-1})    
    }    
  }

  warrantyendchoose = (numberrow,e) =>{
    
    var timestampend = e.unix()
    var timestampstart = new Date(this.state["warrantystart"+(numberrow+1)]).getTime()/1000

    console.log("timestampend:" + timestampend)
    console.log("timestampstart:" +timestampstart )

    if(timestampend < timestampstart){
      if(window.confirm("Warranty end cannot after warranty start")){
        this.setState({["warrantyend"+(numberrow+1)]:''})
        return;
      }
    }else{
      this.setState({["warrantyend"+(numberrow+1)]:e})
    }

   
  }
  
  warrantystartchoose = (numberrow,e) =>{
    this.setState({["warrantystart"+(numberrow+1)]:e})
    this.setState({["warrantyend"+(numberrow+1)]:''})
   
  }

  getcustomerlist = (value) =>{     
    this.setState({phoneprefix:value})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-getcustomerlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({customerlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].phoneprefix == value){
            this.setState({customerlist:[...this.state.customerlist,
              { no:i+1,                
                title:json.employee[i].Contact,
                phone:json.employee[i].Contact,
                customername:json.employee[i].Name,
                memberid:json.employee[i].memberid,
                title2:json.employee[i].memberid,
                phoneprefix:json.employee[i].phoneprefix,

              },      
          
                 
            ]})
          }
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  getcustomerlistmemberid = (value) =>{     

    this.setState({findcustomer:value})

    if(value =='Member ID'){
      var urlencoded = new URLSearchParams();
      urlencoded.append("empty", '-');
      let link = this.state.linkurl +'mode=admin-getcustomerlistmemberid'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        //console.log(json)
        if(json.success == 1){
         
         console.log(json)
         this.setState({customerlist:[]});   
          var lengthemployee = Object.keys(json.employee).length;
          for(let i=0;i<lengthemployee;i++){
              this.setState({customerlist:[...this.state.customerlist,
                { no:i+1,                
                  title:json.employee[i].Contact,
                  phone:json.employee[i].Contact,
                  customername:json.employee[i].Name,
                  memberid:json.employee[i].memberid,
                  title2:json.employee[i].memberid,
                  phoneprefix:json.employee[i].phoneprefix,
                 },      
            
                   
              ]})
            }
            
           
    }
    
        // this.setState({loading:false})  
              
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }
}


  selectphonenumber = (value)=>{
    this.setState({phonenumbervalue:value})
    var lengthofphonelist = Object.keys(this.state.customerlist).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.customerlist[i].phone == value){
         this.setState({
          customername:this.state.customerlist[i].customername,
          memberid:this.state.customerlist[i].memberid,
         })
       }
      
         }  
  }

  memberidvalue=(value)=>{
    this.setState({memberid:value})
    var lengthofphonelist = Object.keys(this.state.customerlist).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.customerlist[i].memberid == value){
         this.setState({
          customername:this.state.customerlist[i].customername,
          phonenumbervalue:this.state.customerlist[i].phone,
          phoneprefix:this.state.customerlist[i].phoneprefix,
         })
       }
      
         }  
  }

  getinvoicenumberlist = () =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-getinvoicenumberlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({invoicenumberlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
        
            this.setState({invoicenumberlist:[...this.state.invoicenumberlist,
              { no:i+1,                
                title:json.employee[i].invoicenumber,
                invoicenumber:json.employee[i].invoicenumber,
              
              },      
          
                 
            ]})
          
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }
  
  getphonestocklist = () =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectphonestocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({stockphonelist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
            this.setState({stockphonelist:[...this.state.stockphonelist,
              { no:i+1,
                productname:json.employee[i].productname,   
                imei:json.employee[i].imei,             
                storagevalue:json.employee[i].storagevalue,
                colorvalue:json.employee[i].colorvalue,
                phoneremark:json.employee[i].remark,
                //fingerprintvalue:json.employee[i].fingerprintvalue,
                //brandvalue:json.employee[i].brandvalue,      
                //faceidvalue:json.employee[i].faceidvalue,
                saleprice:json.employee[i].saleprice,
                quantityvalue:json.employee[i].quantityvalue,
                purchasedate:purchasedateshow,
                statusvalue:json.employee[i].statusvalue,
                title:json.employee[i].imei,
                productid:json.employee[i].Ref,
                costprice:json.employee[i].costprice,
                repaircost:Number.parseFloat(json.employee[i].repaircost)+Number.parseFloat(json.employee[i].repaircost2)+Number.parseFloat(json.employee[i].repaircost3)+Number.parseFloat(json.employee[i].repaircost4)+Number.parseFloat(json.employee[i].repaircost5),
                typevalue:json.employee[i].typevalue,
                brandvalue:json.employee[i].brandvalue,
              },            
            ]})
          }
          
         
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  getaccessoriesstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectaccesoriesstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
      
      console.log(json)
      this.setState({stockaccessorieslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
          this.setState({stockaccessorieslist:[...this.state.stockaccessorieslist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              costprice:json.employee[i].costprice,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              repaircost:json.employee[i].repaircost+json.employee.repaircost2+json.employee.repaircost3+json.employee.repaircost4+json.employee.repaircost5,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }
  selectcolorlist =() =>{ 
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("usernamecontrol", this.state.userid);
    let link = this.state.linkurl +'mode=admin-selectcolorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({colorlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
                this.setState({colorlist:[...this.state.colorlist,
                  { no:i+1,
                    username:json.employee[i].phonecolor,
                    title:json.employee[i].phonecolor,
                    value:json.employee[i].phonecolor,
                  },            
                ]})
              
               
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  getsparepartstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectsparepartstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)2
      if(json.success == 1){
      
      console.log(json)
      this.setState({stocksparepartlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].statusvalue == "Ready For Sell"){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              costprice:json.employee[i].costprice,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              repaircost:json.employee[i].repaircost+json.employee.repaircost2+json.employee.repaircost3+json.employee.repaircost4+json.employee.repaircost5,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }
  setbackwhencnotset=(beforecategory,aftercategory,productnumber)=>{
    this.setState({["showcategories"+productnumber]:aftercategory})
  if(beforecategory=="Apple Full Set 1:Plug+Cable+Earpod"||beforecategory=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
    if(aftercategory=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
      if(this.state.productnumbersection>=productnumber+1){
        for(let i=productnumber;i<=this.state.productnumbersection-1;i++){
          this.setState({["selectedcategories"+(i)]:this.state["selectedcategories"+(i+1)]})
          this.setState({["productname"+(i)]:this.state["productname"+(i+1)]})
          this.setState({["quantity"+(i)]:this.state["quantity"+(i+1)]})
          this.setState({["subtotal"+(i)]:this.state["subtotal"+(i+1)]})
          this.setState({["fullset"+(i)]:this.state["fullset"+(i+1)]})
          this.setState({["imei"+(i)]:this.state["imei"+(i+1)]})
          this.setState({["typevalue"+(i)]:this.state["typevalue"+(i+1)]})
          this.setState({["brandvalue"+(i)]:this.state["brandvalue"+(i+1)]})
          this.setState({["productid"+(i)]:this.state["productid"+(i+1)]})
          this.setState({["unitprice"+(i)]:this.state["unitprice"+(i+1)]})
          this.setState({["productnameupload"+(i)]:this.state["productnameupload"+(i+1)]})
          this.setState({["costprice"+(i)]:this.state["costprice"+(i+1)]})
          this.setState({["repaircost"+(i)]:this.state["repaircost"+(i+1)]})
          this.setState({["commission"+(i)]:this.state["commission"+(i+1)]})
          this.setState({["warrantyduration"+(i)]:this.state["warrantyduration"+(i+1)]})
        }
          this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
          this.setState({["productname"+(this.state.productnumbersection)]:''})
          this.setState({["quantity"+(this.state.productnumbersection)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection)]:''})
          this.setState({["fullset"+(this.state.productnumbersection)]:''})
          this.setState({["imei"+(this.state.productnumbersection)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
          this.setState({["productid"+(this.state.productnumbersection)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection)]:''})
          this.setState({["disable"+(this.state.productnumbersection)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
          this.setState({["costprice"+(this.state.productnumbersection)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection)]:''})
          this.setState({["commission"+(this.state.productnumbersection)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection)]:''})
        }      
        this.setState({productnumbersection:this.state.productnumbersection-1});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Type C","Android","Cable",(productnumber+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2))     
      }
      else if(aftercategory=="Android Full Set 2: Fast Charging Plug+Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
      if(this.state.productnumbersection>=productnumber+1){
        for(let i=productnumber;i<=this.state.productnumbersection-1;i++){
          this.setState({["selectedcategories"+(i)]:this.state["selectedcategories"+(i+1)]})
          this.setState({["productname"+(i)]:this.state["productname"+(i+1)]})
          this.setState({["quantity"+(i)]:this.state["quantity"+(i+1)]})
          this.setState({["subtotal"+(i)]:this.state["subtotal"+(i+1)]})
          this.setState({["fullset"+(i)]:this.state["fullset"+(i+1)]})
          this.setState({["imei"+(i)]:this.state["imei"+(i+1)]})
          this.setState({["typevalue"+(i)]:this.state["typevalue"+(i+1)]})
          this.setState({["brandvalue"+(i)]:this.state["brandvalue"+(i+1)]})
          this.setState({["productid"+(i)]:this.state["productid"+(i+1)]})
          this.setState({["unitprice"+(i)]:this.state["unitprice"+(i+1)]})
          this.setState({["productnameupload"+(i)]:this.state["productnameupload"+(i+1)]})
          this.setState({["costprice"+(i)]:this.state["costprice"+(i+1)]})
          this.setState({["repaircost"+(i)]:this.state["repaircost"+(i+1)]})
          this.setState({["commission"+(i)]:this.state["commission"+(i+1)]})
          this.setState({["warrantyduration"+(i)]:this.state["warrantyduration"+(i+1)]})
        }
          this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
          this.setState({["productname"+(this.state.productnumbersection)]:''})
          this.setState({["quantity"+(this.state.productnumbersection)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection)]:''})
          this.setState({["fullset"+(this.state.productnumbersection)]:''})
          this.setState({["imei"+(this.state.productnumbersection)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
          this.setState({["productid"+(this.state.productnumbersection)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection)]:''})
          this.setState({["disable"+(this.state.productnumbersection)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
          this.setState({["costprice"+(this.state.productnumbersection)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection)]:''})
          this.setState({["commission"+(this.state.productnumbersection)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection)]:''})
        }
        this.setState({productnumbersection:this.state.productnumbersection-1});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Normal","Android","Cable",(productnumber+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2))    
    } 
    else if (aftercategory=="Apple Full Set 1:Plug+Cable+Earpod"){
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Normal","Apple","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Normal","Apple","Cable",(productnumber+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2));
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3)) 
    }
    else if(aftercategory=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Apple","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Type C","Apple","Cable",(productnumber+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2));
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3))   
    }
    else{
      if(this.state.productnumbersection>=productnumber+3){
        for(let i=productnumber+1;i<=this.state.productnumbersection-3;i++){
          this.setState({
            ["selectedcategories"+(i)]:this.state["selectedcategories"+(i+3)],
            ["productname"+(i)]:this.state["productname"+(i+3)],
            ["imei"+(i)]:this.state["imei"+(i+3)],
            ["quantity"+(i)]:this.state["quantity"+(i+3)],
            ["subtotal"+(i)]:this.state["subtotal"+(i+3)],
            ["fullset"+(i)]:this.state["fullset"+(i+3)],
            ["typevalue"+(i)]:this.state["typevalue"+(i+3)],
            ["brandvalue"+(i)]:this.state["brandvalue"+(i+3)],
            ["productid"+(i)]:this.state["productid"+(i+3)],
            ["unitprice"+(i)]:this.state["unitprice"+(i+3)],
            ["disable"+(i)]:false,
            ["productnameupload"+(i)]:this.state["productnameupload"+(i+3)],
            ["costprice"+(i)]:this.state["costprice"+(i+3)],
            ["repaircost"+(i)]:this.state["repaircost"+(i+3)],
            ["commission"+(i)]:this.state["commission"+(i+3)],
            ["warrantyduration"+(i)]:this.state["warrantyduration"+(i+3)],
        })
        }
        this.setState({["selectedcategories"+(this.state.productnumbersection-2)]:''})
          this.setState({["productname"+(this.state.productnumbersection-2)]:''})
          this.setState({["quantity"+(this.state.productnumbersection-2)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection-2)]:''})
          this.setState({["fullset"+(this.state.productnumbersection-2)]:''})
          this.setState({["imei"+(this.state.productnumbersection-2)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection-2)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection-2)]:''})
          this.setState({["productid"+(this.state.productnumbersection-2)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection-2)]:''})
          this.setState({["disable"+(this.state.productnumbersection-2)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection-1)]:''})
          this.setState({["costprice"+(this.state.productnumbersection-1)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection-1)]:''})
          this.setState({["commission"+(this.state.productnumbersection-1)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection-1)]:''})
        this.setState({["selectedcategories"+(this.state.productnumbersection-1)]:''})
          this.setState({["productname"+(this.state.productnumbersection-1)]:''})
          this.setState({["quantity"+(this.state.productnumbersection-1)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection-1)]:''})
          this.setState({["fullset"+(this.state.productnumbersection-1)]:''})
          this.setState({["imei"+(this.state.productnumbersection-1)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection-1)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection-1)]:''})
          this.setState({["productid"+(this.state.productnumbersection-1)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection-1)]:''})
          this.setState({["disable"+(this.state.productnumbersection-1)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection-1)]:''})
          this.setState({["costprice"+(this.state.productnumbersection-1)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection-1)]:''})
          this.setState({["commission"+(this.state.productnumbersection-1)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection-1)]:''})
          this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
          this.setState({["productname"+(this.state.productnumbersection)]:''})
          this.setState({["quantity"+(this.state.productnumbersection)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection)]:''})
          this.setState({["fullset"+(this.state.productnumbersection)]:''})
          this.setState({["imei"+(this.state.productnumbersection)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
          this.setState({["productid"+(this.state.productnumbersection)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection)]:''})
          this.setState({["disable"+(this.state.productnumbersection)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
          this.setState({["costprice"+(this.state.productnumbersection)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection)]:''})
          this.setState({["commission"+(this.state.productnumbersection)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection)]:''})
      }
          this.setState({["productname"+productnumber]:''})
          this.setState({["quantity"+productnumber]:''})
          this.setState({["subtotal"+productnumber]:''})
          this.setState({["fullset"+productnumber]:''})
          this.setState({["imei"+productnumber]:''})
          this.setState({["typevalue"+productnumber]:''})
          this.setState({["brandvalue"+productnumber]:''})
          this.setState({["productid"+productnumber]:''})
          this.setState({["unitprice"+productnumber]:''})
          this.setState({["disable"+productnumber]:false})
          this.setState({["productnameupload"+productnumber]:''})
          this.setState({["costprice"+productnumber]:''})
          this.setState({["repaircost"+productnumber]:''})
          this.setState({["commission"+productnumber]:''})
          this.setState({["selectedcategories"+productnumber]:aftercategory})
          this.setState({["showcategories"+productnumber]:aftercategory})
          if(aftercategory == "Phone"){
            this.setState(
              {
              ["productname"+productnumber]:'',
              ["unitprice"+productnumber]:0,
              ["quantity"+productnumber]:0,
              ["subtotal"+productnumber]:0,
            })
            this.getphonestocklist();
          }else if(aftercategory =="Accessories"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getaccessoriesstocklist();
          }else if(aftercategory =="Spare Part"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getsparepartstocklist();
          }
    this.setState({productnumbersection:this.state.productnumbersection-3});


  }
}
  else if(beforecategory=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"||beforecategory=="Android Full Set 2: Fast Charging Plug+Cable"){
    if(aftercategory=="Apple Full Set 1:Plug+Cable+Earpod"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
        this.setState({productnumbersection:this.state.productnumbersection+2});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
        this.checksetaccessorieshaveornot("Normal","Apple","Charger",productnumber); 
        this.checksetaccessorieshaveornot("Normal","Apple","Cable",(productnumber+1)); 
        this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2));
        this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3))     
      }
      else if(aftercategory=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
        this.setState({productnumbersection:this.state.productnumbersection+2});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
        this.checksetaccessorieshaveornot("Fast Charger","Apple","Charger",productnumber); 
        this.checksetaccessorieshaveornot("Type C","Apple","Cable",(productnumber+1)); 
        this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2)); 
        this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3))
    } 
    else if (aftercategory=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,             
      })
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Type C","Android","Cable",(productnumber+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2)) 
    }
    else if(aftercategory=="Android Full Set 2: Fast Charging Plug+Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Normal","Android","Cable",(productnumber+1)); 
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2))  
    }
    else{
      if(this.state.productnumbersection>productnumber){
        if(this.state.productnumbersection>=productnumber+2){
          for(let i=productnumber;i<=this.state.productnumbersection-2;i++){
            this.setState({["selectedcategories"+(i)]:this.state["selectedcategories"+(i+2)]})
            this.setState({["productname"+(i)]:this.state["productname"+(i+2)]})
            this.setState({["quantity"+(i)]:this.state["quantity"+(i+2)]})
            this.setState({["subtotal"+(i)]:this.state["subtotal"+(i+2)]})
            this.setState({["fullset"+(i)]:this.state["fullset"+(i+2)]})
            this.setState({["imei"+(i)]:this.state["imei"+(i+1)]})
            this.setState({["typevalue"+(i)]:this.state["typevalue"+(i+2)]})
            this.setState({["brandvalue"+(i)]:this.state["brandvalue"+(i+2)]})
            this.setState({["productid"+(i)]:this.state["productid"+(i+2)]})
            this.setState({["unitprice"+(i)]:this.state["unitprice"+(i+2)]})
            this.setState({["productnameupload"+(i)]:this.state["productnameupload"+(i+2)]})
            this.setState({["costprice"+(i)]:this.state["costprice"+(i+2)]})
            this.setState({["repaircost"+(i)]:this.state["repaircost"+(i+2)]})
            this.setState({["commission"+(i)]:this.state["commission"+(i+2)]})
          }
            this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
            this.setState({["productname"+(this.state.productnumbersection)]:''})
            this.setState({["quantity"+(this.state.productnumbersection)]:''})
            this.setState({["subtotal"+(this.state.productnumbersection)]:''})
            this.setState({["fullset"+(this.state.productnumbersection)]:''})
            this.setState({["imei"+(this.state.productnumbersection)]:''})
            this.setState({["typevalue"+(this.state.productnumbersection)]:''})
            this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
            this.setState({["productid"+(this.state.productnumbersection)]:''})
            this.setState({["unitprice"+(this.state.productnumbersection)]:''})
            this.setState({["disable"+(this.state.productnumbersection)]:false})
            this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
            this.setState({["costprice"+(this.state.productnumbersection)]:''})
            this.setState({["repaircost"+(this.state.productnumbersection)]:''})
            this.setState({["commission"+(this.state.productnumbersection)]:''})
            this.setState({["selectedcategories"+(this.state.productnumbersection-1)]:''})
            this.setState({["productname"+(this.state.productnumbersection-1)]:''})
            this.setState({["quantity"+(this.state.productnumbersection-1)]:''})
            this.setState({["subtotal"+(this.state.productnumbersection-1)]:''})
            this.setState({["fullset"+(this.state.productnumbersection-1)]:''})
            this.setState({["imei"+(this.state.productnumbersection-1)]:''})
            this.setState({["typevalue"+(this.state.productnumbersection-1)]:''})
            this.setState({["brandvalue"+(this.state.productnumbersection-1)]:''})
            this.setState({["productid"+(this.state.productnumbersection-1)]:''})
            this.setState({["unitprice"+(this.state.productnumbersection-1)]:''})
            this.setState({["disable"+(this.state.productnumbersection-1)]:false})
            this.setState({["productnameupload"+(this.state.productnumbersection-1)]:''})
            this.setState({["costprice"+(this.state.productnumbersection-1)]:''})
            this.setState({["repaircost"+(this.state.productnumbersection-1)]:''})
            this.setState({["commission"+(this.state.productnumbersection-1)]:''})
        }
    }
    this.setState({["selectedcategories"+productnumber]:''})
          this.setState({["productname"+productnumber]:''})
          this.setState({["quantity"+productnumber]:''})
          this.setState({["subtotal"+productnumber]:''})
          this.setState({["fullset"+productnumber]:''})
          this.setState({["imei"+productnumber]:''})
          this.setState({["typevalue"+productnumber]:''})
          this.setState({["brandvalue"+productnumber]:''})
          this.setState({["productid"+productnumber]:''})
          this.setState({["unitprice"+productnumber]:''})
          this.setState({["disable"+productnumber]:false})
          this.setState({["productnameupload"+productnumber]:''})
          this.setState({["costprice"+productnumber]:''})
          this.setState({["repaircost"+productnumber]:''})
          this.setState({["commission"+productnumber]:''})
      this.setState({productnumbersection:this.state.productnumbersection-2});
      this.setState({["selectedcategories"+productnumber]:aftercategory})
          this.setState({["showcategories"+productnumber]:aftercategory})
          if(aftercategory == "Phone"){
            this.setState(
              {
              ["productname"+productnumber]:'',
              ["unitprice"+productnumber]:0,
              ["quantity"+productnumber]:0,
              ["subtotal"+productnumber]:0,
            })
            this.getphonestocklist();
          }else if(aftercategory =="Accessories"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getaccessoriesstocklist();
          }else if(aftercategory =="Spare Part"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getsparepartstocklist();
          }
  }
  }

  }

  checksetaccessorieshaveornot=(typevalue,brandvalue,productname,productnumber)=>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("typevalue", typevalue);
    urlencoded.append("brandvalue", brandvalue);
    urlencoded.append("productname", productname);
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-checksetaccessorieshaveornot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)       
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
        
          this.setState(
            {            
              ["selectedcategories"+productnumber]:"Accessories",
              ["imei"+productnumber]:"-",
              ["typevalue"+productnumber]:json.employee[i].typevalue,
              ["brandvalue"+productnumber]:json.employee[i].brandvalue,
              ["productid"+productnumber]:json.employee[i].Ref,
              ["unitprice"+productnumber]:0,
              ["productname"+productnumber]:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              ["productnameupload"+productnumber]:json.employee[i].productname,
              ["quantity"+productnumber]:1,
              ["subtotal"+productnumber]:0,
              ["costprice"+productnumber]:json.employee[i].costprice,
              ["repaircost"+productnumber]:json.employee[i].repaircost,
              ["salesdate"+productnumber]:Moment(),
              ["warrantystart"+productnumber]:Moment(),
              ["warrantyduration"+productnumber]:"No Warranty",
              ["disable"+productnumber]:true,
              ["colorbackground"+productnumber]:"#f5f5f5",
              ["failornot" + productnumber]:false,
              ["fullset" + productnumber]:1,
          })
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
      window.confirm("No enough " + productname +" stock, please check again")
     this.setState({loading:false,["failornot" + productnumber]:true,})    
     this.setState({productnumbersection:1});
     window.location.reload();
      
   console.log(json)
      }
     })   
  }

  selectedcategoriesfunction = (numberrow,value) =>{
    if(this.state["showcategories"+numberrow]=="Apple Full Set 1:Plug+Cable+Earpod"||this.state["showcategories"+numberrow]=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"||this.state["showcategories"+numberrow]=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"||this.state["showcategories"+numberrow]=="Android Full Set 2: Fast Charging Plug+Cable"){
      this.setbackwhencnotset(this.state["showcategories"+numberrow],value,numberrow)
    }
    else{
    this.setState({["selectedcategories"+numberrow]:value})
    this.setState({["showcategories"+numberrow]:value})
    if(value == "Phone"){
      this.setState(
        {
        ["productname"+numberrow]:'',
        ["unitprice"+numberrow]:0,
        ["quantity"+numberrow]:0,
        ["subtotal"+numberrow]:0,
      })
      this.getphonestocklist();
    }else if(value =="Accessories"){
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:0,
      })
      this.getaccessoriesstocklist();
    }else if(value =="Spare Part"){
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:0,
      })
      this.getsparepartstocklist();
    }else if(value == "Apple Full Set 1:Plug+Cable+Earpod"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,       
      })
      this.setState({productnumbersection:(this.state.productnumbersection+3)});
      this.checksetaccessorieshaveornot("Normal","Apple","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Normal","Apple","Cable",(numberrow+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(numberrow+2)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(numberrow+3))
      



    }else if(value == "Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,        
      })
       this.setState({productnumbersection:this.state.productnumbersection+3});
      this.checksetaccessorieshaveornot("Fast Charger","Apple","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Type C","Apple","Cable",(numberrow+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(numberrow+2));
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(numberrow+3))    


    }else if(value == "Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,             
      })
      this.setState({productnumbersection:this.state.productnumbersection+2});
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Type C","Android","Cable",(numberrow+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(numberrow+2))   


    }else if(value == "Android Full Set 2: Fast Charging Plug+Cable"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,        
      })
      this.setState({productnumbersection:this.state.productnumbersection+2});
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Normal","Android","Cable",(numberrow+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(numberrow+2))    
    }
  }
  }

  selectedimeifunction = (numberrow,value) =>{
    this.setState({["imei"+numberrow]:value})
    var lengthofphonelist = Object.keys(this.state.stockphonelist).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.stockphonelist[i].imei == value){
         this.setState({
          ["productname"+numberrow]:this.state.stockphonelist[i].productname ,
          ["productnameupload"+numberrow]:this.state.stockphonelist[i].productname ,
          ["unitprice"+numberrow]:this.state.stockphonelist[i].saleprice,
          ["productid"+numberrow]:this.state.stockphonelist[i].productid,
          ["imei"+numberrow]:this.state.stockphonelist[i].imei,
          ["itemremark"+numberrow]:this.state.stockphonelist[i].phoneremark,
          ["storage"+numberrow]:this.state.stockphonelist[i].storagevalue,
          ["quantity"+numberrow]:1,
          ["subtotal"+numberrow]:this.state.stockphonelist[i].saleprice,
          ["maxquantity" + numberrow] : this.state.stockphonelist[i].quantityvalue,
          ["costprice" + numberrow] : this.state.stockphonelist[i].costprice,
          ["repaircost" + numberrow] : this.state.stockphonelist[i].repaircost,
          ["typevalue" + numberrow] : this.state.stockphonelist[i].typevalue,
          ["brandvalue" + numberrow] : this.state.stockphonelist[i].brandvalue,
         })
       }
      
         }  
  }

  selectedproductnameaccessories = (numberrow,value) =>{
    this.setState({["productname"+numberrow]:value})
    var lengthofaccessorieslist = Object.keys(this.state.stockaccessorieslist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

        var nametoshow = this.state.stockaccessorieslist[i].productname + '---' +this.state.stockaccessorieslist[i].typevalue + '---' +this.state.stockaccessorieslist[i].brandvalue +'---'+ this.state.stockaccessorieslist[i].productid
       
        if(nametoshow == value){
         this.setState({
          ["productnameupload"+numberrow]:this.state.stockaccessorieslist[i].productname,
          ["unitprice"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["productid"+numberrow]:this.state.stockaccessorieslist[i].productid,
          ["quantity"+numberrow]:1,
          ["subtotal"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["typevalue"+numberrow]:this.state.stockaccessorieslist[i].typevalue ,
          ["brandvalue"+numberrow]:this.state.stockaccessorieslist[i].brandvalue ,
          ["maxquantity" + numberrow] : this.state.stockaccessorieslist[i].quantityvalue,
          ["costprice" + numberrow] : this.state.stockaccessorieslist[i].costprice,
          ["repaircost" + numberrow] : this.state.stockaccessorieslist[i].repaircost,
          ["imei" + numberrow] : '-',
         })
       }
      
         }  
  }
  
  selectedproductnamesparepart = (numberrow,value) =>{
    this.setState({["productname"+numberrow]:value})
    var lengthofaccessorieslist = Object.keys(this.state.stocksparepartlist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

        var nametoshow = this.state.stocksparepartlist[i].productname + '---' +this.state.stocksparepartlist[i].typevalue + '---' +this.state.stocksparepartlist[i].brandvalue +'---'+ this.state.stocksparepartlist[i].productid
       
      console.log("value: " + value)
      console.log("this.state.stocksparepartlist[i].productname : " + this.state.stocksparepartlist[i].productname )
      console.log("nametoshow: " + nametoshow)
        if(nametoshow == value){
         this.setState({
          ["productnameupload"+numberrow]:this.state.stocksparepartlist[i].productname,
          ["unitprice"+numberrow]:this.state.stocksparepartlist[i].saleprice,
          ["productid"+numberrow]:this.state.stocksparepartlist[i].productid,
          ["quantity"+numberrow]:1,
          ["subtotal"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["typevalue"+numberrow]:this.state.stocksparepartlist[i].typevalue ,
          ["brandvalue"+numberrow]:this.state.stocksparepartlist[i].brandvalue ,
          ["maxquantity" + numberrow] : this.state.stocksparepartlist[i].quantityvalue,
          ["costprice" + numberrow] : this.state.stocksparepartlist[i].costprice,
          ["repaircost" + numberrow] : this.state.stocksparepartlist[i].repaircost,
          ["imei" + numberrow] : '-',
         })
       }
      
         }  
  }
  
  selectuser =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
              this.selectpaymentmethod();
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  checkimei=(imei)=>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("imeisearch", imei);
    urlencoded.append("usernamecontrol", this.state.userid);
    let link = this.state.linkurl +'mode=admin-imeicheck'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        window.confirm(json.message);
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  submitnewrotforpendingtrade =() =>{ 

    var errorornot = false;
    var errormessage = "";
    var b0=false;
    var message;

      if(this.state.tradeinphonem =="" || this.state.tradeinphonem ==undefined){
        errormessage="Please fill in Phone Model"
        errorornot=true;         
      }

      if(this.state.tradeinphonei =="" || this.state.tradeinphonei ==undefined){
        errormessage="Please fill in Phone IMEI"
        errorornot=true;        
      }

      if(this.state.tradeinphoneb =="" || this.state.tradeinphoneb ==undefined){
        errormessage="Please fill in Phone Brand"
        errorornot=true;  
      }


      if(this.state.storagevalue =="" || this.state.storagevalue ==undefined){
        errormessage="Please fill in Phone Storage"
        errorornot=true;  
      } 


      if(this.state.colorphonevalue =="" || this.state.colorphonevalue ==undefined){
        errormessage="Please fill in Phone Color"+this.state.colorphonevalue
        errorornot=true;  
      }

      if(this.state.fingerprinthaveornot =="" || this.state.fingerprinthaveornot ==undefined){
        errormessage="Please fill in Phone Finger Print"
        errorornot=true;  
      } 

      if(this.state.faceidhaveornot =="" || this.state.faceidhaveornot ==undefined){
        errormessage="Please fill in Phone Face ID"
        errorornot=true;  
      }
  
    
    if( errorornot==true){
      window.scrollTo(0, 0)
      window.confirm(errormessage)      
    }else{
      
        this.setState({aftersubmitstatus:3})
        var urlencoded = new URLSearchParams();
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.invoicedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
          urlencoded.append("productname", this.state.tradeinphonem);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("brandvalue", this.state.tradeinphoneb);
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot);
          urlencoded.append("imei", this.state.tradeinphonei);
          urlencoded.append("storagevalue", this.state.storagevalue);
          urlencoded.append("colorvalue", this.state.colorphonevalue);
          urlencoded.append("tradeininvoice",this.state.invoicenumber)
          urlencoded.append("tradeinsupplier","Client")
          urlencoded.append("suppliervalue","Trade In") 
          urlencoded.append("category", "Phone");
          urlencoded.append("costprice", this.state.tradeinprice);
          //urlencoded.append("wastagevalue", this.state.wastageornot);
          if(this.state.findcustomer!="Guest"){
            urlencoded.append("suppliervalue", this.state.memberid);
            }
            else
            {
              urlencoded.append("suppliervalue", "Guest");
            }
          urlencoded.append("statusvalue", "Repair");
          urlencoded.append("remark", this.state.remark);
          urlencoded.append("usernamecontrol", this.state.userid);
          urlencoded.append("server", this.state.server);
        let link = this.state.linkurl +'mode=admin-insertstocktable'
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })

        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
          }
          else{
            b0=true;
            message=json.message;
            console.log(json)
          }
        })

        if(this.state.tradeinphonem2==""|| this.state.tradeinphonem2 ==undefined){

        }
        else{
          var urlencoded = new URLSearchParams();
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.invoicedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
          urlencoded.append("productname", this.state.tradeinphonem2);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("brandvalue", this.state.tradeinphoneb2);
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot2);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot2);
          urlencoded.append("imei", this.state.tradeinphonei2);
          urlencoded.append("storagevalue", this.state.storagevalue2);
          urlencoded.append("colorvalue", this.state.colorphonevalue2);
          urlencoded.append("tradeininvoice",this.state.invoicenumber)
          urlencoded.append("tradeinsupplier","Client")          
          urlencoded.append("category", "Phone");
          urlencoded.append("costprice", this.state.tradeinprice2);
          //urlencoded.append("wastagevalue", this.state.wastageornot);
          if(this.state.findcustomer!="Guest"){
          urlencoded.append("suppliervalue", this.state.memberid);
          }
          else
          {
            urlencoded.append("suppliervalue", "Guest");
          }
          urlencoded.append("statusvalue", "Repair");
          urlencoded.append("remark", this.state.remark);
          urlencoded.append("usernamecontrol", this.state.userid);
          urlencoded.append("server", this.state.server);
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })

        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
          }
          else{
            b0=true;
            message=json.message;
            console.log(json)
          }
        })
      }
      if(this.state.tradeinphonem3==""|| this.state.tradeinphonem3 ==undefined){

      }
      else{
        var urlencoded = new URLSearchParams();
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.invoicedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
          urlencoded.append("productname", this.state.tradeinphonem3);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("brandvalue", this.state.tradeinphoneb3);
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot3);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot3);
          urlencoded.append("imei", this.state.tradeinphonei3);
          urlencoded.append("storagevalue", this.state.storagevalue3);
          urlencoded.append("colorvalue", this.state.colorphonevalue3);
          urlencoded.append("tradeininvoice",this.state.invoicenumber)
          urlencoded.append("tradeinsupplier","Client")          
          urlencoded.append("category", "Phone");
          urlencoded.append("costprice", this.state.tradeinprice3);
          //urlencoded.append("wastagevalue", this.state.wastageornot);
          if(this.state.findcustomer!="Guest"){
            urlencoded.append("suppliervalue", this.state.memberid);
            }
            else
            {
              urlencoded.append("suppliervalue", "Guest");
            }
          urlencoded.append("statusvalue", "Repair");
          urlencoded.append("remark", this.state.remark);
          urlencoded.append("usernamecontrol", this.state.userid);
          urlencoded.append("server", this.state.server);
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })

        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
          }
          else{
            b0=true
            message=json.message;
            console.log(json)
          }
        })
      }

          if(b0 == false){
            
                this.setState({aftersubmitstatus:1})

          }
                   
          else{
               //fail submit
              this.setState({aftersubmitstatus:2})
              this.setState({loading:false}) 
              this.setState({feedbackmessage:message})
              
          }

    }

  }

  selectpaymentmethod =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectpaymentmethod'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({paymentmethodlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({paymentmethodlist:[...this.state.paymentmethodlist,
                { no:i+1,
                  paymentmethod:json.employee[i].method,
                  title:json.employee[i].method,
                  value:json.employee[i].method,
                },            
              ]})
               //console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  selectstorage =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("usernamecontrol", this.state.userid);
    let link = this.state.linkurl +'mode=admin-selectgb'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({storagelist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
            
                this.setState({storagelist:[...this.state.storagelist,
                  { no:i+1,
                    username:json.employee[i].phonestorage,
                    title:json.employee[i].phonestorage,
                    value:json.employee[i].phonestorage,
                  },            
                ]})
              
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

 quantity1changefunction = (numberrow,e) =>{
  this.setState({["quantity"+numberrow]: e.target.value})
  this.setState({["subtotal"+numberrow]:e.target.value * this.state["unitprice"+numberrow]});  
 }

 unitprice1changefunction = (numberrow,e) =>{
  this.setState({["unitprice"+numberrow]: e.target.value})
  this.setState({["subtotal"+numberrow]:e.target.value * this.state["quantity"+numberrow]});  
 }


 renderproductsection() {

  let consignmentnorow=[];
  for(let i=0;i<this.state.productnumbersection;i++){
    var numbershow = i+1
    

    

    consignmentnorow.push(
      <div>
        <Row>
        <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
        </Row>
      


      {/*product number*/}
   <Row>
     <Col md="9">
     
     <p className="h3 bold producttextcolor" style={{color:"blue",fontSize:25}}>Product {i+1}</p> 
     
     </Col>
    
   </Row>
     
    {/*Category*/}
    <Row>
    <Col md="4">
    <p className="h5">Category:</p>
         <FormGroup >
            <Autocomplete
            options={[{title:'Phone'},{title:'Accessories'},{title:'Spare Part'},{title:'Apple Full Set 1:Plug+Cable+Earpod'},{title:'Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod'},{title:'Android Full Set 1: Fast Charging Plug+Type Ｃ Cable'},{title:'Android Full Set 2: Fast Charging Plug+Cable'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.selectedcategoriesfunction(i+1,value)}
            renderInput={(params) => <TextField {...params} label={this.state["selectedcategories"+(i+1)]} variant="outlined" />}
            disableClearable={true}
          /> 
                                         
                         </FormGroup>
                         </Col>
                         </Row>
   
   {this.state["selectedcategories" +(i+1)] == 'Phone' ?
   <div>
      {/*IMEI,ProductName,UnitPrice*/}
      <Row>
       <Col md="4">
    <p className="h5">IMEI:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.stockphonelist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedimeifunction((i+1),value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
      
      
       <Col md="4">
   
   
         <p className="h5">Product Name:</p>
         <FormGroup>
           <Input
             type="text"
             value={this.state["productname"+(i+1)]}
             disabled
           />
         </FormGroup>
       </Col>
       
       <Col md="4">
         <p className="h5">Subtotal Price RM/MYR:</p>
         <FormGroup>
         <Input
             id="Shipperinput1"
             className="textcolorblack"
             type="number"
             min="0"
             onWheel={(e) => e.target.blur()}
             value={this.state["subtotal"+(i+1)]} 
             onChange={e => this.setState({["subtotal"+(i+1)]: e.target.value})}
             enabled
           />
         </FormGroup>
       </Col>
       <Col md="4">
      <p className="h5">Commission:</p>
      <FormGroup>
      <Input
          className="textcolorblack"
          type="number"
          min="0"
          onWheel={(e) => e.target.blur()}
          value={this.state["commission"+(i+1)]} 
          onChange={e => this.setState({["commission"+(i+1)]: e.target.value})}
        />
      </FormGroup>
    </Col>
    <Col md="6">
                         <p className="h5">Warranty Duration:</p>
                         <Autocomplete
                           options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({["warrantyduration"+(i+1)]:value})}
                           renderInput={(params) => <TextField {...params} label={this.state["warrantyduration"+i+1]} variant="outlined" />}
                           disableClearable={true}
                         />
                       </Col>     
   </Row>
       
      {/*Salesdate, Warranty Period*/}
   
   
   </div>
   
   :
   this.state["selectedcategories" +(i+1)] =='Accessories' || this.state["selectedcategories" +(i+1)] == 'Apple Full Set 1:Plug+Cable+Earpod' || this.state["selectedcategories" +(i+1)] == 'Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod' || this.state["selectedcategories" +(i+1)] == 'Android Full Set 1: Fast Charging Plug+Type Ｃ Cable' || this.state["selectedcategories" +(i+1)] == 'Android Full Set 2: Fast Charging Plug+Cable'?
   <div>
      {/*ProductName*/}
      <Row>
       <Col md="10">
    <p className="h5">Product Name:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.stockaccessorieslist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block",backgroundColor:this.state ["colorbackground"+(i+1)]}}
                           onInputChange                                                                                                                                                                                                                                  ={(event,value) =>this.selectedproductnameaccessories((i+1),value)}
                           renderInput={(params) => <TextField {...params} label={this.state["productname"+(i+1)]} variant="outlined"  />}
                           disableClearable={true}
                           disabled={this.state["disable"+(i+1)]}       
                         />                   
                         </FormGroup>
                         </Col>
      <Col md="2">
         <p className="h5">Unit Price RM/MYR:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min="0"
             onWheel={(e) => e.target.blur()}
             enabled
             value={this.state["unitprice"+(i+1)]} 
             onChange={e => this.unitprice1changefunction((i+1),e)}
           />
         </FormGroup>
       </Col>
       
       </Row>
   
      {/* quantity, subtotalprice*/}
     <Row>
   
       <Col md="4">
         <p className="h5">Quantity: (max {this.state["maxquantity"+(i+1)]} stock)</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min={0}
             value={this.state["quantity"+(i+1)]} 
             onChange={e => this.quantity1changefunction((i+1),e)}
             max={this.state["maxquantity"+(i+1)]} 
             disabled={this.state["disable"+(i+1)]}
             style={{backgroundColor:this.state ["colorbackground"+(i+1)]}}
           />
         </FormGroup>
       </Col>
       <Col md="4">
         <p className="h5">Subtotal Price RM/MYR:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min="0"
             onWheel={(e) => e.target.blur()}
             value={this.state["subtotal"+(i+1)]} 
             onChange={e => this.setState({["subtotal"+(i+1)]: e.target.value})}
             disabled
           />
         </FormGroup>
       </Col>
       <Col md="6">
                         <p className="h5">Warranty Duration:</p>
                         <Autocomplete
                           options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block",backgroundColor:this.state ["colorbackground"+(i+1)]}}
                           onInputChange={(event,value) =>this.setState({["warrantyduration"+(i+1)]:value})}
                           renderInput={(params) => <TextField {...params} label={this.state["warrantyduration"+(i+1)]} variant="outlined" />}
                           disableClearable={true}
                           disabled={this.state["disable"+(i+1)]}
                         />
                       </Col>
   </Row>
       
      {/*Salesdate, Warranty Period*/}

   
   </div>
   
   :
   this.state["selectedcategories" +(i+1)] =="Spare Part" ?
   <div>
      {/*ProductName*/}
      <Row>
       <Col md="10">
    <p className="h5">Product Name:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.stocksparepartlist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedproductnamesparepart((i+1),value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
      <Col md="2">
         <p className="h5">Unit Price RM/MYR:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
              min="0"
              onWheel={(e) => e.target.blur()}
             disabled
             value={this.state["unitprice"+(i+1)]} 
             onChange={e => this.setState({["unitprice"+(i+1)]: e.target.value})}
           />
         </FormGroup>
       </Col>
       
       </Row>
   
      {/* quantity, subtotalprice*/}
     <Row>
   
       <Col md="4">
         <p className="h5">Quantity: (max {this.state["maxquantity"+(i+1)]} stock)</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min={0}
             max={this.state["maxquantity"+(i+1)]} 
             value={this.state["quantity"+(i+1)]} 
             onChange={e => this.quantity1changefunction((i+1),e)}
           />
         </FormGroup>
       </Col>
       <Col md="4">
         <p className="h5">Subtotal Price RM/MYR:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min="0"
             onWheel={(e) => e.target.blur()}
             value={this.state["subtotal"+(i+1)]} 
             onChange={e => this.setState({["subtotal"+(i+1)]: e.target.value})}
             disabled
           />
         </FormGroup>
       </Col>
       <Col md="6">
                         <p className="h5">Warranty Duration:</p>
                         <Autocomplete
                           options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({["warrantyduration"+(i+1)]:value})}
                           renderInput={(params) => <TextField {...params} label={this.state["warrantyduration"+(i+1)]} variant="outlined" />}
                           disableClearable={true}
                         />
                       </Col>
   </Row>
       
      {/*Salesdate, Warranty Period*/}   
   
   </div>
   
   :
   null}
    </div>
    
    )
  }

  return consignmentnorow

}

submitnewrotforpending =() =>{ 

  var errorornot = false;
  var errormessage = "";
  if(this.state.invoicenumberlist!=null){ 
  var lengthemployee = Object.keys(this.state.invoicenumberlist).length;
  var i =0;
  for(i =0;i<lengthemployee;i++){
    if(this.state.invoicenumberlist[i].invoicenumber == this.state.invoicenumber){
      errormessage="Invoice Number Duplicate , Please check again"
      errorornot=true; 
    }
  }
}
  
 
  if(this.state.invoicenumber =="" || this.state.invoicenumber ==undefined ){
    errormessage="Please fill in Invoice Number"
    errorornot=true;         
  }

  
 

  if(this.state.paymentmethodoneortwo =="" || this.state.paymentmethodoneortwo ==undefined){
    errormessage="Please fill in How many Payment Method"
    errorornot=true;    
  }

  if(this.state.paymentmethodvalue =="" || this.state.paymentmethodvalue ==undefined){
    errormessage="Please fill in Payment Method 1"
    errorornot=true;   
  }
 

  if(this.state.selectedcategories1 == "Phone"){

    if(this.state.imei1 =="" || this.state.imei1 ==undefined){
      errormessage="Please fill in Phone IMEI"
      errorornot=true;        
    } 

  }

  if(this.state.productname1 =="" || this.state.productname1 ==undefined){
    errormessage="Please fill in Product Name"
    errorornot=true;   
  }


  if(this.state.quantity1 ==0){
    errormessage="Please fill in Product Quantity"
    errorornot=true;   
  }

  if(parseFloat(this.state.paymentvalue1)+parseFloat(this.state.paymentvalue2) != parseFloat(this.state.totalprice) && this.state.paymentmethodoneortwo=="Two"){
    errormessage="Payment Amount Error, Please Try Again"
    errorornot=true;   
  }


  if(this.state.warrantyduration1 =="" || this.state.warrantyduration1 ==undefined){
    errormessage="Please fill in Warranty Duration"
    errorornot=true; 
  } 
  if(this.state.paymentmethodvalue != "Credit Card"){
    this.state.creditcharges1=0.00
  }
  if(this.state.paymentmethod2 != "Credit Card"){
    this.state.creditcharges2=0.00
  }
  if(this.state.paymentmethod3 != "Credit Card"){
    this.state.creditcharges3=0.00
  }

  for(let i=0;i<this.state.productnumbersection;i++){
    for(let j=1;j<this.state.productnumbersection;j++){
        if(this.state["productid"+(i+1)] == this.state["productid"+(j+i+1)]){
          errormessage="Something wrong, please refresh the page and try again"
          errorornot=true; 
        }

        if(this.state["quantity"+(i+1)] > this.state["maxquantity" +(i+1)]){
          errormessage="Quantity more than stock have"
          errorornot=true; 
        }
    }
  }

  if( errorornot==true){
    window.scrollTo(0, 0)
    window.confirm(errormessage)
  }else{
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();
      //var tempphone = "";
      
      for(let i=0;i<this.state.productnumbersection;i++){
       
        urlencoded.append("usernamecontrol", this.state.userid);
        if(this.state["selectedcategories"+(i+1)] == "Android Full Set 2: Fast Charging Plug+Cable" || this.state["selectedcategories"+(i+1)] == "Apple Full Set 1:Plug+Cable+Earpod" ||  this.state["selectedcategories"+(i+1)] == "Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod" || this.state["selectedcategories"+(i+1)] == "Android Full Set 1: Fast Charging Plug+Type Ｃ Cable" ){
          urlencoded.append(("category"+(i+1)), "Accessories");
          console.log("seelctedcategroyies"+(i+1)+":",this.state["selectedcategories"+(i+1)])
        //  tempphone ="Phone"
           
        }else{
          urlencoded.append(("category"+(i+1)), this.state["selectedcategories"+(i+1)]);
          console.log("seelctedcategroyies"+(i+1)+":",this.state["selectedcategories"+(i+1)])
        }
        
        //console.log("imeiall: " + this.state["imei"+(i+1)]);
        if(this.state["selectedcategories"+(i+1)] == "Phone"){
          urlencoded.append(("imei"+(i+1)), this.state["imei"+(i+1)]);
          urlencoded.append(("unitprice"+(i+1)), this.state["subtotal"+(i+1)]); 

        }else{          
          urlencoded.append(("typevalue"+(i+1)), this.state["typevalue"+(i+1)]);
          urlencoded.append(("brandvalue"+(i+1)), this.state["brandvalue"+(i+1)]);
          urlencoded.append(("unitprice"+(i+1)), this.state["unitprice"+(i+1)]);  
        }

        urlencoded.append(("productid"+(i+1)), this.state["productid"+(i+1)]);      
        urlencoded.append(("productname"+(i+1)), this.state["productnameupload"+(i+1)]);
        urlencoded.append(("quantity"+(i+1)), this.state["quantity"+(i+1)]);
        urlencoded.append(("subtotal"+(i+1)), this.state["subtotal"+(i+1)]);
        urlencoded.append(("costprice"+(i+1)), this.state["costprice"+(i+1)]);
        urlencoded.append(("repaircost"+(i+1)), this.state["repaircost"+(i+1)]);
        urlencoded.append(("commission"+(i+1)), this.state["commission"+(i+1)]);
        
        urlencoded.append(("memberdiscountprice"+(i+1)), Math.round(parseFloat(this.state.memberdiscountprice)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100)
        urlencoded.append(("fullset"+(i+1)), this.state["fullset"+(i+1)]);
        if(this.state.paymentmethodoneortwo == "Two"){
          urlencoded.append(("creditcharges1"+(i+1)), Math.round(parseFloat(this.state.creditcharges1)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100);
          urlencoded.append(("creditcharges2"+(i+1)), Math.round(parseFloat(this.state.creditcharges2)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100);
        }else if(this.state.paymentmethodoneortwo == "Three"){
          urlencoded.append(("creditcharges1"+(i+1)), Math.round(parseFloat(this.state.creditcharges1)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100);
          urlencoded.append(("creditcharges2"+(i+1)), Math.round(parseFloat(this.state.creditcharges2)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100);
          urlencoded.append(("creditcharges3"+(i+1)), Math.round(parseFloat(this.state.creditcharges3)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100);
        
        }else{
          urlencoded.append(("creditcharges1"+(i+1)), Math.round(parseFloat(this.state.creditcharges1)*100*parseFloat(this.state["subtotal"+(i+1)])/parseFloat(this.state.totalpriceall))/100);
        }


        var salesdate = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix();
        urlencoded.append(("salesdate"+(i+1)), salesdate);

        var warrantystartdate = Moment(this.state.invoicedate, 'DD/MM/YYYY')
        urlencoded.append(("warrantystart"+(i+1)), warrantystartdate.unix());

        if(this.state["warrantyduration"+(i+1)] == "1 Week"){
          var warrantyenddate = warrantystartdate.add(1, 'week')   
          var warrantyend = warrantyenddate.unix();
         // console.log("warrantyend: "  + warrantyend);
         // return;
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "2 Weeks"){
          var warrantyenddate = warrantystartdate.add(2, 'week')  
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "3 Weeks"){
          var warrantyenddate = warrantystartdate.add(3, 'week') 
          var warrantyend = warrantyenddate.unix(); 
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "1 Month"){
          var warrantyenddate = warrantystartdate.add(1, 'M') 
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "2 Months"){
          var warrantyenddate = warrantystartdate.add(2, 'M') 
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "3 Months"){
          var warrantyenddate = warrantystartdate.add(3, 'M')
          var warrantyend = warrantyenddate.unix(); 
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "1 Year"){
          var warrantyenddate = warrantystartdate.add(1, 'y') 
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "No Warranty"){
          var warrantyenddate = 0
         // var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), 0);
        }
        urlencoded.append(("warrantyduration"+(i+1)), this.state["warrantyduration"+(i+1)]);
        
        
      }
      //return
      // console.log("selectedcategories1: " + this.state["selectedcategories1"]);
      // console.log("selectedcategories"+(i+1)+": " + this.state["selectedcategories" + (i+1)]);

      // if(this.state["selectedcategories1"] == "Android Full Set 2: Fast Charging Plug+Cable" || this.state["selectedcategories1"] == "Apple Full Set 1:Plug+Cable+Earpod" ||  this.state["selectedcategories1"] == "Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod" ||  this.state["selectedcategories1"] == "Android Full Set 1: Fast Charging Plug+Type Ｃ Cable" ){
      //   urlencoded.append(("category1"), "Phone");
      //   console.log("first one category is Phone");
      // }

      var invoicedate2 = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix();
      urlencoded.append("invoicedate", invoicedate2);

     // console.log("invoicedate: " + this.state.invoicedate);
      //console.log("invoicedate2: " + invoicedate2);
     // return
      
      urlencoded.append("numberofproduct", this.state.productnumbersection);
      urlencoded.append("invoicenumber", this.state.invoicenumber);
      urlencoded.append("salesperson", this.state.salesperson);
      urlencoded.append("totalprice", this.state.totalprice);
      urlencoded.append("customername", this.state.customername);

      urlencoded.append("phoneprefix", this.state.phoneprefix);
     

      if(this.state.phonenumbervalue =="" || this.state.phonenumbervalue ==undefined){
        urlencoded.append("phonenumbervalue", '-')
      }else{      
        urlencoded.append("phonenumbervalue", this.state.phonenumbervalue)
      }

      if(this.state.memberid =="" || this.state.memberid ==undefined){
        urlencoded.append("memberid", '-');
        urlencoded.append("phoneprefix", '-');
      }else{      
        urlencoded.append("memberid", this.state.memberid);
        urlencoded.append("phoneprefix", this.state.phoneprefix);
      }

    
      urlencoded.append("productchangeid", "")
      


      
      if(this.state.paymentmethodoneortwo == "Two"){
        urlencoded.append("paymentmethodvalue", this.state.paymentmethodvalue);
        urlencoded.append("paymentmethod1value", this.state.paymentvalue1);
        urlencoded.append("paymentmethod2", this.state.paymentmethod2);
        urlencoded.append("paymentmethod2value", this.state.paymentvalue2);
        urlencoded.append("paymentmethod3", "");
        urlencoded.append("paymentmethod3value", 0);        

        urlencoded.append("creditcharges3", 0.00);
      }else if(this.state.paymentmethodoneortwo == "Three"){
        urlencoded.append("paymentmethodvalue", this.state.paymentmethodvalue);
        urlencoded.append("paymentmethod1value", this.state.paymentvalue1);
        urlencoded.append("paymentmethod2", this.state.paymentmethod2);
        urlencoded.append("paymentmethod2value", this.state.paymentvalue2);
        urlencoded.append("paymentmethod3", this.state.paymentmethod3);
        urlencoded.append("paymentmethod3value", this.state.paymentvalue3);      
      }else{
        urlencoded.append("paymentmethodvalue", this.state.paymentmethodvalue);
        urlencoded.append("paymentmethod1value", this.state.totalprice);
        urlencoded.append("paymentmethod2", "");
        urlencoded.append("paymentmethod2value", 0);
        urlencoded.append("paymentmethod3", "");
        urlencoded.append("paymentmethod3value", 0);
        urlencoded.append("creditcharges2", 0.00);
        urlencoded.append("creditcharges3", 0.00);
      }

      if(this.state.paymentmethodvalue=="Ansuran"||this.state.paymentmethod2=="Ansuran"||this.state.paymentmethod3=="Ansuran"){
        urlencoded.append("cashrebate", this.state.cashrebate);
      }
  else{
    urlencoded.append("cashrebate", 0);
  }
      //return;
      urlencoded.append("remark", this.state.remark);
      urlencoded.append("server", this.state.server);
     
      let link = this.state.linkurl +'mode=admin-insertsalestable'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              this.setState({aftersubmitstatus:1})

        }
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
          console.log(json)
        }
      
       })
    
  if(this.state.tradein=="Yes"){
    this.submitnewrotforpendingtrade()
  }


   
  }

}

selectphonemodel = (value) =>{
 this.setState({tradein:value})
  if(value=="Yes"){
  var urlencoded = new URLSearchParams();
  urlencoded.append("empty", '-');
  urlencoded.append("usernamecontrol", this.state.userid);
  let link = this.state.linkurl +'mode=admin-selectproductmodel'
  fetch(link, {
    method: 'POST', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body:urlencoded
  })
  .then(response => response.json())
  .then(json => {
    console.log(json.success)
    if(json.success == 1){
      
          console.log(json)
          this.setState({modellist:[]});   
          this.setState({casingbrand:[]});   
           var lengthemployee = Object.keys(json.employee).length;
           for(let i=0;i<lengthemployee;i++){
          
              this.setState({modellist:[...this.state.modellist,
                { no:i+1,
                  username:json.employee[i].phonemodel,
                  title:json.employee[i].phonemodel,
                  value:json.employee[i].phonemodel,
                },            
              ]})

              this.setState({casingbrand:[...this.state.casingbrand,
                { no:i+1,
                  username:json.employee[i].phonemodel,
                  title:json.employee[i].phonemodel,
                  value:json.employee[i].phonemodel,
                },            
              ]})
            }
           // this.setState({loading:false})  
          }        
             
       else{
        this.setState({loading:false}) 
      console.log(json)
    }
   })
  }
}

calculatetotalprice1 = () =>{
  var totalpricetemp = 0;
  var i = 0;
  for(i=1;i<=this.state.productnumbersection;i++){
    totalpricetemp = parseFloat(totalpricetemp) + parseFloat(this.state["subtotal"+i]) 
  }
  this.setState({
    totalprice:(totalpricetemp - this.state.memberdiscountprice - this.state.tradeinprice - this.state.tradeinprice2 - this.state.tradeinprice3),
    totalpriceall:totalpricetemp
  })
}
calculatetotalprice2 = () =>{
  var totalpricetemp = 0;
  var i = 0;
  for(i=1;i<=this.state.productnumbersection;i++){
    totalpricetemp = parseFloat(totalpricetemp) + parseFloat(this.state["subtotal"+i]) 
  }
  this.setState({
    totalprice:(totalpricetemp - this.state.memberdiscountprice),
    totalpriceall:totalpricetemp})
}



  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Create New Sales:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>

              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Submit Success</p>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/sales"
              tag={Link}
              >
              Go To Sales List
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.refreshallthingsale()}
              >
              Create Another Sales
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.refreshallthingsale()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body mt--5">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
  
  {/*invoice*/}
  <Row>
  <Col md="12">
      <p className="h5">Invoice Number:</p>
      <FormGroup>
      <Input
          className="textcolorblack"
          type="text"
          value={this.state.invoicenumber} 
          onChange={e => this.setState({invoicenumber: e.target.value})}
        />
      </FormGroup>
    </Col>
    </Row>
    <Row>
 <Col md="4">
    <p className="h5">Invoice Date :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Invoice Date",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state.invoicedate}
                         onChange={(e) => this.setState({invoicedate:e})}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
    </Col>
 </Row>
 <Row >
  <Col md="12">
    <p> </p>
    <p> </p>
  </Col>
  </Row>
    {/*product+,product-*/}
    <Row>
      <Col md="9">
        {this.state.productnumbersection >= 10 ?
        null
        :
        <Button className=" mb-2" color="primary" type="button" onClick={this.addproductbutton.bind(this)}>
        + Product
      </Button>
        }

        {this.state.productnumbersection <=1 ?
        null
        :
        <Button className=" mb-2" color="primary" type="button" onClick={this.minusproductbutton.bind(this)}>
        - Product
      </Button>
        }
    
 
      </Col>
  
  </Row>

 {this.renderproductsection()}


  {/*space*/}          
  <Row >
  <Col md="12">
    <p> </p>
    <p> </p>
  </Col>
  </Row>

  {/*space*/}
  <Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
  
   {/*Salesperson, customer name, phone*/}
   <Row>
  <Col md="2" >
   <p className="h5">Trade In? :</p>
     <FormGroup>
  
      <Autocomplete
                       options={[{title:'Yes'},{title:'No'}]}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       defaultValue={"No"}
                       onInputChange={(event,value) =>this.selectphonemodel(value)}
                       renderInput={(params) => <TextField {...params}  variant="outlined" />}
                       disableClearable={true}
                     /> 
     </FormGroup>
   </Col>
   </Row>
  {this.state.tradein == "Yes"?
  <>
  <Row>
  <p className="h3" style={{color:'black'}}>Trade in 1</p> 
  </Row>
  <Row>
  <Col md="4">
    <p className="h5">Product Name:</p>
    <FormGroup>
  
  <Autocomplete
                   options={this.state.modellist}
                   getOptionLabel={(option) => option.title}                       
                   style={{ flex:1,display:"block" }}
                   defaultValue={'No'}
                   onInputChange={(event,value) =>this.setState({tradeinphonem:value})}
                   renderInput={(params) => <TextField {...params}  variant="outlined" />}
                   disableClearable={true}
                 /> 
 </FormGroup>
  </Col>
  

  <Col md="4">
  <p className="h5">IMEI</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="text"
      value={this.state.tradeinphonei} 
      onChange={e => {this.setState({tradeinphonei: e.target.value});this.checkimei(e.target.value)}}
    />
  </FormGroup>
</Col>
<Col md="2">
    <p className="h5">Brand:</p>
    <FormGroup>
        <Autocomplete
        //options={[{title:'Yes'},{title:'No'}]}
        options={[{title:'Apple'},{title:'Samsung'},{title:'Huawei'},{title:'Oppo'},{title:'Xiaomi'},{title:'Redmi'},{title:'Vivo'},{title:'Asus'},{title:'Others'}]}
        getOptionLabel={(option) => option.title}                       
        style={{ flex:1,display:"block" }}
        onInputChange={(event,value) =>this.setState({tradeinphoneb:value})}
        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
        disableClearable={true}
        />    
                      
        <FormFeedback invalid>Please fill in the detail</FormFeedback>
 </FormGroup>
  </Col>
  </Row>
  <Row>
  <Col md="3">
      <p className="h5">Storage:</p>
      <FormGroup >
          <Autocomplete
            options={this.state.storagelist}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({storagevalue:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.storagevalueerrorvalidation} helperText={this.state.storagevalueerrormessage}/>}  
          disableClearable={true}
          />                   
          </FormGroup>
    </Col>
  <Col md="3">
      <p className="h5">Color:</p>
      <FormGroup>
      <Autocomplete
            options={this.state.colorlist}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({colorphonevalue:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.colorvalueerrorvalidation} helperText={this.state.colorvalueerrormessage} />}  
          disableClearable={true}
          />     
      </FormGroup>
    </Col>
  <Col md="3">
      <p className="h5">Fingerprint:</p>
      <FormGroup>
      <Autocomplete
            options={[{title:'Yes'},{title:'No'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({fingerprinthaveornot:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.fingerprinthaveornoterrorvalidation} helperText={this.state.fingerprinthaveornoterrormessage} />}  
          disableClearable={true}
          />   
      </FormGroup>
    
    </Col>
    <Col md="3">
      <p className="h5">FaceID:</p>
      <FormGroup>
      <Autocomplete
            options={[{title:'Yes'},{title:'No'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({faceidhaveornot:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.faceidhaveornoterrorvalidation} helperText={this.state.faceidhaveornoterrormessage}  />}  
          disableClearable={true}
          />   
      </FormGroup>
    
    </Col>
    <Col md="3">
  <p className="h5">Trade in Price RM/MYR:</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="number"
      min="0"
      onWheel={(e) => e.target.blur()}
      value={this.state.tradeinprice} 
      onChange={e => this.setState({tradeinprice: e.target.value})}
    />
  </FormGroup>
</Col>

  </Row>
  <Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>

  <Row>
  <p className="h3" style={{color:'black'}}>Trade in 2</p> 
  </Row>
  <Row>
  <Col md="4">
    <p className="h5">Product Name:</p>
    <FormGroup>
  
  <Autocomplete
                   options={this.state.modellist}
                   getOptionLabel={(option) => option.title}                       
                   style={{ flex:1,display:"block" }}
                   defaultValue={'No'}
                   onInputChange={(event,value) =>this.setState({tradeinphonem2:value})}
                   renderInput={(params) => <TextField {...params}  variant="outlined" />}
                   disableClearable={true}
                 /> 
 </FormGroup>
  </Col>
  

  <Col md="4">
  <p className="h5">IMEI</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="text"
      value={this.state.tradeinphonei2} 
      onChange={e => {this.setState({tradeinphonei2: e.target.value});this.checkimei(e.target.value)}}
    />
  </FormGroup>
</Col>
<Col md="2">
    <p className="h5">Brand:</p>
    <FormGroup>
        <Autocomplete
        //options={[{title:'Yes'},{title:'No'}]}
        options={[{title:'Apple'},{title:'Samsung'},{title:'Huawei'},{title:'Oppo'},{title:'Xiaomi'},{title:'Redmi'},{title:'Vivo'},{title:'Asus'},{title:'Others'}]}
        getOptionLabel={(option) => option.title}                       
        style={{ flex:1,display:"block" }}
        onInputChange={(event,value) =>this.setState({tradeinphoneb2:value})}
        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
        disableClearable={true}
        />    
                      
        <FormFeedback invalid>Please fill in the detail</FormFeedback>
 </FormGroup>
  </Col>
  </Row>
  <Row>
  <Col md="3">
      <p className="h5">Storage:</p>
      <FormGroup >
          <Autocomplete
            options={this.state.storagelist}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({storagevalue2:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.storagevalueerrorvalidation} helperText={this.state.storagevalueerrormessage}/>}  
          disableClearable={true}
          />                   
          </FormGroup>
    </Col>
  <Col md="3">
      <p className="h5">Color:</p>
      <FormGroup>
      <Autocomplete
            options={this.state.colorlist}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({colorphonevalue2:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.colorvalueerrorvalidation} helperText={this.state.colorvalueerrormessage} />}  
          disableClearable={true}
          />     
      </FormGroup>
    </Col>
  <Col md="3">
      <p className="h5">Fingerprint:</p>
      <FormGroup>
      <Autocomplete
            options={[{title:'Yes'},{title:'No'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({fingerprinthaveornot2:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.fingerprinthaveornoterrorvalidation} helperText={this.state.fingerprinthaveornoterrormessage} />}  
          disableClearable={true}
          />   
      </FormGroup>
    
    </Col>
    <Col md="3">
      <p className="h5">FaceID:</p>
      <FormGroup>
      <Autocomplete
            options={[{title:'Yes'},{title:'No'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({faceidhaveornot2:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.faceidhaveornoterrorvalidation} helperText={this.state.faceidhaveornoterrormessage}  />}  
          disableClearable={true}
          />   
      </FormGroup>
    
    </Col>
    <Col md="3">
  <p className="h5">Trade in Price RM/MYR:</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="number"
      min="0"
      onWheel={(e) => e.target.blur()}
      value={this.state.tradeinprice2} 
      onChange={e => this.setState({tradeinprice2: e.target.value})}
    />
  </FormGroup>
</Col>

  </Row>
  <Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
  <Row>
  <p className="h3" style={{color:'black'}}>Trade in 3</p> 
  </Row>
  <Row>
  <Col md="4">
    <p className="h5">Product Name:</p>
    <FormGroup>
  
  <Autocomplete
                   options={this.state.modellist}
                   getOptionLabel={(option) => option.title}                       
                   style={{ flex:1,display:"block" }}
                   defaultValue={'No'}
                   onInputChange={(event,value) =>this.setState({tradeinphonem3:value})}
                   renderInput={(params) => <TextField {...params}  variant="outlined" />}
                   disableClearable={true}
                 /> 
 </FormGroup>
  </Col>
  

  <Col md="4">
  <p className="h5">IMEI</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="text"
      value={this.state.tradeinphonei3} 
      onChange={e => {this.setState({tradeinphonei3: e.target.value});this.checkimei(e.target.value)}}
    />
  </FormGroup>
</Col>
<Col md="2">
    <p className="h5">Brand:</p>
    <FormGroup>
        <Autocomplete
        //options={[{title:'Yes'},{title:'No'}]}
        options={[{title:'Apple'},{title:'Samsung'},{title:'Huawei'},{title:'Oppo'},{title:'Xiaomi'},{title:'Redmi'},{title:'Vivo'},{title:'Asus'},{title:'Others'}]}
        getOptionLabel={(option) => option.title}                       
        style={{ flex:1,display:"block" }}
        onInputChange={(event,value) =>this.setState({tradeinphoneb3:value})}
        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
        disableClearable={true}
        />    
                      
        <FormFeedback invalid>Please fill in the detail</FormFeedback>
 </FormGroup>
  </Col>
  </Row>
  <Row>
  <Col md="3">
      <p className="h5">Storage:</p>
      <FormGroup >
          <Autocomplete
            options={this.state.storagelist}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({storagevalue3:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.storagevalueerrorvalidation} helperText={this.state.storagevalueerrormessage}/>}  
          disableClearable={true}
          />                   
          </FormGroup>
    </Col>
  <Col md="3">
      <p className="h5">Color:</p>
      <FormGroup>
      <Autocomplete
            options={this.state.colorlist}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({colorphonevalue3:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.colorvalueerrorvalidation} helperText={this.state.colorvalueerrormessage} />}  
          disableClearable={true}
          />     
      </FormGroup>
    </Col>
  <Col md="3">
      <p className="h5">Fingerprint:</p>
      <FormGroup>
      <Autocomplete
            options={[{title:'Yes'},{title:'No'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({fingerprinthaveornot3:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.fingerprinthaveornoterrorvalidation} helperText={this.state.fingerprinthaveornoterrormessage} />}  
          disableClearable={true}
          />   
      </FormGroup>
    
    </Col>
    <Col md="3">
      <p className="h5">FaceID:</p>
      <FormGroup>
      <Autocomplete
            options={[{title:'Yes'},{title:'No'}]}
            getOptionLabel={(option) => option.title}                       
            style={{ flex:1,display:"block" }}
            onInputChange={(event,value) =>this.setState({faceidhaveornot3:value})}
            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.faceidhaveornoterrorvalidation} helperText={this.state.faceidhaveornoterrormessage}  />}  
          disableClearable={true}
          />   
      </FormGroup>
    
    </Col>
    <Col md="3">
  <p className="h5">Trade in Price RM/MYR:</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="number"
      min="0"
      onWheel={(e) => e.target.blur()}
      value={this.state.tradeinprice3} 
      onChange={e => this.setState({tradeinprice3: e.target.value})}
    />
  </FormGroup>
</Col>

  </Row>
  </>
  :
  null
  }
   <Row >
  <Col md="12">
    <p> </p>
    <p> </p>
  </Col>
  </Row>

  {/*space*/}
  <Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
  <Row>
  <Col md="4">
      <p className="h5">Sales Person :</p>
      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.userlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({salesperson:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                        disableClearable={true}
                      />                   
                      </FormGroup>
    </Col>
    
    
    <Col md="3">
      <p className="h5">How to find Customer :</p>
      
      <FormGroup>
      <Autocomplete
                        options={[{title:'Member ID'},{title:'Phone'},{title:'Guest'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) => this.getcustomerlistmemberid(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      /> 
    
      </FormGroup>
    </Col>
   
   {this.state.findcustomer == "Phone" ?
   <>
      <Col md="2">
      <p className="h5">Customer Phone :</p>
      
      <FormGroup>
      <Autocomplete
                        options={[{title:'010'},{title:'011'},{title:'012'},{title:'013'},{title:'014'},{title:'015'},{title:'016'},{title:'017'},{title:'018'},{title:'019'},{title:'+65'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.getcustomerlist(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      /> 
    
      </FormGroup>
    </Col>
   
    <Col md="2" >
    <p className="h5">Phone Number :</p>
      <FormGroup>
   
       <Autocomplete
                        options={this.state.customerlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectphonenumber(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      /> 
      </FormGroup>
    </Col>
  
    <Col md="2">
      <p className="h5">Customer Name :</p>
      <FormGroup>
      <Input
          className="textcolorblack"
          type="text"
          disabled
          value={this.state.customername} 
          onChange={e => this.setState({customername: e.target.value})}
        />
      </FormGroup>
    </Col>
   
   </>
   :
   this.state.findcustomer == "Member ID" ?
   <>
   <Col md="2" >
   <p className="h5">Member ID :</p>
     <FormGroup>
  
      <Autocomplete
                       options={this.state.customerlist}
                       getOptionLabel={(option) => option.title2}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.memberidvalue(value)}
                       renderInput={(params) => <TextField {...params}  variant="outlined" />}
                       disableClearable={true}
                     /> 
     </FormGroup>
   </Col>


   <Col md="2">
  <p className="h5">Member Discount :</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="number"
      min="0"
      onWheel={(e) => e.target.blur()}
      value={this.state.memberdiscountprice} 
      onChange={e => this.setState({memberdiscountprice: e.target.value})}
    />
  </FormGroup>
</Col>


  <Col md="2">
  <p className="h5">Customer Name :</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="text"
      disabled
      value={this.state.customername} 
      onChange={e => this.setState({customername: e.target.value})}
    />
  </FormGroup>
</Col>
</>
  :
this.state.findcustomer=="Guest"?
<>
<Col md="2">
  <p className="h5">Manual Discount :</p>
  <FormGroup>
  <Input
      className="textcolorblack"
      type="text"
      value={this.state.memberdiscountprice} 
      onChange={e => this.setState({memberdiscountprice: e.target.value})}
    />
  </FormGroup>
</Col>
</>
:
null
   }
  </Row>
  <Row >
  <Col md="12">
    <p> </p>
    <p> </p>
  </Col>
  </Row>

  {/*space*/}
  <Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
  <Row>
    {this.state.tradein=="Yes"?
    <>
  <Col md="3">
         <p className="h5">Calculate</p>
         <FormGroup>
         <Button color="primary" type="button" onClick={this.calculatetotalprice1.bind(this)}>
            Calculate Total Price
          </Button>
         </FormGroup>
       </Col>
       </>
       :
       <Col md="3">
         <p className="h5">Calculate</p>
         <FormGroup>
         <Button color="primary" type="button" onClick={this.calculatetotalprice2.bind(this)}>
            Calculate Total Price
          </Button>
         </FormGroup>
       </Col>
  }
  <Col md="3">
         <p className="h5">Total Price RM/MYR:</p>
         <FormGroup>
           <Input
             type="text"
             value={this.state.totalprice}
             disabled
           />
         </FormGroup>
       </Col>
    <Col md="4">
         <p className="h5">How Many Payment Method:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={[{title:"One"},{title:"Two"},{title:"Three"}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({paymentmethodoneortwo:value})}                          
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
       </Col>
      {this.state.paymentmethodoneortwo == "One" ?
      <>
      <Col md="4">
      <p className="h5">Payment Method 1:</p>
      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.paymentmethodlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({paymentmethodvalue:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                        disableClearable={true}
                      />                   
                      </FormGroup>
    </Col>
    {this.state.paymentmethodvalue=="Credit Card"?
    <Col md="3">
    <p className="h5">Credit Charges:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({creditcharges1: e.target.value})}
                        value={this.state.creditcharges1}
                        
                      />               
                    </FormGroup>
  </Col>
  :
  this.state.paymentmethodvalue=="Ansuran"?
  <>
  <Col md="3">
    <p className="h5">Cash Rebate:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({cashrebate: e.target.value})}
                        value={this.state.cashrebate}
                        
                      />               
                    </FormGroup>
  </Col>
  </>
  :
  null
}
  </>
    :
    null}
       
    </Row>
 {/*payment method*/}
 
   {
   this.state.paymentmethodoneortwo == "Two" ?
   <>
   <Row>
   <Col md="3">
        <p className="h5">Payment Method 1:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethodvalue:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>

      <Col md="3">
        <p className="h5">Paid Value:</p>
        <FormGroup >
        <Input
                            type="float"
                            min="0.00"
                            onWheel={(e) => e.target.blur()}
                            onChange={e => this.setState({paymentvalue1: e.target.value})}
                            value={this.state.paymentvalue1}
                            
                          />               
                        </FormGroup>
      </Col>
      {this.state.paymentmethodvalue=="Credit Card"?
            <Col md="3">
    <p className="h5">Credit Charges 1:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({creditcharges1: e.target.value})}
                        value={this.state.creditcharges1}
                        
                      />               
                    </FormGroup>
  </Col> 
  :
  this.state.paymentmethod2=="Ansuran"?
  <>
  <Col md="3">
    <p className="h5">Cash Rebate:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({cashrebate: e.target.value})}
                        value={this.state.cashrebate}
                        
                      />               
                    </FormGroup>
  </Col>
  </>
  :
  null
}
  </Row>
  <Row>   
      <Col md="3">
        <p className="h5">Payment Method 2:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethod2:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>
      
      <Col md="3">
        <p className="h5">Paid Value 2:</p>
        <FormGroup >
        <Input
                            type="float"
                            min="0.00"
                            onWheel={(e) => e.target.blur()}
                            onChange={e => this.setState({paymentvalue2: e.target.value})}
                            value={this.state.paymentvalue2}
                            
                          />                 
                        </FormGroup>
      </Col>
      {this.state.paymentmethod2=="Credit Card"?
      <Col md="3">
    <p className="h5">Credit Charges 2:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({creditcharges2: e.target.value})}
                        value={this.state.creditcharges2}
                        
                      />               
                    </FormGroup>
  </Col>
  :
  this.state.paymentmethod2=="Ansuran"?
  <>
  <Col md="3">
    <p className="h5">Cash Rebate:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({cashrebate: e.target.value})}
                        value={this.state.cashrebate}
                        
                      />               
                    </FormGroup>
  </Col>
  </>
  :
  null
}
   </Row>
   </>
   :null
   }
  
  {
   this.state.paymentmethodoneortwo == "Three" ?
   <>
   <Row>
   <Col md="3">
        <p className="h5">Payment Method 1:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethodvalue:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>

      <Col md="3">
        <p className="h5">Paid Value:</p>
        <FormGroup >
        <Input
                            type="float"
                            min="0.00"
                            onWheel={(e) => e.target.blur()}
                            onChange={e => this.setState({paymentvalue1: e.target.value})}
                            value={this.state.paymentvalue1}
                            
                          />               
                        </FormGroup>
      </Col>
      {this.state.paymentmethodvalue=="Credit Card"?
            <Col md="3">
    <p className="h5">Credit Charges 1:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({creditcharges1: e.target.value})}
                        value={this.state.creditcharges1}
                        
                      />               
                    </FormGroup>
  </Col> 
  :
  this.state.paymentmethod2=="Ansuran"?
  <>
  <Col md="3">
    <p className="h5">Cash Rebate:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({cashrebate: e.target.value})}
                        value={this.state.cashrebate}
                        
                      />               
                    </FormGroup>
  </Col>
  </>
  :
  null
}
  </Row>
  <Row>   
      <Col md="3">
        <p className="h5">Payment Method 2:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethod2:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>
      
      <Col md="3">
        <p className="h5">Paid Value 2:</p>
        <FormGroup >
        <Input
                            type="float"
                            min="0.00"
                            onWheel={(e) => e.target.blur()}
                            onChange={e => this.setState({paymentvalue2: e.target.value})}
                            value={this.state.paymentvalue2}
                            
                          />                 
                        </FormGroup>
      </Col>
      {this.state.paymentmethod2=="Credit Card"?
      <Col md="3">
    <p className="h5">Credit Charges 2:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({creditcharges2: e.target.value})}
                        value={this.state.creditcharges2}
                        
                      />               
                    </FormGroup>
  </Col>
  :
  this.state.paymentmethod2=="Ansuran"?
  <>
  <Col md="3">
    <p className="h5">Cash Rebate:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({cashrebate: e.target.value})}
                        value={this.state.cashrebate}
                        
                      />               
                    </FormGroup>
  </Col>
  </>
  :
  null
}
   </Row>
   <Row>   
      <Col md="3">
        <p className="h5">Payment Method 3:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethod3:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>
      
      <Col md="3">
        <p className="h5">Paid Value 3:</p>
        <FormGroup >
        <Input
                            type="float"
                            min="0.00"
                            onWheel={(e) => e.target.blur()}
                            onChange={e => this.setState({paymentvalue3: e.target.value})}
                            value={this.state.paymentvalue3}
                            
                          />                 
                        </FormGroup>
      </Col>
      {this.state.paymentmethod3=="Credit Card"?
      <Col md="3">
    <p className="h5">Credit Charges 3:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({creditcharges3: e.target.value})}
                        value={this.state.creditcharges3}
                        
                      />               
                    </FormGroup>
  </Col>
  :
  this.state.paymentmethod3=="Ansuran"?
  <>
  <Col md="3">
    <p className="h5">Cash Rebate:</p>
    <FormGroup >
    <Input
                        type="float"
                        min="0.00"
                        onWheel={(e) => e.target.blur()}
                        onChange={e => this.setState({cashrebate: e.target.value})}
                        value={this.state.cashrebate}
                        
                      />               
                    </FormGroup>
  </Col>
  </>
  :
  null
}
   </Row>
   </>
   :null
   }

  {/*Remark*/}
  <Row>
            <Col md="12">
                <p className="h5">Remark:</p>
                <FormGroup>
                <Input
                    rows="5"
                    className="textcolorblack"
                    type="textarea"
                    value={this.state.remark}
                    onChange={(e)=>this.setState({remark:e.target.value})}
                  />
                </FormGroup>
              </Col>
            
            </Row>
                  
               
</Form>
  </div>
  <div className="modal-footer">
    {/* <Button
      color="secondary"
      data-dismiss="modal"
      type="button"
      onClick={() => this.toggleModal("editpopuout")}2
    >
      Close
    </Button> */}
    <Button color="primary" type="button" onClick={this.submitnewrotforpending.bind(this)}>
      Save changes
    </Button>
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>
          </Row>
        </Container>
     
       

      
      </>
    );
  }
}

export default AddNewSales;

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{createContext} from "react";
import { Link } from 'react-router-dom';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col,NavItem,NavLink,Button,Nav } from "reactstrap";
import classnames from "classnames";



class Header extends React.Component {

  constructor(props) {
    super(props);
   
    this.state ={
      showaddnewbutton: false,
    }
    
  }

  
  render() {
    return (
      <>
      
       
        <div className="header bg-gradient-blue pb-9 pt-2 pt-md-5">
        <Container fluid>
          <div className="header-body">

          </div>
        </Container>
      </div>
        
     
 
      </>
    );
  }
}

export default Header;

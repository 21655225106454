/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
*https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
*http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Report from "views/Report.jsx";
import Profile from "views/examples/Profile.jsx";
import Stock from "views/examples/Stock.jsx";
import Register from "views/examples/Register.jsx";
import Login from "views/examples/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Sales from "views/examples/Sales.jsx";
import AddNewSales from "views/examples/AddNewSales.jsx";
import ClaimWarranty from "views/examples/ClaimWarranty.jsx";
import AddNewStock from "views/examples/AddNewStock.jsx";
import Forget from "views/examples/Forgetpassword.jsx";
import Commission from "views/examples/Commission.jsx";
import SaleReport from "views/examples/Report.jsx";
import PaymentSummary from "views/examples/PaymentSummary.jsx";
import TransferStock from "views/examples/Transferstock.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-blue",
    component: Dashboard,
    layout: "/admin"
  },
  // {
  //   path: "/report",
  //   name: "Report",
  //   icon: "ni ni-tv-2 text-blue",
  //   component: Report,
  //   layout: "/admin"
  // },
  {
    path: "/stock",
    name: "Stock",
    icon: "ni ni-collection text-blue",
    component: Stock,
    layout: "/admin"
  },
  {
    path: "/Transferstock",
    name: "Transfer Phone",
    icon: "ni ni-collection text-blue",
    component: TransferStock,
    layout: "/admin"
  },
  {
    path: "/sales",
    name: "Sales",
    icon: "ni ni-money-coins text-blue",
    component: Sales,
    layout: "/admin"
  },
  {
    path: "/report",
    name: "Report",
    icon: "ni ni-money-coins text-blue",
    component: SaleReport,
    layout: "/admin"
  },
  {
    path: "/PaymentSummary",
      name: "Payment Summary",
      icon: "ni ni-folder-17 text-blue",
      component: PaymentSummary,
      layout: "/admin"
    },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/addnewsales",
    name: "Add New Sales",
    icon: "ni ni-folder-17 text-blue",
    component: AddNewSales,
    layout: "/admin"
  },
  {
    path: "/addnewstock",
    name: "Add New Stock",
    icon: "ni ni-settings-gear-65 text-blue",
    component: AddNewStock,
    layout: "/admin"
  },
  {
    path: "/claimwarranty",
    name: "Claim Warranty",
    icon: "ni ni-world text-blue",
    component: ClaimWarranty,
    layout: "/admin"
  },
  {
    path: "/comission",
    name: "Commission",
    icon: "ni ni-credit-card text-blue",
    component: Commission,
    layout: "/admin"
  },
];
export default routes;

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Alert,
} from "reactstrap";

class Login extends React.Component {

  state= {
    emailtext:'',
    checkagreeornot:false,
    errorsubmit:false,
    alerterrormessage:'',
    //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
   // imageurllink:"https://crm.mewahtrans.com.my/img/profile_img/",
    linkurl:"https://cors-anywhere.herokuapp.com/https://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
  }

  submitforgetpassword = () =>{
    if(this.state.emailtext == ""){
      this.setState({alerterrormessage:'Email cannot empty'})
      this.setState({errorsubmit:true})
      window.scrollTo(0, 0)
    }else if(this.state.emailtext == "" || !this.state.emailtext.includes("@")){
      this.setState({alerterrormessage:'Invalid email'})
      this.setState({errorsubmit:true})
      window.scrollTo(0, 0)
    }else if(this.state.checkagreeornot == false){
      this.setState({alerterrormessage:'Please check to agree reset password'})
      this.setState({errorsubmit:true})
      window.scrollTo(0, 0)
    }else{
      var urlencoded = new URLSearchParams();
      urlencoded.append("emailtext", this.state.emailtext);
      let link = this.state.linkurl +'mode=admin-submitforgetpassword'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          if(window.confirm('Success submit forget password, please check your email')){
            this.props.history.push({
              pathname: '/auth/login',
              state: {
                id: 0,
              }
            })
          }
          
        }
                 
           else{
            this.setState({loading:false}) 
          console.log(json)
        }
      
       })
    }
  
  }



  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Forget Password</small>
              </div>
              <Form role="form">
              {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" value={this.state.emailtext} onChange = {(e) => this.setState({emailtext: e.target.value })}/>
                  </InputGroup>
                </FormGroup>
               
               
                <div className="text-center">
                <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                      className="custom-control-input"
                      id="customCheck2"
                      type="checkbox"
                      disabled={false}
                      checked={this.state.checkagreeornot}
                      onChange={(e)=> this.setState({checkagreeornot:!this.state.checkagreeornot}) }
                    />  
                      <label className="custom-control-label" htmlFor={"customCheck2"}>
                        I agree to reset my password
                      </label>
                    </div>
                  <NavLink className="nav-link-icon" onClick={(e) => this.submitforgetpassword()}>
                  <Button className="my-4" color="primary" type="button">
                    Submit
                  </Button>
                  </NavLink>
                
                 
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/login"
                to="/auth/login"
                tag={Link}
              >
                <small> Back to Login</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
                to="/auth/register"
                tag={Link}
              >
                <small>Create new account</small>
              </a>
            </Col>
        
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;

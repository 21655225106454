/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  FormFeedback,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import Moment from 'moment';
import ReactToPrint from 'react-to-print';
import { isThisTypeNode } from 'typescript';


      //test in stock
      //sales table,stock table;

      
      



class Transferstock extends React.Component {
  
  state = {
    loading:false,

    selectedcategories1:'',
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    paymentmethodlist:[],
    unitprice1:0,
    subtotal1:0,
    productname1:'',
    quantity1:0,
    productnumbersection:1,
    remark:'',
    customername:'',
    creditcharges1:0.00,
    creditcharges2:0.00,
    paymentmethodoneortwo:"",
    modellist:[],
    tradeinphonem:'',
    tradeinphonei:'',
    tradeinphoneb:'',
    tradeinprice:'',
    faceidhaveornot:'',
    fingerprinthaveornot:'',
    storagevalue:'',
    colorphonevalue:'',
    tradeinphonem2:'',
    tradeinphonei2:'',
    tradeinphoneb2:'',
    tradeinprice2:'',
    faceidhaveornot2:'',
    fingerprinthaveornot2:'',
    storagevalue2:'',
    colorphonevalue2:'',
    tradeinphonem3:'',
    tradeinphonei3:'',
    tradeinphoneb3:'',
    tradeinprice3:'',
    faceidhaveornot3:'',
    fingerprinthaveornot3:'',
    storagevalue3:'',
    colorphonevalue3:'',
    totalpriceall:0,
    totalprice:0,
    paymentmethodvalue:"",
    transferserver:"",
    imeilist:[],
    serverlist:[],
    defaultpassword:"8888",
    commission:0,
    memberdiscountprice:0,
    server:localStorage.getItem('server'),
    userid:localStorage.getItem('userid'),
    invoicedate:Moment().format("DD/MM/YYYY"),
    linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    //linkurl:"https://cors-anywhere.herokuapp.com/http://pos.mzprotrading.com/api/mzprotrading.php?",
    
  };
  

  refreshallthingsale = () =>{

    for(let i = 0;i<this.state.productnumbersection;i++){
      this.setState({
        ["selectedcategories"+(i+1)]:'',
        ["productname"+(i+1)]:'',
        ["imei"+(i+1)]:'',
        ["quantity"+(i+1)]:'',
        ["unitprice"+(i+1)]:'',
        ["subtotal"+(i+1)]:'',
        ["warrantystart"+(i+1)]:'',
        ["warrantyend"+(i+1)]:'',
        ["salesdate"+(i+1)]:'',
      })
    }
    this.setState({     
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    productnumbersection:1,
    aftersubmitstatus:0,
    phonenumbervalue:'',
    customername:'',
    remark:'',
    invoicenumber:'',
    tradein:'',
    totalprice:0,
    paymentmethodoneortwo:"",
    paymentmethodvalue:"",
    memberdiscountprice:0,
    })
  }
  componentDidMount(){
    // this.getarealist();
    this.selectuser();
    this.getimeistocklist();
    this.getserver();
    //this.getcustomerlist();
  }

  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
 
  addproductbutton = () =>{
    if(this.state.productnumbersection >= 10){

    }else{
      this.setState({productnumbersection:this.state.productnumbersection+1})    
    }    
  }

  minusproductbutton = () =>{
    if(this.state.productnumbersection <= 1){

    }else{
      this.setState({productnumbersection:this.state.productnumbersection-1})    
    }    
  }

  warrantyendchoose = (numberrow,e) =>{
    
    var timestampend = e.unix()
    var timestampstart = new Date(this.state["warrantystart"+(numberrow+1)]).getTime()/1000

    console.log("timestampend:" + timestampend)
    console.log("timestampstart:" +timestampstart )

    if(timestampend < timestampstart){
      if(window.confirm("Warranty end cannot after warranty start")){
        this.setState({["warrantyend"+(numberrow+1)]:''})
        return;
      }
    }else{
      this.setState({["warrantyend"+(numberrow+1)]:e})
    }

   
  }
  
  warrantystartchoose = (numberrow,e) =>{
    this.setState({["warrantystart"+(numberrow+1)]:e})
    this.setState({["warrantyend"+(numberrow+1)]:''})
   
  }

  getcustomerlist = (value) =>{     
    this.setState({phoneprefix:value})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-getcustomerlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({customerlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].phoneprefix == value){
            this.setState({customerlist:[...this.state.customerlist,
              { no:i+1,                
                title:json.employee[i].Contact,
                phone:json.employee[i].Contact,
                customername:json.employee[i].Name,
                memberid:json.employee[i].memberid,
                title2:json.employee[i].memberid,
                phoneprefix:json.employee[i].phoneprefix,

              },      
          
                 
            ]})
          }
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }


  

  getimeistocklist = () =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-getimeistocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({imeilist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
        
            this.setState({imeilist:[...this.state.imeilist,
              { no:i+1,                
                title:json.employee[i].imei,
                ref:json.employee[i].ref,
                productname:json.employee[i].productname,
                statusvalue:json.employee[i].statusvalue,

              
              },      
          
                 
            ]})
          
          
         }
         console.log(this.state.imeilist)
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }
  
 
  selectcolorlist =() =>{ 
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("usernamecontrol", this.state.userid);
    let link = this.state.linkurl +'mode=admin-selectcolorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({colorlist:[]});   
            
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
                this.setState({colorlist:[...this.state.colorlist,
                  { no:i+1,
                    username:json.employee[i].phonecolor,
                    title:json.employee[i].phonecolor,
                    value:json.employee[i].phonecolor,
                  },            
                ]})
              
               
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  getsparepartstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectsparepartstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)2
      if(json.success == 1){
      
      console.log(json)
      this.setState({stocksparepartlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].statusvalue == "Ready For Sell"){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              costprice:json.employee[i].costprice,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              repaircost:json.employee[i].repaircost+json.employee.repaircost2+json.employee.repaircost3+json.employee.repaircost4+json.employee.repaircost5,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }
  setbackwhencnotset=(beforecategory,aftercategory,productnumber)=>{
    this.setState({["showcategories"+productnumber]:aftercategory})
  if(beforecategory=="Apple Full Set 1:Plug+Cable+Earpod"||beforecategory=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
    if(aftercategory=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
      if(this.state.productnumbersection>=productnumber+1){
        for(let i=productnumber;i<=this.state.productnumbersection-1;i++){
          this.setState({["selectedcategories"+(i)]:this.state["selectedcategories"+(i+1)]})
          this.setState({["productname"+(i)]:this.state["productname"+(i+1)]})
          this.setState({["quantity"+(i)]:this.state["quantity"+(i+1)]})
          this.setState({["subtotal"+(i)]:this.state["subtotal"+(i+1)]})
          this.setState({["fullset"+(i)]:this.state["fullset"+(i+1)]})
          this.setState({["imei"+(i)]:this.state["imei"+(i+1)]})
          this.setState({["typevalue"+(i)]:this.state["typevalue"+(i+1)]})
          this.setState({["brandvalue"+(i)]:this.state["brandvalue"+(i+1)]})
          this.setState({["productid"+(i)]:this.state["productid"+(i+1)]})
          this.setState({["unitprice"+(i)]:this.state["unitprice"+(i+1)]})
          this.setState({["productnameupload"+(i)]:this.state["productnameupload"+(i+1)]})
          this.setState({["costprice"+(i)]:this.state["costprice"+(i+1)]})
          this.setState({["repaircost"+(i)]:this.state["repaircost"+(i+1)]})
          this.setState({["commission"+(i)]:this.state["commission"+(i+1)]})
          this.setState({["warrantyduration"+(i)]:this.state["warrantyduration"+(i+1)]})
        }
          this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
          this.setState({["productname"+(this.state.productnumbersection)]:''})
          this.setState({["quantity"+(this.state.productnumbersection)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection)]:''})
          this.setState({["fullset"+(this.state.productnumbersection)]:''})
          this.setState({["imei"+(this.state.productnumbersection)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
          this.setState({["productid"+(this.state.productnumbersection)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection)]:''})
          this.setState({["disable"+(this.state.productnumbersection)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
          this.setState({["costprice"+(this.state.productnumbersection)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection)]:''})
          this.setState({["commission"+(this.state.productnumbersection)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection)]:''})
        }      
        this.setState({productnumbersection:this.state.productnumbersection-1});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Type C","Android","Cable",(productnumber+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2))     
      }
      else if(aftercategory=="Android Full Set 2: Fast Charging Plug+Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
      if(this.state.productnumbersection>=productnumber+1){
        for(let i=productnumber;i<=this.state.productnumbersection-1;i++){
          this.setState({["selectedcategories"+(i)]:this.state["selectedcategories"+(i+1)]})
          this.setState({["productname"+(i)]:this.state["productname"+(i+1)]})
          this.setState({["quantity"+(i)]:this.state["quantity"+(i+1)]})
          this.setState({["subtotal"+(i)]:this.state["subtotal"+(i+1)]})
          this.setState({["fullset"+(i)]:this.state["fullset"+(i+1)]})
          this.setState({["imei"+(i)]:this.state["imei"+(i+1)]})
          this.setState({["typevalue"+(i)]:this.state["typevalue"+(i+1)]})
          this.setState({["brandvalue"+(i)]:this.state["brandvalue"+(i+1)]})
          this.setState({["productid"+(i)]:this.state["productid"+(i+1)]})
          this.setState({["unitprice"+(i)]:this.state["unitprice"+(i+1)]})
          this.setState({["productnameupload"+(i)]:this.state["productnameupload"+(i+1)]})
          this.setState({["costprice"+(i)]:this.state["costprice"+(i+1)]})
          this.setState({["repaircost"+(i)]:this.state["repaircost"+(i+1)]})
          this.setState({["commission"+(i)]:this.state["commission"+(i+1)]})
          this.setState({["warrantyduration"+(i)]:this.state["warrantyduration"+(i+1)]})
        }
          this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
          this.setState({["productname"+(this.state.productnumbersection)]:''})
          this.setState({["quantity"+(this.state.productnumbersection)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection)]:''})
          this.setState({["fullset"+(this.state.productnumbersection)]:''})
          this.setState({["imei"+(this.state.productnumbersection)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
          this.setState({["productid"+(this.state.productnumbersection)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection)]:''})
          this.setState({["disable"+(this.state.productnumbersection)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
          this.setState({["costprice"+(this.state.productnumbersection)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection)]:''})
          this.setState({["commission"+(this.state.productnumbersection)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection)]:''})
        }
        this.setState({productnumbersection:this.state.productnumbersection-1});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Normal","Android","Cable",(productnumber+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2))    
    } 
    else if (aftercategory=="Apple Full Set 1:Plug+Cable+Earpod"){
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Normal","Apple","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Normal","Apple","Cable",(productnumber+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2));
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3)) 
    }
    else if(aftercategory=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Apple","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Type C","Apple","Cable",(productnumber+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2));
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3))   
    }
    else{
      if(this.state.productnumbersection>=productnumber+3){
        for(let i=productnumber+1;i<=this.state.productnumbersection-3;i++){
          this.setState({
            ["selectedcategories"+(i)]:this.state["selectedcategories"+(i+3)],
            ["productname"+(i)]:this.state["productname"+(i+3)],
            ["imei"+(i)]:this.state["imei"+(i+3)],
            ["quantity"+(i)]:this.state["quantity"+(i+3)],
            ["subtotal"+(i)]:this.state["subtotal"+(i+3)],
            ["fullset"+(i)]:this.state["fullset"+(i+3)],
            ["typevalue"+(i)]:this.state["typevalue"+(i+3)],
            ["brandvalue"+(i)]:this.state["brandvalue"+(i+3)],
            ["productid"+(i)]:this.state["productid"+(i+3)],
            ["unitprice"+(i)]:this.state["unitprice"+(i+3)],
            ["disable"+(i)]:false,
            ["productnameupload"+(i)]:this.state["productnameupload"+(i+3)],
            ["costprice"+(i)]:this.state["costprice"+(i+3)],
            ["repaircost"+(i)]:this.state["repaircost"+(i+3)],
            ["commission"+(i)]:this.state["commission"+(i+3)],
            ["warrantyduration"+(i)]:this.state["warrantyduration"+(i+3)],
        })
        }
        this.setState({["selectedcategories"+(this.state.productnumbersection-2)]:''})
          this.setState({["productname"+(this.state.productnumbersection-2)]:''})
          this.setState({["quantity"+(this.state.productnumbersection-2)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection-2)]:''})
          this.setState({["fullset"+(this.state.productnumbersection-2)]:''})
          this.setState({["imei"+(this.state.productnumbersection-2)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection-2)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection-2)]:''})
          this.setState({["productid"+(this.state.productnumbersection-2)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection-2)]:''})
          this.setState({["disable"+(this.state.productnumbersection-2)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection-1)]:''})
          this.setState({["costprice"+(this.state.productnumbersection-1)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection-1)]:''})
          this.setState({["commission"+(this.state.productnumbersection-1)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection-1)]:''})
        this.setState({["selectedcategories"+(this.state.productnumbersection-1)]:''})
          this.setState({["productname"+(this.state.productnumbersection-1)]:''})
          this.setState({["quantity"+(this.state.productnumbersection-1)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection-1)]:''})
          this.setState({["fullset"+(this.state.productnumbersection-1)]:''})
          this.setState({["imei"+(this.state.productnumbersection-1)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection-1)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection-1)]:''})
          this.setState({["productid"+(this.state.productnumbersection-1)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection-1)]:''})
          this.setState({["disable"+(this.state.productnumbersection-1)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection-1)]:''})
          this.setState({["costprice"+(this.state.productnumbersection-1)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection-1)]:''})
          this.setState({["commission"+(this.state.productnumbersection-1)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection-1)]:''})
          this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
          this.setState({["productname"+(this.state.productnumbersection)]:''})
          this.setState({["quantity"+(this.state.productnumbersection)]:''})
          this.setState({["subtotal"+(this.state.productnumbersection)]:''})
          this.setState({["fullset"+(this.state.productnumbersection)]:''})
          this.setState({["imei"+(this.state.productnumbersection)]:''})
          this.setState({["typevalue"+(this.state.productnumbersection)]:''})
          this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
          this.setState({["productid"+(this.state.productnumbersection)]:''})
          this.setState({["unitprice"+(this.state.productnumbersection)]:''})
          this.setState({["disable"+(this.state.productnumbersection)]:false})
          this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
          this.setState({["costprice"+(this.state.productnumbersection)]:''})
          this.setState({["repaircost"+(this.state.productnumbersection)]:''})
          this.setState({["commission"+(this.state.productnumbersection)]:''})
          this.setState({["warrantyduration"+(this.state.productnumbersection)]:''})
      }
          this.setState({["productname"+productnumber]:''})
          this.setState({["quantity"+productnumber]:''})
          this.setState({["subtotal"+productnumber]:''})
          this.setState({["fullset"+productnumber]:''})
          this.setState({["imei"+productnumber]:''})
          this.setState({["typevalue"+productnumber]:''})
          this.setState({["brandvalue"+productnumber]:''})
          this.setState({["productid"+productnumber]:''})
          this.setState({["unitprice"+productnumber]:''})
          this.setState({["disable"+productnumber]:false})
          this.setState({["productnameupload"+productnumber]:''})
          this.setState({["costprice"+productnumber]:''})
          this.setState({["repaircost"+productnumber]:''})
          this.setState({["commission"+productnumber]:''})
          this.setState({["selectedcategories"+productnumber]:aftercategory})
          this.setState({["showcategories"+productnumber]:aftercategory})
          if(aftercategory == "Phone"){
            this.setState(
              {
              ["productname"+productnumber]:'',
              ["unitprice"+productnumber]:0,
              ["quantity"+productnumber]:0,
              ["subtotal"+productnumber]:0,
            })
            this.getphonestocklist();
          }else if(aftercategory =="Accessories"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getaccessoriesstocklist();
          }else if(aftercategory =="Spare Part"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getsparepartstocklist();
          }
    this.setState({productnumbersection:this.state.productnumbersection-3});


  }
}
  else if(beforecategory=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"||beforecategory=="Android Full Set 2: Fast Charging Plug+Cable"){
    if(aftercategory=="Apple Full Set 1:Plug+Cable+Earpod"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
        this.setState({productnumbersection:this.state.productnumbersection+2});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
        this.checksetaccessorieshaveornot("Normal","Apple","Charger",productnumber); 
        this.checksetaccessorieshaveornot("Normal","Apple","Cable",(productnumber+1)); 
        this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2));
        this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3))     
      }
      else if(aftercategory=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
        this.setState({productnumbersection:this.state.productnumbersection+2});
        this.setState({["selectedcategories"+productnumber]:aftercategory})
        this.checksetaccessorieshaveornot("Fast Charger","Apple","Charger",productnumber); 
        this.checksetaccessorieshaveornot("Type C","Apple","Cable",(productnumber+1)); 
        this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(productnumber+2)); 
        this.checksetaccessorieshaveornot("Normal","Apple","Box",(productnumber+3))
    } 
    else if (aftercategory=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,             
      })
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Type C","Android","Cable",(productnumber+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2)) 
    }
    else if(aftercategory=="Android Full Set 2: Fast Charging Plug+Cable"){
      this.setState(
        {
          ["productname"+productnumber]:'',
          ["unitprice"+productnumber]:0,
          ["quantity"+productnumber]:0,
          ["subtotal"+productnumber]:0,
          ['fullset'+productnumber]:1,        
      })
      this.setState({["selectedcategories"+productnumber]:aftercategory})
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",productnumber); 
      this.checksetaccessorieshaveornot("Normal","Android","Cable",(productnumber+1)); 
      this.checksetaccessorieshaveornot("Normal","Android","Box",(productnumber+2))  
    }
    else{
      if(this.state.productnumbersection>productnumber){
        if(this.state.productnumbersection>=productnumber+2){
          for(let i=productnumber;i<=this.state.productnumbersection-2;i++){
            this.setState({["selectedcategories"+(i)]:this.state["selectedcategories"+(i+2)]})
            this.setState({["productname"+(i)]:this.state["productname"+(i+2)]})
            this.setState({["quantity"+(i)]:this.state["quantity"+(i+2)]})
            this.setState({["subtotal"+(i)]:this.state["subtotal"+(i+2)]})
            this.setState({["fullset"+(i)]:this.state["fullset"+(i+2)]})
            this.setState({["imei"+(i)]:this.state["imei"+(i+1)]})
            this.setState({["typevalue"+(i)]:this.state["typevalue"+(i+2)]})
            this.setState({["brandvalue"+(i)]:this.state["brandvalue"+(i+2)]})
            this.setState({["productid"+(i)]:this.state["productid"+(i+2)]})
            this.setState({["unitprice"+(i)]:this.state["unitprice"+(i+2)]})
            this.setState({["productnameupload"+(i)]:this.state["productnameupload"+(i+2)]})
            this.setState({["costprice"+(i)]:this.state["costprice"+(i+2)]})
            this.setState({["repaircost"+(i)]:this.state["repaircost"+(i+2)]})
            this.setState({["commission"+(i)]:this.state["commission"+(i+2)]})
          }
            this.setState({["selectedcategories"+(this.state.productnumbersection)]:''})
            this.setState({["productname"+(this.state.productnumbersection)]:''})
            this.setState({["quantity"+(this.state.productnumbersection)]:''})
            this.setState({["subtotal"+(this.state.productnumbersection)]:''})
            this.setState({["fullset"+(this.state.productnumbersection)]:''})
            this.setState({["imei"+(this.state.productnumbersection)]:''})
            this.setState({["typevalue"+(this.state.productnumbersection)]:''})
            this.setState({["brandvalue"+(this.state.productnumbersection)]:''})
            this.setState({["productid"+(this.state.productnumbersection)]:''})
            this.setState({["unitprice"+(this.state.productnumbersection)]:''})
            this.setState({["disable"+(this.state.productnumbersection)]:false})
            this.setState({["productnameupload"+(this.state.productnumbersection)]:''})
            this.setState({["costprice"+(this.state.productnumbersection)]:''})
            this.setState({["repaircost"+(this.state.productnumbersection)]:''})
            this.setState({["commission"+(this.state.productnumbersection)]:''})
            this.setState({["selectedcategories"+(this.state.productnumbersection-1)]:''})
            this.setState({["productname"+(this.state.productnumbersection-1)]:''})
            this.setState({["quantity"+(this.state.productnumbersection-1)]:''})
            this.setState({["subtotal"+(this.state.productnumbersection-1)]:''})
            this.setState({["fullset"+(this.state.productnumbersection-1)]:''})
            this.setState({["imei"+(this.state.productnumbersection-1)]:''})
            this.setState({["typevalue"+(this.state.productnumbersection-1)]:''})
            this.setState({["brandvalue"+(this.state.productnumbersection-1)]:''})
            this.setState({["productid"+(this.state.productnumbersection-1)]:''})
            this.setState({["unitprice"+(this.state.productnumbersection-1)]:''})
            this.setState({["disable"+(this.state.productnumbersection-1)]:false})
            this.setState({["productnameupload"+(this.state.productnumbersection-1)]:''})
            this.setState({["costprice"+(this.state.productnumbersection-1)]:''})
            this.setState({["repaircost"+(this.state.productnumbersection-1)]:''})
            this.setState({["commission"+(this.state.productnumbersection-1)]:''})
        }
    }
    this.setState({["selectedcategories"+productnumber]:''})
          this.setState({["productname"+productnumber]:''})
          this.setState({["quantity"+productnumber]:''})
          this.setState({["subtotal"+productnumber]:''})
          this.setState({["fullset"+productnumber]:''})
          this.setState({["imei"+productnumber]:''})
          this.setState({["typevalue"+productnumber]:''})
          this.setState({["brandvalue"+productnumber]:''})
          this.setState({["productid"+productnumber]:''})
          this.setState({["unitprice"+productnumber]:''})
          this.setState({["disable"+productnumber]:false})
          this.setState({["productnameupload"+productnumber]:''})
          this.setState({["costprice"+productnumber]:''})
          this.setState({["repaircost"+productnumber]:''})
          this.setState({["commission"+productnumber]:''})
      this.setState({productnumbersection:this.state.productnumbersection-2});
      this.setState({["selectedcategories"+productnumber]:aftercategory})
          this.setState({["showcategories"+productnumber]:aftercategory})
          if(aftercategory == "Phone"){
            this.setState(
              {
              ["productname"+productnumber]:'',
              ["unitprice"+productnumber]:0,
              ["quantity"+productnumber]:0,
              ["subtotal"+productnumber]:0,
            })
            this.getphonestocklist();
          }else if(aftercategory =="Accessories"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getaccessoriesstocklist();
          }else if(aftercategory =="Spare Part"){
            this.setState(
              {
                ["productname"+productnumber]:'',
                ["unitprice"+productnumber]:0,
                ["quantity"+productnumber]:0,
                ["subtotal"+productnumber]:0,
                ['fullset'+productnumber]:0,
            })
            this.getsparepartstocklist();
          }
  }
  }

  }

  checksetaccessorieshaveornot=(typevalue,brandvalue,productname,productnumber)=>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("typevalue", typevalue);
    urlencoded.append("brandvalue", brandvalue);
    urlencoded.append("productname", productname);
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-checksetaccessorieshaveornot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)       
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
        
          this.setState(
            {            
              ["selectedcategories"+productnumber]:"Accessories",
              ["imei"+productnumber]:"-",
              ["typevalue"+productnumber]:json.employee[i].typevalue,
              ["brandvalue"+productnumber]:json.employee[i].brandvalue,
              ["productid"+productnumber]:json.employee[i].Ref,
              ["unitprice"+productnumber]:0,
              ["productname"+productnumber]:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              ["productnameupload"+productnumber]:json.employee[i].productname,
              ["quantity"+productnumber]:1,
              ["subtotal"+productnumber]:0,
              ["costprice"+productnumber]:json.employee[i].costprice,
              ["repaircost"+productnumber]:json.employee[i].repaircost,
              ["salesdate"+productnumber]:Moment(),
              ["warrantystart"+productnumber]:Moment(),
              ["warrantyduration"+productnumber]:"No Warranty",
              ["disable"+productnumber]:true,
              ["colorbackground"+productnumber]:"#f5f5f5",
              ["failornot" + productnumber]:false,
              ["fullset" + productnumber]:1,
          })
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
      window.confirm("No enough " + productname +" stock, please check again")
     this.setState({loading:false,["failornot" + productnumber]:true,})    
     this.setState({productnumbersection:1});
     window.location.reload();
      
   console.log(json)
      }
     })   
  }

  selectedcategoriesfunction = (numberrow,value) =>{
    if(this.state["showcategories"+numberrow]=="Apple Full Set 1:Plug+Cable+Earpod"||this.state["showcategories"+numberrow]=="Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"||this.state["showcategories"+numberrow]=="Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"||this.state["showcategories"+numberrow]=="Android Full Set 2: Fast Charging Plug+Cable"){
      this.setbackwhencnotset(this.state["showcategories"+numberrow],value,numberrow)
    }
    else{
    this.setState({["selectedcategories"+numberrow]:value})
    this.setState({["showcategories"+numberrow]:value})
    if(value == "Phone"){
      this.setState(
        {
        ["productname"+numberrow]:'',
        ["unitprice"+numberrow]:0,
        ["quantity"+numberrow]:0,
        ["subtotal"+numberrow]:0,
      })
      this.getphonestocklist();
    }else if(value =="Accessories"){
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:0,
      })
      this.getaccessoriesstocklist();
    }else if(value =="Spare Part"){
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:0,
      })
      this.getsparepartstocklist();
    }else if(value == "Apple Full Set 1:Plug+Cable+Earpod"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,       
      })
      this.setState({productnumbersection:(this.state.productnumbersection+3)});
      this.checksetaccessorieshaveornot("Normal","Apple","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Normal","Apple","Cable",(numberrow+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(numberrow+2)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(numberrow+3))
      



    }else if(value == "Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,        
      })
       this.setState({productnumbersection:this.state.productnumbersection+3});
      this.checksetaccessorieshaveornot("Fast Charger","Apple","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Type C","Apple","Cable",(numberrow+1)); 
      this.checksetaccessorieshaveornot("Normal","Apple","Earpod",(numberrow+2));
      this.checksetaccessorieshaveornot("Normal","Apple","Box",(numberrow+3))    


    }else if(value == "Android Full Set 1: Fast Charging Plug+Type Ｃ Cable"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,             
      })
      this.setState({productnumbersection:this.state.productnumbersection+2});
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Type C","Android","Cable",(numberrow+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(numberrow+2))   


    }else if(value == "Android Full Set 2: Fast Charging Plug+Cable"){
      //check stock first, if yes then ok      
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ['fullset'+numberrow]:1,        
      })
      this.setState({productnumbersection:this.state.productnumbersection+2});
      this.checksetaccessorieshaveornot("Fast Charger","Android","Charger",numberrow); 
      this.checksetaccessorieshaveornot("Normal","Android","Cable",(numberrow+1));
      this.checksetaccessorieshaveornot("Normal","Android","Box",(numberrow+2))    
    }
  }
  }

  selectedimeifunction = (numberrow,value) =>{
    if(this.state["imei"+numberrow]==undefined){
      
      this.setState({productnumbersection:this.state.productnumbersection+1})
    }
    this.setState({["imei"+numberrow]:value})
    var lengthofimeilist = Object.keys(this.state.imeilist).length;
    for(let i=0;i<lengthofimeilist;i++){
       if(this.state.imeilist[i].title == value){
         this.setState({
          ["productname"+numberrow]:this.state.imeilist[i].productname,
          ["statusvalue"+numberrow]:this.state.imeilist[i].statusvalue,
          ["ref"+numberrow]:this.state.imeilist[i].ref,
         })
         console.log(this.state.imeilist[i].ref)
       }
      
         }  
  }

  selectedproductnameaccessories = (numberrow,value) =>{
    this.setState({["productname"+numberrow]:value})
    var lengthofaccessorieslist = Object.keys(this.state.stockaccessorieslist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

        var nametoshow = this.state.stockaccessorieslist[i].productname + '---' +this.state.stockaccessorieslist[i].typevalue + '---' +this.state.stockaccessorieslist[i].brandvalue +'---'+ this.state.stockaccessorieslist[i].productid
       
        if(nametoshow == value){
         this.setState({
          ["productnameupload"+numberrow]:this.state.stockaccessorieslist[i].productname,
          ["unitprice"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["productid"+numberrow]:this.state.stockaccessorieslist[i].productid,
          ["quantity"+numberrow]:1,
          ["subtotal"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["typevalue"+numberrow]:this.state.stockaccessorieslist[i].typevalue ,
          ["brandvalue"+numberrow]:this.state.stockaccessorieslist[i].brandvalue ,
          ["maxquantity" + numberrow] : this.state.stockaccessorieslist[i].quantityvalue,
          ["costprice" + numberrow] : this.state.stockaccessorieslist[i].costprice,
          ["repaircost" + numberrow] : this.state.stockaccessorieslist[i].repaircost,
          ["imei" + numberrow] : '-',
         })
       }
      
         }  
  }
  
  selectedproductnamesparepart = (numberrow,value) =>{
    this.setState({["productname"+numberrow]:value})
    var lengthofaccessorieslist = Object.keys(this.state.stocksparepartlist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

        var nametoshow = this.state.stocksparepartlist[i].productname + '---' +this.state.stocksparepartlist[i].typevalue + '---' +this.state.stocksparepartlist[i].brandvalue +'---'+ this.state.stocksparepartlist[i].productid
       
      console.log("value: " + value)
      console.log("this.state.stocksparepartlist[i].productname : " + this.state.stocksparepartlist[i].productname )
      console.log("nametoshow: " + nametoshow)
        if(nametoshow == value){
         this.setState({
          ["productnameupload"+numberrow]:this.state.stocksparepartlist[i].productname,
          ["unitprice"+numberrow]:this.state.stocksparepartlist[i].saleprice,
          ["productid"+numberrow]:this.state.stocksparepartlist[i].productid,
          ["quantity"+numberrow]:1,
          ["subtotal"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["typevalue"+numberrow]:this.state.stocksparepartlist[i].typevalue ,
          ["brandvalue"+numberrow]:this.state.stocksparepartlist[i].brandvalue ,
          ["maxquantity" + numberrow] : this.state.stocksparepartlist[i].quantityvalue,
          ["costprice" + numberrow] : this.state.stocksparepartlist[i].costprice,
          ["repaircost" + numberrow] : this.state.stocksparepartlist[i].repaircost,
          ["imei" + numberrow] : '-',
         })
       }
      
         }  
  }
  
  selectuser =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  submitnewrotforpendingtrade =() =>{ 

    var errorornot = false;
    var errormessage = "";
    var b0=false;
    var message;

      if(this.state.tradeinphonem =="" || this.state.tradeinphonem ==undefined){
        errormessage="Please fill in Phone Model"
        errorornot=true;         
      }

      if(this.state.tradeinphonei =="" || this.state.tradeinphonei ==undefined){
        errormessage="Please fill in Phone IMEI"
        errorornot=true;        
      }

      if(this.state.tradeinphoneb =="" || this.state.tradeinphoneb ==undefined){
        errormessage="Please fill in Phone Brand"
        errorornot=true;  
      }


      if(this.state.storagevalue =="" || this.state.storagevalue ==undefined){
        errormessage="Please fill in Phone Storage"
        errorornot=true;  
      } 


      if(this.state.colorphonevalue =="" || this.state.colorphonevalue ==undefined){
        errormessage="Please fill in Phone Color"+this.state.colorphonevalue
        errorornot=true;  
      }

      if(this.state.fingerprinthaveornot =="" || this.state.fingerprinthaveornot ==undefined){
        errormessage="Please fill in Phone Finger Print"
        errorornot=true;  
      } 

      if(this.state.faceidhaveornot =="" || this.state.faceidhaveornot ==undefined){
        errormessage="Please fill in Phone Face ID"
        errorornot=true;  
      }
  
    
    if( errorornot==true){
      window.scrollTo(0, 0)
      window.confirm(errormessage)      
    }else{
      
        this.setState({aftersubmitstatus:3})
        var urlencoded = new URLSearchParams();
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.invoicedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
          urlencoded.append("productname", this.state.tradeinphonem);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("brandvalue", this.state.tradeinphoneb);
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot);
          urlencoded.append("imei", this.state.tradeinphonei);
          urlencoded.append("storagevalue", this.state.storagevalue);
          urlencoded.append("colorvalue", this.state.colorphonevalue);
          urlencoded.append("tradeininvoice",this.state.invoicenumber)
          urlencoded.append("tradeinsupplier","Client")
          urlencoded.append("suppliervalue","Trade In") 
          urlencoded.append("category", "Phone");
          urlencoded.append("costprice", this.state.tradeinprice);
          //urlencoded.append("wastagevalue", this.state.wastageornot);
          if(this.state.findcustomer!="Guest"){
            urlencoded.append("suppliervalue", this.state.memberid);
            }
            else
            {
              urlencoded.append("suppliervalue", "Guest");
            }
          urlencoded.append("statusvalue", "Repair");
          urlencoded.append("remark", this.state.remark);
          urlencoded.append("usernamecontrol", this.state.userid);
          urlencoded.append("server", this.state.server);
        let link = this.state.linkurl +'mode=admin-insertstocktable'
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })

        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
          }
          else{
            b0=true;
            message=json.message;
            console.log(json)
          }
        })

        if(this.state.tradeinphonem2==""|| this.state.tradeinphonem2 ==undefined){

        }
        else{
          var urlencoded = new URLSearchParams();
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.invoicedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
          urlencoded.append("productname", this.state.tradeinphonem2);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("brandvalue", this.state.tradeinphoneb2);
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot2);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot2);
          urlencoded.append("imei", this.state.tradeinphonei2);
          urlencoded.append("storagevalue", this.state.storagevalue2);
          urlencoded.append("colorvalue", this.state.colorphonevalue2);
          urlencoded.append("tradeininvoice",this.state.invoicenumber)
          urlencoded.append("tradeinsupplier","Client")          
          urlencoded.append("category", "Phone");
          urlencoded.append("costprice", this.state.tradeinprice2);
          //urlencoded.append("wastagevalue", this.state.wastageornot);
          if(this.state.findcustomer!="Guest"){
          urlencoded.append("suppliervalue", this.state.memberid);
          }
          else
          {
            urlencoded.append("suppliervalue", "Guest");
          }
          urlencoded.append("statusvalue", "Repair");
          urlencoded.append("remark", this.state.remark);
          urlencoded.append("usernamecontrol", this.state.userid);
          urlencoded.append("server", this.state.server);
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })

        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
          }
          else{
            b0=true;
            message=json.message;
            console.log(json)
          }
        })
      }
      if(this.state.tradeinphonem3==""|| this.state.tradeinphonem3 ==undefined){

      }
      else{
        var urlencoded = new URLSearchParams();
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.invoicedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.invoicedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
          urlencoded.append("productname", this.state.tradeinphonem3);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("brandvalue", this.state.tradeinphoneb3);
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot3);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot3);
          urlencoded.append("imei", this.state.tradeinphonei3);
          urlencoded.append("storagevalue", this.state.storagevalue3);
          urlencoded.append("colorvalue", this.state.colorphonevalue3);
          urlencoded.append("tradeininvoice",this.state.invoicenumber)
          urlencoded.append("tradeinsupplier","Client")          
          urlencoded.append("category", "Phone");
          urlencoded.append("costprice", this.state.tradeinprice3);
          //urlencoded.append("wastagevalue", this.state.wastageornot);
          if(this.state.findcustomer!="Guest"){
            urlencoded.append("suppliervalue", this.state.memberid);
            }
            else
            {
              urlencoded.append("suppliervalue", "Guest");
            }
          urlencoded.append("statusvalue", "Repair");
          urlencoded.append("remark", this.state.remark);
          urlencoded.append("usernamecontrol", this.state.userid);
          urlencoded.append("server", this.state.server);
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })

        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
          }
          else{
            b0=true
            message=json.message;
            console.log(json)
          }
        })
      }

          if(b0 == false){
            
                this.setState({aftersubmitstatus:1})

          }
                   
          else{
               //fail submit
              this.setState({aftersubmitstatus:2})
              this.setState({loading:false}) 
              this.setState({feedbackmessage:message})
              
          }

    }

  }

  

 quantity1changefunction = (numberrow,e) =>{
  this.setState({["quantity"+numberrow]: e.target.value})
  this.setState({["subtotal"+numberrow]:e.target.value * this.state["unitprice"+numberrow]});  
 }

 unitprice1changefunction = (numberrow,e) =>{
  this.setState({["unitprice"+numberrow]: e.target.value})
  this.setState({["subtotal"+numberrow]:e.target.value * this.state["quantity"+numberrow]});  
 }


 renderproductsection() {

  let consignmentnorow=[];
  for(let i=0;i<this.state.productnumbersection;i++){
    var numbershow = i+1
    

    

    consignmentnorow.push(
      <div>  
      {/*product number*/}
   <Row>
     <Col md="9">
     
     <p className="h3 bold producttextcolor" style={{color:"blue",fontSize:25}}>Product {i+1}</p> 
     
     </Col>
    
   </Row>
     
    {/*Category*/}
    
   
   <div>
      {/*IMEI,ProductName,UnitPrice*/}
      <Row>
      <Col md="4">
    <p className="h5">IMEI:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo"
                           options={this.state.imeilist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedimeifunction((i+1),value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
                         <Col md="4">
<p className="h5">Phone Name: {this.state["productname"+(i+1)]}          Status : {this.state["statusvalue"+(i+1)]}</p> 
  </Col>
   </Row>

   
   </div>
   
    </div>
    
    )
  }

  return consignmentnorow

}

submitnewrotforpending =() =>{ 

  var errorornot = false;
  var errormessage = "";
  let passfortransfer = prompt("Please enter transfer password:", "Password");
  if (passfortransfer !=this.state.defaultpassword) {
    errormessage="Wrong password"
    errorornot=true; 
  }
 
  if(this.state.productnumbersection ==1  ){
    errormessage="Please select at least 1 imei"
    errorornot=true;         
  }

  if(this.state.transferserver ==""||this.state.transferserver==undefined  ){
    errormessage="Please select Server to transfer"
    errorornot=true;         
  }

  if( errorornot==true){
    window.scrollTo(0, 0)
    window.confirm(errormessage)
  }else{
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();
      //var tempphone = "";
      urlencoded.append("servertt", this.state.transferserver);
      urlencoded.append("server", this.state.server);
      urlencoded.append("numberofproduct", this.state.productnumbersection);
      for(let i=0;i<this.state.productnumbersection;i++){
       
        urlencoded.append("usernamecontrol", this.state.userid);
           
        
          urlencoded.append(("imei"+(i+1)), this.state["imei"+(i+1)]);
          urlencoded.append(("ref"+(i+1)), this.state["ref"+(i+1)]);
        
        
      }
      //return
      // console.log("selectedcategories1: " + this.state["selectedcategories1"]);
      // console.log("selectedcategories"+(i+1)+": " + this.state["selectedcategories" + (i+1)]);

      // if(this.state["selectedcategories1"] == "Android Full Set 2: Fast Charging Plug+Cable" || this.state["selectedcategories1"] == "Apple Full Set 1:Plug+Cable+Earpod" ||  this.state["selectedcategories1"] == "Apple Full Set 2:Fast Charging Plug+Type C cable+Earpod" ||  this.state["selectedcategories1"] == "Android Full Set 1: Fast Charging Plug+Type Ｃ Cable" ){
      //   urlencoded.append(("category1"), "Phone");
      //   console.log("first one category is Phone");
      // }

     // console.log("invoicedate: " + this.state.invoicedate);
      //console.log("invoicedate2: " + invoicedate2);
     // return

     


      

     
     
      let link = this.state.linkurl +'mode=admin-transferrequest'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              this.setState({aftersubmitstatus:1})

        }
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
          console.log(json)
        }
      
       })
    
  if(this.state.tradein=="Yes"){
    this.submitnewrotforpendingtrade()
  }
  }
}
getserver =() =>{
  this.setState({loading:true})
  var urlencoded = new URLSearchParams();
  urlencoded.append("empty", '-');
  urlencoded.append("usernamecontrol", this.state.userid);
  let link = this.state.linkurl +'mode=admin-getserver'
  fetch(link, {
    method: 'POST', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body:urlencoded
  })
  .then(response => response.json())
  .then(json => {
    console.log(json.success)
    if(json.success == 1){
      
          console.log(json)
          this.setState({serverlist:[]});   
           var lengthemployee = Object.keys(json.employee).length;
           for(let i=0;i<lengthemployee;i++){
            if(json.employee[i].servername==this.state.server){
              }
              else{
              this.setState({serverlist:[...this.state.serverlist,
                { no:i+1,
                  username:json.employee[i].servername,
                  title:json.employee[i].servername,
                  value:json.employee[i].servername,
                },            
              ]})
            }
            }
             console.log(this.state.serverlist)
           // this.setState({loading:false})  
          }        
             
       else{
        this.setState({loading:false}) 
      console.log(json)
    }
  
   })

}





  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Transfer Phones:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>

              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Submit Success</p>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/stock"
              tag={Link}
              >
              Go To Stock List
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.refreshallthingsale()}
              >
              Create Another Sales
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.refreshallthingsale()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body mt--5">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
  

 {this.renderproductsection()}

 <Row>
        <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
        </Row>
        <Row>
 <Col md="4">
    <p className="h5">Transfer to Server:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo"
                           options={this.state.serverlist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({transferserver:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
   </Row>
  
               
</Form>
  </div>
  <div className="modal-footer">
    {/* <Button
      color="secondary"
      data-dismiss="modal"
      type="button"
      onClick={() => this.toggleModal("editpopuout")}2
    >
      Close
    </Button> */}
    <Button color="primary" type="button" onClick={this.submitnewrotforpending.bind(this)}>
      Transfer
    </Button>
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>
          </Row>
        </Container>
     
       

      
      </>
    );
  }
}

export default Transferstock;

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import { textChangeRangeIsUnchanged } from 'typescript';
import Moment from 'moment';
class ClaimWarranty extends React.Component {
  
  state = {
    loading:false,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownarea:false,
    invoiceorimeitext:'',
    aftersubmitstatus:0,
    server:localStorage.getItem('server'),
    alerterrormessage:'',
    errorsubmit:false,
    repairsparepart:'',
    quotationnumbershow:'',
    selectedproductid:'',
    selectedunitprice1:'',
    sparepartbrand:'',
    warrantyremark:'',
    userid:localStorage.getItem('userid'),
    linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    categoryselectedornot:0,
    productlist:[],

    productlistcanbechanged:[],
    sparepartlistcanbechanged:[],
    //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
   //imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
  };
  

  refreshallthingquotation = () =>{
    this.setState({
      tabs: 1,
      exampleModal: false,
      editpopuout:false,
      isopendropdownarea:false,
      areadropdowntext:'',
      Billtoparty:sessionStorage.getItem('Billtoparty'),
      lasteditby:sessionStorage.getItem('Ref'),
      shippervalue:'',
      addressvalue:'',
      picvalue:'',
      depogatechargevalue:'',
      contactvalue:'',
      backtobackchargevalue:'',
      gp20pgu:'',
      isotank20pgu:'',
      rf20pgu:'',
      ot20pgu:'',
      tipper20pgu:'',
      gp40pgu:'',
      rf40pgu:'',
      isotank40pgu:'',
      tipper40pgu:'',
      gp20ptp:'',
      isotank20ptp:'',
      rf20ptp:'',
      ot20ptp:'',
      tipper20ptp:'',
      gp40ptp:'',
      rf40ptp:'',
      isotank40ptp:'',
      tipper40ptp:'',
      aftersubmitstatus:0,
      alerterrormessage:'',
      errorsubmit:false,
      quotationnumbershow:'',
      linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
      //imageurllink:"https://crm.mewahtrans.com.my/img/profile_img/",
      //linkurl:"https://cors-anywhere.herokuapp.com/https://crm.mewahtrans.com.my/api/mewahapicode.php?",
      imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
    })
  }
  componentDidMount(){
    // this.getarealist();
  }
  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
 

  categoryselected = (value) => {
    this.setState({categoryselectedornot:1})
    this.setState({selectedcategory:value})
  }

  invoiceorimeiselected = () =>{
    //this.setState({invoiceorimeiselectedornot:1})
  }

  productnameselected = (value) =>{
    this.setState({
      productnameselectedornot:1,
      selectedproductname:value,
    })
    this.choosenproductnamecheckwarrantydate(value)
    
  }

  clicktocheckbuttonclick = () =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    console.log("checkbuttoncalled")
    //sum of phone category quantity status in ready for sell

    urlencoded.append("selectedcategory", this.state.selectedcategory);
    urlencoded.append("invoiceorimei", this.state.invoiceorimeitext);
    urlencoded.append("server", this.state.server);
    
    let link = this.state.linkurl +'mode=admin-checkhavingthisproductornot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        console.log(json)
        this.setState({productlist:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           //var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
            this.setState({productlist:[...this.state.productlist,
              { no:i+1,
               ref:json.employee[i].Ref,
               invoicenumber:json.employee[i].invoicenumber,
               productid:json.employee[i].productid,
               imei:json.employee[i].imei,
               title:json.employee[i].productname + "-" + json.employee[i].typevalue +"-" +json.employee[i].brandvalue +"-" +json.employee[i].Ref,
               productname:json.employee[i].productname,
               productid:json.employee[i].productid,
               unitprice1:json.employee[i].unitprice,
               warrantystart:json.employee[i].warrantystart,
               warrantyend:json.employee[i].warrantyend,
               brandvalue:json.employee[i].brandvalue,
               typevalue:json.employee[i].typevalue,
               warrantydate:json.employee[i].warrantydate,
               warrantyremark:json.employee[i].warrantyremark,
              },            
            ]})
          
           
          }
          this.setState({
            invoiceorimeiselectedornot:1,
          })
        }
               
         else{
          window.confirm(json.message)
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  claimbuttonclick = () =>{
    if(Moment(this.state.selectedwarrantystart,"DD/MM/YYYY").unix()<=Moment(this.state.claimdate).unix()&&Moment(this.state.selectedwarrantyend,"DD/MM/YYYY").unix()+86399>=Moment(this.state.claimdate).unix()){
    //update claim date and claim cost
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
   // console.log("checkbuttoncalled")
    //sum of phone category quantity status in ready for sell
    var timestampclaimdate = Moment(this.state.claimdate).unix();
    urlencoded.append("refidofproductsale", this.state.selectedref);
    urlencoded.append("refidofstock", this.state.selectedproductid);
    urlencoded.append("warrantyremark", this.state.warrantyremark);
    urlencoded.append("claimdate", timestampclaimdate);
    
    if(this.state.warrantymethod=="1 to 1 Change"){
      urlencoded.append("productidtochange", this.state.productidtochange);
      urlencoded.append("selectedpastimei", this.state.selectedpastimei);
      urlencoded.append("warrantycost", this.state.warrantycost);
    }else{
      urlencoded.append("productidtochange", 0);
      urlencoded.append("warrantycost", this.state.warrantycost);
    }
    urlencoded.append("selectedimeiclaim", this.state.selectedimeiclaim);
    urlencoded.append("selectedunitpriceclaim", this.state.selectedunitprice1);
    urlencoded.append("selectedtypevalueclaim", this.state.selectedtypevalueclaim);
    urlencoded.append("selectedbrandvalueclaim", this.state.selectedbrandvalueclaim);
    urlencoded.append("selectedproductnameclaim", this.state.selectedproductnameclaim);
    urlencoded.append("selectedcostpriceclaim", this.state.selectedcostpriceclaim);
    urlencoded.append("selectedquantityvalueclaim", this.state.selectedquantityvalueclaim);
    urlencoded.append("selectedproducttradeinornot", this.state.warrantymethod);
    urlencoded.append("repairsparepart", this.state.repairsparepart);

  
    let link = this.state.linkurl +'mode=admin-claimwarrantyupdate'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        if(window.confirm(json.message)){
          window.location.reload();
        }   
      }else{
        
        if(window.confirm(json.message)){
          window.location.reload();
        }
      }
    })
  }
  else{
    window.scrollTo(0, 0)
    window.confirm("Warranty Have been Over When Claim Requested")
  }
        
  }

  choosenproductnamecheckwarrantydate = (value) =>{
    this.setState({loading:true})
   // var urlencoded = new URLSearchParams();
  
    console.log("Called choosen product check warranty")
    
    var lengthofaccessorieslist = Object.keys(this.state.productlist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

      var nametoshow = this.state.productlist[i].productname + "-" + this.state.productlist[i].typevalue +"-" +this.state.productlist[i].brandvalue +"-" +this.state.productlist[i].ref
      console.log("nametoshow" +nametoshow)
  //  console.log("value: " + value)
    //console.log("nametoshow: " + nametoshow)
      if(nametoshow == value){
       this.setState({
        selectedwarrantystart:this.state.productlist[i].warrantystart,
        selectedwarrantyend:this.state.productlist[i].warrantyend,
        selectedref:this.state.productlist[i].ref,
        selectedunitprice1:this.state.productlist[i].unitprice1,
        selectedproductid:this.state.productlist[i].productid,
        selectedpastimei:this.state.productlist[i].imei,    
        selectedproductname:this.state.productlist[i].productname,
        selectedtypevalue:this.state.productlist[i].typevalue,
        selectedbrandvalue:this.state.productlist[i].brandvalue,
        selectedwarrantyremark:this.state.productlist[i].warrantyremark,      
       })
       
       console.log(this.state.productlist[i].unitprice1)
       console.log("this.state.productlist[i].warrantydate: " + this.state.productlist[i].warrantydate)
      // console.log("this.state.productlist[i].warrantyend: " + this.state.productlist[i].warrantyend)
      // console.log("Moment().unix(): " + Moment().unix());

       if(this.state.productlist[i].warrantydate == "0"){
            if(parseInt(this.state.productlist[i].warrantystart)<= Moment().unix() && parseInt(this.state.productlist[i].warrantyend) >= Moment().unix()){
              this.setState({
                selectedwarrantystart: Moment(this.state.productlist[i].warrantystart*1000).format("DD/MM/YYYY"),
                selectedwarrantyend: Moment(this.state.productlist[i].warrantyend*1000).format("DD/MM/YYYY"),
                canbeclaimornott:"Can be Claimed",
                colorclaim:"green",
              })
              console.log("green")
          }else{
            this.setState({
              selectedwarrantystart: Moment(this.state.productlist[i].warrantystart*1000).format("DD/MM/YYYY"),
              selectedwarrantyend: Moment(this.state.productlist[i].warrantyend*1000).format("DD/MM/YYYY"),
              canbeclaimornott:"Cannot be Claimed",
              colorclaim:"red",
            })}
       }else{
        this.setState({
          selectedwarrantystart: Moment(this.state.productlist[i].warrantystart*1000).format("DD/MM/YYYY"),
          selectedwarrantyend: Moment(this.state.productlist[i].warrantyend*1000).format("DD/MM/YYYY"),
          canbeclaimornott:"Has been Claimed",
          colorclaim:"blue",
        })
       }
      
    
       }
     }
    
       
    

  }

  getclaimablesparepartlist = (value) =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    //console.log("checkbuttoncalled")
    //sum of phone category quantity status in ready for sell   
    
    urlencoded.append("sparepart", value);
    urlencoded.append("server",  this.state.server);
    let link = this.state.linkurl +'mode=admin-getsparepartcanbeclaimlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        console.log(json)
        this.setState({sparepartlistcanbechanged:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           //var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
         
            this.setState({sparepartlistcanbechanged:[...this.state.sparepartlistcanbechanged,
              { no:i+1,
               ref:json.employee[i].Ref,
               title:json.employee[i].productname + '--' + json.employee[i].typevalue +'--'+json.employee[i].brandvalue,
               typevalue:json.employee[i].typevalue,
               brandvalue:json.employee[i].brandvalue,
                productname:json.employee[i].productname,
                costprice:json.employee[i].costprice,
                quantityvalue:json.employee[i].quantityvalue,
              },            
            ]})           
          
           
          }        
        }
               
         else{
          window.confirm(json.message)
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getclaimableproductlist = (value) =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    //console.log("checkbuttoncalled")
    //sum of phone category quantity status in ready for sell   
    
    urlencoded.append("productnameselected", value);
    urlencoded.append("selectedtypevalue", this.state.selectedtypevalue);
    urlencoded.append("selectedbrandvalue",  this.state.selectedbrandvalue);
    urlencoded.append("server",  this.state.server);
    let link = this.state.linkurl +'mode=admin-getproductnamecanbeclaimlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        console.log("check getclaimproductlist")
        
        console.log(json)
        this.setState({productlistcanbechanged:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           //var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
            console.log(json.employee[i].repaircost)
            this.setState({productlistcanbechanged:[...this.state.productlistcanbechanged,
              { no:i+1,
               ref:json.employee[i].Ref,
               title:json.employee[i].productname + '--' + json.employee[i].imei +'--' + json.employee[i].typevalue +'--'+json.employee[i].brandvalue,
               imei:json.employee[i].imei,
               typevalue:json.employee[i].typevalue,
               brandvalue:json.employee[i].brandvalue,
                productname:json.employee[i].productname,
                costprice:json.employee[i].costprice,
                repaircost1:json.employee[i].repaircost,
                repaircost2:json.employee[i].repaircost2,
                repaircost3:json.employee[i].repaircost3,
                repaircost4:json.employee[i].repaircost4,
                repaircost5:json.employee[i].repaircost5,
                quantityvalue:json.employee[i].quantityvalue,
              },            
            ]})           
          
           
          }        
        }
               
         else{
          window.confirm(json.message)
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }


  
  selectproductchangefunction = (value) =>{
    this.setState({warrantymethod:value})
    if(value == "1 to 1 Change"){
      this.getclaimableproductlist(this.state.selectedproductname)
    }
    
  }

  selectsparepartchangefunction = (value) =>{
    this.setState({repairsparepart:value})
    this.getclaimablesparepartlist(value)
    
  }

  selectproductchangeable = (value) =>{
    this.setState({productchangeablevalue:value})
    var lengthofphonelist = Object.keys(this.state.productlistcanbechanged).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.productlistcanbechanged[i].title == value){
         this.setState({
          warrantycost:Number.parseInt(this.state.productlistcanbechanged[i].costprice) + Number.parseInt(this.state.productlistcanbechanged[i].repaircost1) + Number.parseInt(this.state.productlistcanbechanged[i].repaircost2) +Number.parseInt(this.state.productlistcanbechanged[i].repaircost3)  +Number.parseInt(this.state.productlistcanbechanged[i].repaircost4)  +Number.parseInt(this.state.productlistcanbechanged[i].repaircost5) ,
          productidtochange:this.state.productlistcanbechanged[i].ref,
          selectedimeiclaim:this.state.productlistcanbechanged[i].imei,
          selectedtypevalueclaim:this.state.productlistcanbechanged[i].typevalue,
          selectedbrandvalueclaim:this.state.productlistcanbechanged[i].brandvalue,
          selectedproductnameclaim:this.state.productlistcanbechanged[i].productname,
          selectedcostpriceclaim:Number.parseInt(this.state.productlistcanbechanged[i].costprice) + Number.parseInt(this.state.productlistcanbechanged[i].repaircost1) + Number.parseInt(this.state.productlistcanbechanged[i].repaircost2) +Number.parseInt(this.state.productlistcanbechanged[i].repaircost3) +Number.parseInt(this.state.productlistcanbechanged[i].repaircost4) +Number.parseInt(this.state.productlistcanbechanged[i].repaircost5),
          selectedquantityvalueclaim:this.state.productlistcanbechanged[i].quantityvalue,    
               
         })
       }      
    }      
  }

  selectsparepartbrandchangeable = (value) =>{
    this.setState({sparepartbrandchangeablevalue:value})
    var lengthofphonelist = Object.keys(this.state.sparepartlistcanbechanged).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.sparepartlistcanbechanged[i].title == value){
         this.setState({
          warrantycost:this.state.sparepartlistcanbechanged[i].costprice,
          productidtochange:this.state.sparepartlistcanbechanged[i].ref,
          selectedtypevalueclaim:this.state.sparepartlistcanbechanged[i].typevalue,
          selectedbrandvalueclaim:this.state.sparepartlistcanbechanged[i].brandvalue,
          selectedproductnameclaim:this.state.sparepartlistcanbechanged[i].productname,
          selectedcostpriceclaim:this.state.sparepartlistcanbechanged[i].costprice,
          selectedquantityvalueclaim:this.state.sparepartlistcanbechanged[i].quantityvalue,    
               
         })
       }      
    }      
  }

  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Claim Warranty:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Quotation Number is {this.state.quotationnumbershow}.</p>
             
               <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/quotation"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Create Another Quotation
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body mt--5">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
  <Row>
  <Col md="6">
  <p className="h5">Phone/Accessories/SparePart:</p>
         <FormGroup >
                         <Autocomplete
                           options={[{title:'Phone'},{title:'Accessories'},{title:'Spare Part'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.categoryselected(value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
    </Col>
    </Row>


{this.state.categoryselectedornot ? 
 <Row>
 <Col md="6">
 <p className="h5">Invoice / IMEI:</p>
        <FormGroup >
        <Input
                           type="text"
                           onChange={e => this.setState({invoiceorimeitext: e.target.value})}
                           value={this.state.invoiceorimeitext}
                           
                         />
                         
                        </FormGroup>
   </Col>
   <Col md="2">
   <p className="h5">Click to check:</p>
   <Button color="primary" type="button" onClick={this.clicktocheckbuttonclick.bind(this)} >
      Check
    </Button>
   </Col>
   </Row>

:null}
   


    {this.state.invoiceorimeiselectedornot == 1 ?
     <Row>
     <Col md="6">
     <p className="h5">Product Name:</p>
            <FormGroup >
                            <Autocomplete
                              options={this.state.productlist}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.productnameselected(value)}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                              disableClearable={true}
                            />                   
                            </FormGroup>
       </Col>
       </Row>
    :
    null}
   

   
 
{this.state.productnameselectedornot == 1 ?
<div>
{/* word section warranty, status */}
<Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
<Row>
  <Col md="9">
<p className="h3">Warranty Start : {this.state.selectedwarrantystart}    -     Warranty End : {this.state.selectedwarrantyend}</p> 
<p className="h3" style={{color:this.state.colorclaim}}>Status : {this.state.canbeclaimornott}</p> 
  </Col>
</Row>
<Row>
  <Col md="12">
<p className="h3">Warranty Remark : {this.state.selectedwarrantyremark}</p> 
  </Col>
</Row>

  

   {/* fill in section claim date or warranty cost */}
<Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>

  {    this.state.canbeclaimornott == "Has been Claimed" ?
  null
  :
  <Row>

 <Col md="4">
 <p className="h5">Claim Date :</p>
 <ReactDatetime
                     inputProps={{
                       placeholder: "Claim Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.claimdate}
                     onChange={(e) => this.setState({claimdate:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />
   </Col>
   <Col md="4">
    <p className="h5">Warranty Method:</p>
         <FormGroup>
         <Autocomplete
                        options={[{title:'Repair'},{title:'1 to 1 Change'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectproductchangefunction(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      />           
                      </FormGroup>
                      </Col>

    {this.state.warrantymethod == "Repair" ?
     <>
     <Col md="4">
     <p className="h5">Warranty Cost:</p>
          <FormGroup>
          <Input
              className="textcolorblack"
              type="number"
              min={0}
              value={this.state.warrantycost} 
              onChange={e => this.setState({warrantycost:e.target.value})}
            />            
                       </FormGroup>
                       </Col>

<Col md="4">
<p className="h5">Repair Spare Part:</p>
     <FormGroup>
     <Autocomplete
                        options={[{title:"Camera"},{title:"Front Camera"},{title:"Battery"},{title:"LCD"},{title:"Housing"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectsparepartchangefunction(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      />           
                  </FormGroup>
                  </Col>

                  <Col md="4">
<p className="h5">Spare Part Brand:</p>
     <FormGroup>
     <Autocomplete
                        options={this.state.sparepartlistcanbechanged}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) => this.selectsparepartbrandchangeable(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      />           
                  </FormGroup>
                  </Col>
                  <Col md="12">
     <p className="h5">Remark:</p>
          <FormGroup>
          <Input
              className="textcolorblack"
              type="text"
              value={this.state.warrantyremark} 
              onChange={e => this.setState({warrantyremark:e.target.value})}
              enabled
            />            
                       </FormGroup>
         </Col>
    </>
    :
    this.state.warrantymethod == "1 to 1 Change" ?
    <>
       <Col md="4">
     <p className="h5">Product Changeable:</p>
          <FormGroup>
          <Autocomplete
                        options={this.state.productlistcanbechanged}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) => this.selectproductchangeable(value)}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      />                 
                       </FormGroup>
         </Col>

         <Col md="4">
     <p className="h5">Warranty Cost:</p>
          <FormGroup>
          <Input
              className="textcolorblack"
              type="float"
              min={0}
              value={this.state.warrantycost} 
              onChange={e => this.setState({warrantycost:e.target.value})}
              disabled
            />            
                       </FormGroup>
         </Col>
         <Col md="12">
     <p className="h5">Remark:</p>
          <FormGroup>
          <Input
              className="textcolorblack"
              type="text"
              value={this.state.warrantyremark} 
              onChange={e => this.setState({warrantyremark:e.target.value})}
              enabled
            />            
                       </FormGroup>
         </Col>
    
    </>
     :
     null
    }
   
   
    </Row>
    
  }
  
        
</div>
:
null}
     
</Form>
  </div>
  <div className="modal-footer">
    {/* <Button
      color="secondary"
      data-dismiss="modal"
      type="button"
      onClick={() => this.toggleModal("editpopuout")}2
    >
      Close
    </Button> */}
    {this.state.productnameselectedornot == 1 ?
    this.state.canbeclaimornott == "Has been Claimed" ?
    null    :

    <Button color="primary" type="button" onClick={this.claimbuttonclick.bind(this)}>
      Claim
    </Button>:null}
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>
          </Row>
        </Container>
     
       

      
      </>
    );
  }
}

export default ClaimWarranty;

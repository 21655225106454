/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import loadinggif from './../../assets/gif/lorrygif.gif'
import ReactToPrint from 'react-to-print';
// reactstrap components
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
} from "reactstrap";
// core components
import ReactDatetime from "react-datetime";
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import Moment from 'moment';
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
class Commission extends React.Component {
  
  constructor(props){
    super(props);  
  } 

  state = {
    tabs: 1,
    tabsimportexport:1,   
    loading:false,   
    saleslist:[],
    producttablelist:[],
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    searchtextvalue:'',
    detailsalemodalonoff:false,
    editdetailinvoice:false,
    //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"https://crm.mewahtrans.com.my/img/profile_img/",
     linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    // linkurl:"https://cors-anywhere.herokuapp.com/https://pos.mzprotrading.com/api/mzprotrading.php?",
    startDate:Moment(new Date).add(-1,'d'),
    endDate:new Date,
    deletemodalproductonoff:false,
    deletemodalonoff:false,
    addnewproductmodalonoff:false,
    messageboxmodalonoff:false,
    messageformessagebox:"",
    selectedjobid:0,
    userid:localStorage.getItem('userid'),
    customerlist:[],
    userlist:[],
  };


  //enddatetimestamp: 1672502399
///Sales.jsx:291 startdatetimestamp: 1669824000

//enddatetimestamp: 1672486569
//Commission.jsx:112 startdatetimestamp: 1669894569
  getcommission = () =>{     
   
    this.setState({loading:true}) 

    var enddatetimestamp = Moment(this.state.endDate).endOf('day').unix();
    console.log("enddatetimestamp: " + enddatetimestamp);

    var startdatetimestamp = Moment(this.state.startDate).startOf('day').unix();
    console.log("startdatetimestamp: " + startdatetimestamp);

    
      // var enddatetimestamp = Moment(this.state.endDate).unix();
      // console.log("enddatetimestamp: " + enddatetimestamp);

      // var startdatetimestamp = Moment(this.state.startDate).unix();
      // console.log("startdatetimestamp: " + startdatetimestamp);

     var urlencoded = new URLSearchParams();
   
     urlencoded.append("thismonthstart", startdatetimestamp);
     urlencoded.append("thismonthend", enddatetimestamp);
     urlencoded.append("usernamecontrol", this.state.userid);

     let link = this.state.linkurl +'mode=admin-getcommissioncalculation'
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
       console.log(json.success)
       //console.log(json)
       if(json.success == 1){
        
        this.setState({userlist:[]});   
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){      
          
          if(json.user[i].totalsale == null){
            json.user[i].totalsale = 0 
          }

          if(json.user[i].totalcommission == null){
            json.user[i].totalcommission = 0 
          }

          if(json.user[i].phonequantity == null){
            json.user[i].phonequantity = 0 
          }

           this.setState({userlist:[...this.state.userlist,
             { no:i+1,
              username:json.user[i].name,
              phonequantity:json.user[i].phonequantity,
              totalcommission:json.user[i].totalcommission,
              totalsale:json.user[i].totalsale,
             },            
           ]})
         
           
          
         }
         //console.log(this.state.userlist)
         this.setState({loading:false}) 
        }        
           
     else{
     
      this.setState({loading:false}) 
      console.log(json)
  }
      })
   
  }  

 



  componentDidMount(){
    this.getcommission()
  }

  rendersaleslist() {
    
    return this.state.userlist.map((data, index) => {
    
       const {no,ref,username,phonequantity,totalsale,totalcommission} = data 
       return (
    
            
            <tbody>               
                   
             <tr>
   
            <td>{no})</td>
              <td >{username}</td>
              <td >{phonequantity}</td>
              <td >{totalsale}</td>        
                  <td>{totalcommission}</td> 
                 
               </tr>    
                 
                </tbody>

       
       
   
       )
    })
  } 
  
 

  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--6" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
        
            </div>
            <div className=" col-12 ">
              <Card className=" shadow  ">
              <Row className="marginleftcss">                
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">From: </span>
                    </Col>
                    <Col md={2}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">To: </span>
                    </Col>
                    <Col md={2}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
                    </Col>
                    <Col md={2}>
                    <Button  className={classnames("mb-sm-3 mb-md-0")} color="blue" type="button" style={{marginLeft:-10}} onClick={(e) => this.getcommission()}>
                         Search
                      </Button>
                    </Col>
            </Row>
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
               
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  
                 <tr>
                   <th>No.</th>
                   <th >User</th>
                   <th  >Total Phone</th>    
                   <th  >Total Sales</th>       
                   <th  >Total Commission</th>  
                   
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.rendersaleslist()}                       
                   </Table>
                  
             
                  </TabPane>
                   
                </TabContent>
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>
        
     


      </>
    );
  }

  }


export default Commission;

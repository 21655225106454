/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import loadinggif from './../../assets/gif/lorrygif.gif'
// reactstrap components
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
} from "reactstrap";
// core components
import ReactDatetime from "react-datetime";
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import Moment from 'moment';
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useEffect } from "react";

class ComponentToPrint extends React.Component {

  state = {
    printinvoicenum:"",
    printinvoicedate:"",
    printpage:"",
    printpayment1:"",
    printpayment2:"",
    printpayment3:"",
    printpayment1v:"",
    printpayment2v:"",
    printpayment3v:"",
    totalprice:"",
    memberid:"",
    tradeinphone1:'',
    tradeinimei1:'',
    tradeinprice1:0,
    tradeinphone2:'',
    tradeinimei2:'',
    tradeinprice2:0,
    tradeinphone3:'',
    tradeinimei3:'',
    tradeinprice3:0,
    selectedcreditcharge:0,
    invoicedetailslist:[],
    linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    server:localStorage.getItem('server'),
  }
  
  printinvoice=(invoicenum)=>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("invoicenum", invoicenum);
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-getinvoicedetails'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    body:urlencoded
  })
  .then(response => response.json())
  .then(json => {
    console.log(json.success)
    if(json.success == 1){
      
          console.log(json)
          this.setState({invoicedetailslist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
            this.setState({printinvoicenum:json.invoiceinfo.invoicenumber});
            this.setState({printinvoicedate:json.invoiceinfo.invoicedate});
            this.setState({printpage:json.invoiceinfo.page});
            this.setState({printpayment1:json.invoiceinfo.payment1});
            this.setState({printpayment2:json.invoiceinfo.payment2});
            this.setState({printpayment3:json.invoiceinfo.payment3});
            this.setState({printpayment1v:json.invoiceinfo.payment1v});
            this.setState({printpayment2v:json.invoiceinfo.payment2v});
            this.setState({printpayment3v:json.invoiceinfo.payment3v});
            this.setState({totalprice:json.invoiceinfo.totalprice});
            this.setState({memberid:json.invoiceinfo.memberid});
             for(let i=0;i<lengthemployee;i++){
                this.setState({invoicedetailslist:[...this.state.invoicedetailslist,
                  { 
                    no:json.employee[i].no,
                    description:json.employee[i].description,
                    qty:json.employee[i].quantity,
                    unit:json.employee[i].unit,
                    subtotal:json.employee[i].Amount,
                  },
                ]}) 

              console.log(this.state.invoicedetailslist)
             }
            // this.setState({loading:false})  
           }        
              
        else{
         this.setState({loading:false}) 
       console.log(json)
     }
    })
    
  }

  rendertable(){
    var i=0;
    return this.state.invoicedetailslist.slice(0,100).map((data, index) => {
      const {no,description,qty,unit,subtotal} = data //destructuring
      var rotlength = Object.keys(this.state.invoicedetailslist).length;
      return (
        <>
        {i=i+1}
        <span class={"Displayr"+i+"c1printcss"}>{no}</span>
        <span class={"Displayr"+i+"c2printcss"}>{description}</span>
        <span class={"Displayr"+i+"c3printcss"}>{qty}</span>
        <span class={"Displayr"+i+"c4printcss"}>{unit}</span>
        <span class={"Displayr"+i+"c5printcss"}>{subtotal}</span>
        </>
      )
    })
  }

  componentDidMount(){
  this.printinvoice(this.props.invoicenum)
  }



render(){
  return(
    <div class="containerprint">
    <img class="invoicetemplatecss"
    width="1000px"
    height="1442px"
    src={require("./../../assets/gif/invoicetemplatepdfFile.jpg")}
  />
    <span class="invoicenumberprintcss">{this.state.printinvoicenum}</span>
    <span class="invoicedateprintcss">{this.props.invoicedate}</span>
    <span class="pagenumberprintcss">{this.state.printpage}</span>
    {this.state.memberid!=="-"?
  <>
<span class="Displayrmemberidprintcss">{"Member ID: "+this.state.memberid}</span>
  </>  
:
null  
}

      {this.rendertable()}
      <span class="Totalpriceprintcss">{this.state.totalprice}</span>
      {this.state.printpayment1=="Credit Card"?
      <span class="Creditcardpaymentprintcss">{this.state.printpayment1v}</span>
      :
      this.state.printpayment1=="Online Banking"?
      <span class="Onlinetransferpaymentprintcss">{this.state.printpayment1v}</span>
      :
      this.state.printpayment1=="Cash"?
      <span class="Cashpaymentprintcss">{this.state.printpayment1v}</span>
      :
      this.state.printpayment1=="Ewallet"?
      <span class="Tngpaymentprintcss">{this.state.printpayment1v}</span>
      :
      this.state.printpayment1=="Ansuran"?
      <span class="Ansuranprintcss">{this.state.printpayment1v}</span>
      :
      null
    }
    {this.state.printpayment2=="Credit Card"?
      <span class="Creditcardpaymentprintcss">{this.state.printpayment2v}</span>
      :
      this.state.printpayment2=="Online Banking"?
      <span class="Onlinetransferpaymentprintcss">{this.state.printpayment2v}</span>
      :
      this.state.printpayment2=="Cash"?
      <span class="Cashpaymentprintcss">{this.state.printpayment2v}</span>
      :
      this.state.printpayment2=="Ewallet"?
      <span class="Tngpaymentprintcss">{this.state.printpayment2v}</span>
      :
      this.state.printpayment2=="Ansuran"?
      <span class="Ansuranprintcss">{this.state.printpayment2v}</span>
      :
      null
    }
     {this.state.printpayment3=="Credit Card"?
      <span class="Creditcardpaymentprintcss">{this.state.printpayment3v}</span>
      :
      this.state.printpayment3=="Online Banking"?
      <span class="Onlinetransferpaymentprintcss">{this.state.printpayment3v}</span>
      :
      this.state.printpayment3=="Cash"?
      <span class="Cashpaymentprintcss">{this.state.printpayment3v}</span>
      :
      this.state.printpayment3=="Ewallet"?
      <span class="Tngpaymentprintcss">{this.state.printpayment3v}</span>
      :
      this.state.printpayment3=="Ansuran"?
      <span class="Ansuranprintcss">{this.state.printpayment3v}</span>
      :
      null
    }
    </div>
    )
    }
  }

class Sales extends React.Component {
  
  constructor(props){
    super(props);  
  } 

  state = {
    tabs: 1,
    tabsimportexport:1,   
    loading:false,   
    saleslist:[],
    producttablelist:[],
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    searchtextvalue:'',
    server:localStorage.getItem('server'),
    detailsalemodalonoff:false,
    editdetailinvoice:false,
    //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"https://crm.mewahtrans.com.my/img/profile_img/",
     linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    // linkurl:"https://cors-anywhere.herokuapp.com/https://pos.mzprotrading.com/api/mzprotrading.php?",
    startDate:Moment(new Date).add(-1,'d'),
    endDate:new Date,
    deletemodalproductonoff:false,
    deletemodalonoff:false,
    addnewproductmodalonoff:false,
    messageboxmodalonoff:false,
    messageformessagebox:"",
    selectedjobid:0,
    userid:localStorage.getItem('userid'),
    customerlist:[],
    printinvoicenum:"",
    printinvoicedate:"",
    printpage:"",
    printpayment1:"",
    printpayment2:"",
    printpayment3:"",
    printpayment1v:"",
    printpayment2v:"",
    printpayment3v:"",
    totalprice:"",    
    invoicedetailslist:[],
    findcustomer:"Member ID",
  };



  getsaleslist = () =>{     
   
    
      var enddatetimestamp = Moment(this.state.endDate).endOf('day').unix();
      console.log("enddatetimestamp: " + enddatetimestamp);

      var startdatetimestamp = Moment(this.state.startDate).startOf('day').unix();
      console.log("startdatetimestamp: " + startdatetimestamp);

     var urlencoded = new URLSearchParams();

     if(startdatetimestamp > enddatetimestamp){
      window.confirm("Start Date cannot before End Date")
       return;
     }
     urlencoded.append("startdatetimestamp", startdatetimestamp);
     urlencoded.append("enddatetimestamp", enddatetimestamp);
     urlencoded.append("usernamecontrol", this.state.userid);
     urlencoded.append("server", this.state.server);

     let link = this.state.linkurl +'mode=admin-selectsaleslist'
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
       console.log(json.success)
       //console.log(json)
       if(json.success == 1){
        
        console.log(json)
        this.setState({saleslist:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           var invoicedatetoshow = Moment(json.employee[i].invoicedate*1000).format('DD/MM/YYYY')
            this.setState({saleslist:[...this.state.saleslist,
              { no:i+1,
                ref:json.employee[i].Ref,
                invoiceno:json.employee[i].invoicenumber,   
                salesperson:json.employee[i].salesperson,             
                totalprice:json.employee[i].totalprice,
                invoicedate:invoicedatetoshow, 
                totalnumberofproduct:json.employee[i].totalnumberofproduct,  
                saleperson:json.employee[i].salesperson,  
                customername:json.employee[i].customername,  
                phonenumbervalue:json.employee[i].phonenumbervalue,  
                paymentmethodvalue:json.employee[i].paymentmethodvalue, 
                paymentmethod1value:json.employee[i].paymentmethod1value, 
                
                paymentmethod2:json.employee[i].paymentmethod2, 
                paymentmethod2value:json.employee[i].paymentmethod2value, 

                paymentmethod3:json.employee[i].paymentmethod3, 
                paymentmethod3value:json.employee[i].paymentmethod3value, 
                remark:json.employee[i].remark,               
                phoneprefix:json.employee[i].phoneprefix,
                memberdiscountprice:json.employee[i].memberdiscountprice,
                memberid:json.employee[i].memberid,
                productchangeid:json.employee[i].productchangeid,
                productid:json.employee[i].productid,
                
                
              },            
            ]})
          
          //  console.log(phonenumbervalue)
           
          }
         // this.setState({loading:false})  
        }        
           
     else{
      this.setState({saleslist:[]}); 
      this.setState({loading:false}) 
    console.log(json)
  }
      })
   
  }  

  getphonestocklist = () =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectphonestocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({stockphonelist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
            this.setState({stockphonelist:[...this.state.stockphonelist,
              { no:i+1,
                productname:json.employee[i].productname,   
                imei:json.employee[i].imei,             
                storagevalue:json.employee[i].storagevalue,
                colorvalue:json.employee[i].colorvalue,
                phoneremark:json.employee[i].remark,
                //fingerprintvalue:json.employee[i].fingerprintvalue,
                //brandvalue:json.employee[i].brandvalue,      
                //faceidvalue:json.employee[i].faceidvalue,
                saleprice:json.employee[i].saleprice,
                quantityvalue:json.employee[i].quantityvalue,
                purchasedate:purchasedateshow,
                statusvalue:json.employee[i].statusvalue,
                title:json.employee[i].imei,
                productid:json.employee[i].Ref,
                costprice:json.employee[i].costprice,
                repaircost:json.employee[i].repaircost+json.employee.repaircost2+json.employee.repaircost3+json.employee.repaircost4+json.employee.repaircost5,
                typevalue:json.employee[i].typevalue,
                brandvalue:json.employee[i].brandvalue,
              },            
            ]})
          }
          
         
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  selectedcategoryfunction = (value) =>{
    this.setState({selectedcategory:value})

    //empty all
    this.setState({
      selectedproductname:'',
      selectedquantity:0,
      //selectedremark:'',
      selectedsubtotal:0,
      selectedquantity:0,
      selectedsalesdate:'',
      selectedwarrantyduration:'',
      selectedwarrantystartdate:'',    
      selectedunitprice:0,
    })


    if(value == "Phone"){
      this.getphonestocklist();
    }else if(value == "Accessories"){
      this.getaccessoriesstocklist();
    }else if(value == "Spare Part"){
      this.getsparepartstocklist();
    }
  }

  getaccessoriesstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectaccesoriesstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
      
      console.log(json)
      this.setState({stockaccessorieslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
          this.setState({stockaccessorieslist:[...this.state.stockaccessorieslist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              costprice:json.employee[i].costprice,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              repaircost:json.employee[i].repaircost+json.employee.repaircost2+json.employee.repaircost3+json.employee.repaircost4+json.employee.repaircost5,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }

  getsparepartstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-selectsparepartstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)2
      if(json.success == 1){
      
      console.log(json)
      this.setState({stocksparepartlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].statusvalue == "Ready For Sell"){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              costprice:json.employee[i].costprice,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
              repaircost:json.employee[i].repaircost+json.employee.repaircost2+json.employee.repaircost3+json.employee.repaircost4+json.employee.repaircost5,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }

  submitaddproductfunction = () =>{
    
    var errorornot = false;
    var errormessage = "";

  if(this.state.selectedcategory == "Phone"){

    if(this.state.selectedimei =="" || this.state.selectedimei ==undefined){
      errormessage="Please fill in Phone IMEI"
      errorornot=true;    
     
    }

  }

  if(this.state.selectedproductname =="" || this.state.selectedproductname ==undefined){
    errormessage="Please fill in Product Name"
    errorornot=true;    
  }


  if(this.state.selectedquantity ==0 || this.state.selectedquantity ==undefined){
    errormessage="Please fill in Product Quantity"
    errorornot=true; 
  } 

  if(this.state.selectedsalesdate =="" || this.state.selectedsalesdate ==undefined){
    errormessage="Please fill in Sales Date"
    errorornot=true; 
  } 


  if(this.state.selectedwarrantystartdate =="" || this.state.selectedwarrantystartdate ==undefined){
    errormessage="Please fill in Warranty Start"
    errorornot=true; 
  } 

  if(this.state.selectedwarrantyduration =="" || this.state.selectedwarrantyduration ==undefined){
    errormessage="Please fill in Warranty Duration"
    errorornot=true; 
  }

  if( errorornot==true){
    window.scrollTo(0, 0)
    window.confirm(errormessage)
  }else{
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();      
       
        urlencoded.append("selectedcategory", this.state.selectedcategory);
        if(this.state.selectedcategory == "Phone"){
          urlencoded.append("selectedimei", this.state.selectedimei);
          urlencoded.append("selectedtype", '-');
          urlencoded.append("selectedbrand", '-');
        }else{
          urlencoded.append("selectedimei", '-');
          urlencoded.append("selectedtype", this.state.selectedtype);
          urlencoded.append("selectedbrand", this.state.selectedbrand);
        }
        urlencoded.append("selectedproductid", this.state.selectedproductid);      
        urlencoded.append("selectedunitprice", this.state.selectedunitprice);  
        urlencoded.append("selectedproductname", this.state.selectedproductnameforupload);
        urlencoded.append("selectedquantity", this.state.selectedquantity);
        urlencoded.append("selectedsubtotal", this.state.selectedsubtotal);

        var salesdate = new Date(this.state.selectedsalesdate).getTime()/1000;
        urlencoded.append("selectedsalesdate", salesdate);

      
        var warrantystart = new Date(this.state.selectedwarrantystartdate).getTime()/1000;
        var warrantystartdate = Moment(this.state.selectedwarrantystartdate, 'DD/MM/YYYY');
        urlencoded.append("selectedwarrantystart", warrantystart);

        if(this.state.selectedwarrantyduration == "1 Week"){
          var warrantyenddate = warrantystartdate.add(1, 'week')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "2 Weeks"){
          var warrantyenddate = warrantystartdate.add(2, 'week')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "3 Weeks"){
          var warrantyenddate = warrantystartdate.add(3, 'week')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration== "1 Month"){
          var warrantyenddate = warrantystartdate.add(1, 'M')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "2 Months"){
          var warrantyenddate = warrantystartdate.add(2, 'M')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "3 Months"){
          var warrantyenddate = warrantystartdate.add(3, 'M')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "1 Year"){
          var warrantyenddate = warrantystartdate.add(1, 'y')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "2 Years"){
          var warrantyenddate = warrantystartdate.add(2, 'y')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "3 Years"){
          var warrantyenddate = warrantystartdate.add(3, 'y')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "No Warranty"){
          var warrantyenddate = 0
          urlencoded.append("selectedwarrantyend", warrantyenddate);
        }
        
       
       
        var selectedinvoicedatetimestamp = Moment(this.state.selectedinvoicedate,'DD/MM/YYYY').unix();
        urlencoded.append("selectedinvoicedate", selectedinvoicedatetimestamp);
        
      
 
      urlencoded.append("invoicenumber", this.state.selectedinvoicenumber);
      
      urlencoded.append("selectedsaleperson", this.state.selectedsaleperson);
      urlencoded.append("selectedcustomername", this.state.selectedcustomername);

      urlencoded.append("phoneprefix", this.state.selectedphoneprefix);
      urlencoded.append("costprice", this.state.selectedcostprice);

      if(this.state.selectedphonenumber =="" || this.state.selectedphonenumber ==undefined){
        urlencoded.append("selectedphonenumber", '-')
      }else{       
        urlencoded.append("selectedphonenumber", this.state.selectedphonenumber)
      }
      
      if(this.state.howmanypaymentmethod == "Two"){
        urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
        urlencoded.append("selectedpaymentmethod2", this.state.selectedpaymentmethod2);
        urlencoded.append("selectedpaymentmethod3", "");
        urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
        urlencoded.append("selectedpaidvalue2", this.state.selectedpaidvalue2);
        urlencoded.append("selectedpaidvalue3", 0);
      }else if(this.state.howmanypaymentmethod == "Three"){
        urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
        urlencoded.append("selectedpaymentmethod2", this.state.selectedpaymentmethod2);
        urlencoded.append("selectedpaymentmethod3", this.state.selectedpaymentmethod3);
        urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
        urlencoded.append("selectedpaidvalue2", this.state.selectedpaidvalue2);
        urlencoded.append("selectedpaidvalue3", this.state.selectedpaidvalue3);
      }
      else{
        urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
        urlencoded.append("selectedpaymentmethod2", "");
        urlencoded.append("selectedpaymentmethod3", "");
        urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
        urlencoded.append("selectedpaidvalue2", 0);
        urlencoded.append("selectedpaidvalue3", 0);
      }

      urlencoded.append("memberid", this.state.selectedmemberid);
      urlencoded.append("productchangeid", "");
      urlencoded.append("repaircost", this.state.selectedrepairprice);
      urlencoded.append("memberdiscountprice", this.state.selectedmemberdiscountprice);


   

    //  console.log("selectedwarrantystart: " + this.state.selectedwarrantystartdate);
     // console.log("warrantystartdate: " + warrantystartdate);
    //  console.log("warrantyend: " + warrantyend);

     // console.log("selectedpaymentmethod1: " + this.state.selectedpaymentmethod1);
     // console.log("selectedpaidvalue1: " + this.state.selectedpaidvalue1);
     // console.log("selectedpaymentmethod2: " + this.state.selectedpaymentmethod2);
    //  console.log("selectedpaidvalue2: " + this.state.selectedpaidvalue2);
     // console.log("selectedinvoicedate: " + this.state.selectedinvoicedate);
      
     //return
      //return;
      urlencoded.append("selectedremark", this.state.selectedremark);
      urlencoded.append("usernamecontrol", this.state.userid);
      let link = this.state.linkurl +'mode=admin-updateaddnewproduct'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){          
              //this.setState({aftersubmitstatus:1})
              this.setState({messageformessagebox:"Update Success"});        
              this.setState({
                endDate:new Date,
                startDate:Moment(new Date).add(-1,'d'),
              })
              this.getsaleslist();
              this.togglemessagemodal("messageboxmodalonoff")
              this.toggleModaldetailinvoice("detailsalemodalonoff")
              this.toggleaddnewproduct("addnewproductmodalonoff")
              this.setState({loading:false}) 
        }
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
            console.log(json)
        }
      
       })    
  }   
  }


  selectuser =() =>{
    //this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("usernamecontrol", this.state.userid);

    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
    
  }

  componentDidMount(){
    this.getsaleslist();  
    this.selectuser();
    this.getcustomerlist();
   // this.getphonestocklist();
  }

  rendersaleslist() {
    
    return this.state.saleslist.map((data, index) => {
    
       const {no,ref,productid,invoiceno,remark,imei,saleperson,totalprice,invoicedate, totalnumberofproduct,customername,phonenumbervalue,paymentmethodvalue,paymentmethod1value,paymentmethod2,paymentmethod2value,paymentmethod3,paymentmethod3value,costprice,phonenumber,phoneprefix,productchangeid,memberid,memberdiscountprice} = data 
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{invoiceno}</td>
              <td>{invoicedate}</td> 
              <td >{saleperson}</td>
              <td >{totalnumberofproduct}</td>        
                  <td>{totalprice}</td> 
                 
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                      href="#pablo"
                      onClick={()=>this.toggleModaldetailinvoiceeditdetail("editdetailinvoice",invoiceno,remark,totalprice,invoicedate,totalnumberofproduct,saleperson,customername,phonenumbervalue,paymentmethodvalue,paymentmethod1value,paymentmethod2,paymentmethod2value,paymentmethod3,paymentmethod3value,productchangeid,memberid,memberdiscountprice)}
                    >
                      Edit Detail
                    </DropdownItem> 
                    <DropdownItem
                      href="#pablo"
                      onClick={()=>this.toggleModaldetailinvoice("detailsalemodalonoff",invoiceno,saleperson,totalprice,invoicedate,totalnumberofproduct,phonenumbervalue,phoneprefix,customername,paymentmethodvalue,paymentmethod2,paymentmethod3,paymentmethod1value,paymentmethod2value,paymentmethod3value,remark,imei,productchangeid,memberid,memberdiscountprice)}
                    >  
   
                      Edit Product
                    </DropdownItem>
                    <ReactToPrint
                    trigger={()=><DropdownItem
                      id="print-button"                    
                      >
                        
                        Print Invoice
                        </DropdownItem>}
                    content={() => this.componentRef}>
      </ReactToPrint>
                <div style={{ display: "none" }}><ComponentToPrint
                invoicenum={invoiceno}
                invoicedate={invoicedate}
                ref={el => (this.componentRef = el)} /></div>   
                   
                    
    
                    {/* <DropdownItem
                      href="#pablo"
                      onClick={() => this.toggledeletemodal("deletemodalonoff",invoiceno)}
                    >
                     Delete
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                  </tr>

                  

                 
                </tbody>

       
       
   
       )
    })
  } 

 

  renderproductsaletable() {
      
    return this.state.producttablelist.map((data, index) => {
    
       const {no,ref,productid,productname,type,imei,category,storage,brand,unitprice, quantity,salesdate,warrantystart,warrantyend,productchangeid,productchangeimei,totalnumberofproduct,totalprice,paymentmethod1value,paymentmethod2value,paymentmethod3value} = data 
       var substotal = quantity * unitprice
       var substotalfinal = substotal.toFixed(2)

   
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no}</td>
              <td >{productname}</td>
              {category == 'Phone' ?
              <td>{imei}</td> 
              :
              <td>{type}</td> 
              }
              <td>{storage}</td>
              <td >{brand}</td>
              <td >{unitprice}</td>        
                  <td>{quantity}</td> 
                  <td>{substotalfinal}</td> 
                  <td>{salesdate}</td> 
                  <td>{warrantystart}</td> 
                  <td>{warrantyend}</td>
                  {productchangeid!==""?
                <td>{"1 to 1 exchange Past imei:"+productchangeimei}</td>  
                :
                <td>{""}</td>
                } 
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                      href="#pablo"
                       onClick={()=>this.toggledeleteproductmodal("deletemodalproductonoff",ref,productid,substotalfinal,totalnumberofproduct,totalprice,paymentmethod1value,paymentmethod2value,paymentmethod3value)}
                    >
                      Delete Product
                    </DropdownItem>                     
                   
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                  </tr>    
                 
                </tbody>

       
       
   
       )
    })
  }
  
  toggledeletemodal = (state,invoicenumber) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:invoicenumber,
    });
  }

  toggledeleteproductmodal = (state,ref,productid,subtotalfinal,totalnumberofproduct,totalprice,paymentmethod1value,paymentmethod2value,paymentmethod3value) =>{
    this.setState({
      [state]: !this.state[state],
      selectedref:ref,
      selectedproductid:productid,
      selectedsubtotalfinal:subtotalfinal,
      selectedtotalnumberofproduct:totalnumberofproduct,
      selectedtotalprice:totalprice,
      selectedpaymentmethod1value:paymentmethod1value,
      selectedpaymentmethod2value:paymentmethod2value,
      selectedpaymentmethod3value:paymentmethod3value,
    });
    console.log("Ref:" + ref)
    console.log("productid" + productid)
  }

  toggleaddnewproduct= (state,selectedinvoicenumber) =>{
    this.setState({
      [state]: !this.state[state],
      invoicecnumber:selectedinvoicenumber,
      selectedproductnameforupload:'',
      selectedquantity:0,
     
      selectedsubtotal:'',
      selectedunitprice:'',
      selectedproductname:'',
      selectedcategory:'',    
    
      selectedsalesdate:'',
      selectedwarrantyduration:'',
      selectedwarrantystartdate:'',
      selectedimei:'',  

     

      
    });

      console.log("Remark: " + this.state.selectedremark)
      console.log("invoicedate: " + this.state.selectedinvoicedate)
      console.log("paymentmethod: " + this.state.selectedpaymentmethod1)
      console.log("paymentmethod2: " + this.state.selectedpaymentmethod2)
      console.log("paymentvalue1: " + this.state.selectedpaidvalue1)
      console.log("paymentvalue2: " + this.state.selectedpaidvalue2)
      console.log("memberid: " + this.state.selectedmemberid)
      console.log("memberdiscountprice: " + this.state.selectedmemberdiscountprice)
   
  }

  togglemessagemodal = state =>{
    this.setState({
      [state]: !this.state[state]
    });
  }

  toggleModaldetailinvoice = (state,invoicenumber,salesperson,totalprice,invoicedate,totalnumberofproduct,phonenumber,phoneprefix,customername,paymentmethod1,paymentmethod2,paymentmethod3,paymentvalue1,paymentvalue2,paymentvalue3,remark,imei,productchangeid,memberid,memberdiscountprice) => {
    this.setState({
      [state]: !this.state[state],
      selectedinvoicenumber:invoicenumber,      
      selectedtotalprice:totalprice,
      selectedinvoicedate:invoicedate,
      selectedtotalnumberofproduct:totalnumberofproduct,

     // selectedcostprice:costprice,
      selectedphonenumber:phonenumber,
      selectedphoneprefix:phoneprefix,

      selectedsaleperson:salesperson,
      selectedcustomername:customername,
      selectedpaymentmethod1:paymentmethod1,
      selectedpaymentmethod2:paymentmethod2,
      selectedpaymentmethod3:paymentmethod3,
      selectedpaidvalue1:paymentvalue1,
      selectedpaidvalue2:paymentvalue2,
      selectedpaidvalue3:paymentvalue3,
      selectedremark:remark,
      selectedimei:imei,
      selectedproductchangeid:productchangeid,
      selectedmemberid:memberid,
      selectedmemberdiscountprice:memberdiscountprice,


    });
    if(paymentmethod2 == "" && paymentmethod3 == ""){
      this.setState({howmanypaymentmethod:"One"})     
    }else if(paymentmethod3 == "" && paymentmethod2 != ""){
      this.setState({howmanypaymentmethod:"Two"})
    }else{
      this.setState({howmanypaymentmethod:"Three"})
    }


    if(!this.state[state] == true){
      this.gettdettailinvoicetable(invoicenumber);
    }
  };

  toggleModaldetailinvoiceeditdetail = (state,invoicenumber,remark,totalprice,invoicedate,totalnumberofproduct,saleperson,customername,phonenumbervalue,paymentmethodvalue,paymentmethod1value,paymentmethod2,paymentmethod2value,paymentmethod3,paymentmethod3value,productchangeid,memberid,memberdiscountprice) => {
    this.gettradeindetails(invoicenumber);
    this.setState({
      [state]: !this.state[state],
      selectedinvoicenumber:invoicenumber,
      selectedtotalprice:totalprice,
      selectedinvoicedate:invoicedate,
      selectedtotalnumberofproduct:totalnumberofproduct,
      selectedsaleperson:saleperson,
      selectedcustomername:customername,
      selectedphonenumber:phonenumbervalue,
      selectedpaymentmethod1:paymentmethodvalue,
      selectedpaymentmethod2:paymentmethod2,
      selectedpaymentmethod3:paymentmethod3,
      selectedpaidvalue1:paymentmethod1value,
      selectedpaidvalue2:paymentmethod2value,
      selectedpaidvalue3:paymentmethod3value,
      selectedremark:remark,
      selectedproductchangeid:productchangeid,
      selectedmemberid:memberid,
      selectedmemberdiscountprice:memberdiscountprice,

    });
    console.log("paymethod2: " + paymentmethod2);

    if(paymentmethod2 == "" && paymentmethod3 == ""){
      this.setState({howmanypaymentmethod:"One"})     
    }else if(paymentmethod3 == "" && paymentmethod2 != ""){
      this.setState({howmanypaymentmethod:"Two"})
    }else{
      this.setState({howmanypaymentmethod:"Three"})
    }
    
    console.log("phonenumbervalue:" +phonenumbervalue)
    this.getcustomerlistmemberid();
  };
  
  gettradeindetails = (invoicenumber) =>{
    var urlencoded = new URLSearchParams();
   console.log("selectedinvoicenumber: " +invoicenumber )
   urlencoded.append("invoicenumber", invoicenumber);
urlencoded.append("usernamecontrol", this.state.userid);
   let link = this.state.linkurl +'mode=admin-gettradeinphone'
   fetch(link, {
     method: 'POST', 
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/x-www-form-urlencoded',
     },
     body:urlencoded
   })
   .then(response => response.json())
   .then(json => {
     console.log(json.success)
     //console.log(json)
     if(json.success == 1){
      
      console.log(json)
      this.setState({
        tradeinphone1:'',
    tradeinimei1:'',
    tradeinprice1:0,
    tradeinphone2:'',
    tradeinimei2:'',
    tradeinprice2:0,
    tradeinphone3:'',
    tradeinimei3:'',
    tradeinprice3:0,  
    selectedcreditcharge:json.creditcharge,
    });   
       var lengthemployee = Object.keys(json.employee).length;
       for(let i=0; i<lengthemployee;i++){
        this.setState({
          ["tradeinphone"+(i+1)]:json.employee[i].productname,
          ["tradeinimei"+(i+1)]:json.employee[i].imei,
          ["tradeinprice"+(i+1)]:json.employee[i].costprice,
        })
       }
       
    }
      else{
        this.setState({
          tradeinphone1:'',
      tradeinimei1:'',
      tradeinprice1:0,
      tradeinphone2:'',
      tradeinimei2:'',
      tradeinprice2:0,
      tradeinphone3:'',
      tradeinimei3:'',
      tradeinprice3:0,   
      selectedcreditcharge:json.creditcharge,
      });   
      
        this.setState({loading:false}) 
      console.log(json)
    }
  })
}

  gettdettailinvoicetable = (invoicenumber) =>{
    
   var urlencoded = new URLSearchParams();
   console.log("selectedinvoicenumber: " +invoicenumber )
   urlencoded.append("invoicenumber", invoicenumber);
urlencoded.append("usernamecontrol", this.state.userid);
   let link = this.state.linkurl +'mode=admin-selectedinvoicenumberdata'
   fetch(link, {
     method: 'POST', 
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/x-www-form-urlencoded',
     },
     body:urlencoded
   })
   .then(response => response.json())
   .then(json => {
     console.log(json.success)
     //console.log(json)
     if(json.success == 1){
      
      console.log(json)
      this.setState({producttablelist:[]});   
       var lengthemployee = Object.keys(json.employee).length;
       for(let i=0;i<lengthemployee;i++){
         var invoicedatetoshow = Moment(json.employee[i].invoicedate*1000).format('DD/MM/YYYY')
         var salesdatetoshow = Moment(json.employee[i].salesdate*1000).format('DD/MM/YYYY')
         var warrantystarttoshow = Moment(json.employee[i].warrantystart*1000).format('DD/MM/YYYY')
         var warrantyendtoshow = Moment(json.employee[i].warrantyend*1000).format('DD/MM/YYYY')
          this.setState({producttablelist:[...this.state.producttablelist,
            { no:i+1,
              ref:json.employee[i].Ref,
              invoiceno:json.employee[i].invoicenumber,   
              productname:json.employee[i].productname,     
              imei:json.employee[i].imei,
              storage:json.employee[i].storagevalue,
              category:json.employee[i].category,
              brand:json.employee[i].brandvalue,
              type:json.employee[i].typevalue,
              unitprice:json.employee[i].unitprice,
              quantity:json.employee[i].quantity,      
              salesdate:salesdatetoshow,
              warrantystart:warrantystarttoshow,     
              warrantyend:warrantyendtoshow,     
              costprice:json.employee[i].costprice,
              productchangeid:json.employee[i].productchangeid,
              productchangeimei:json.employee[i].productchangeimei,
              totalnumberofproduct:json.employee[i].totalnumberofproduct,
              totalprice:json.employee[i].totalprice,
              paymentmethod1value:json.employee[i].paymentmethod1value,
              paymentmethod2value:json.employee[i].paymentmethod2value,
              paymentmethod3value:json.employee[i].paymentmethod3value,
              productid:json.employee[i].productid,
            },            
          ]})
        
         
        }
       // this.setState({loading:false})  
      }        
         
   else{
    this.setState({producttablelist:[]}); 
    this.setState({loading:false}) 
  console.log(json)
}
    })
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
    
     // this.getaccessoriesstocklist();    
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
     // this.getorderlistapi("Import");
     }else{
      //this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
       // this.getsparepartstocklist();
       
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  searchfunctionsales = (value) =>{   
    
    this.setState({
      startDate:0,
      endDate:0,
    })
    this.setState({searchtextvalue:value})

    console.log("searchvalue: " + value);
      this.setState({loading:true})

      var urlencoded = new URLSearchParams();    
     urlencoded.append("searchvalue",value);
     urlencoded.append("usernamecontrol", this.state.userid);
    // urlencoded.append("category","Phone");
    let link = this.state.linkurl +'mode=admin-searchsalelist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({saleslist:[]});   
       var a=0;
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].server==this.state.server){
          var invoicedatetoshow = Moment(json.employee[i].invoicedate*1000).format('DD/MM/YYYY')
           this.setState({saleslist:[...this.state.saleslist,
             { no:a+1,
              ref:json.employee[i].Ref,
              invoiceno:json.employee[i].invoicenumber,   
              salesperson:json.employee[i].salesperson,             
              totalprice:json.employee[i].totalprice,
              invoicedate:invoicedatetoshow, 
              totalnumberofproduct:json.employee[i].totalnumberofproduct,  
              saleperson:json.employee[i].salesperson,  
              customername:json.employee[i].customername,  
              phonenumbervalue:json.employee[i].phonenumbervalue,  
              paymentmethodvalue:json.employee[i].paymentmethodvalue, 
              paymentmethod1value:json.employee[i].paymentmethod1value, 
              paymentmethod2:json.employee[i].paymentmethod2, 
              paymentmethod2value:json.employee[i].paymentmethod2value, 
              paymentmethod3:json.employee[i].paymentmethod3, 
              paymentmethod3value:json.employee[i].paymentmethod3value, 
              remark:json.employee[i].remark,
              imei:json.employee[i].imei,              
              phoneprefix:json.employee[i].phoneprefix,   
              memberdiscountprice:json.employee[i].memberdiscountprice,
              memberid:json.employee[i].memberid,
              productchangeid:json.employee[i].productchangeid,
             },            
           ]})
           a=a+1;
          }
          
         }
         this.setState({loading:false})  
       }        
          
    else{
     this.setState({saleslist:[]}); 
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
   
  }

  deletewholeinvoice = (invoicenumber) =>{
    //console.log("delete called")
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("invoicenumber", invoicenumber);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("usernamecontrol", this.state.userid);
    let link = this.state.linkurl +'mode=admin-deletesaleinvoice'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Delete Success"});
          this.setState({
            endDate:new Date,
            startDate:Moment(new Date).add(-1,'d'),
          })
          this.getsaleslist();
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggledeletemodal("deletemodalonoff",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.toggledeletemodal("deletemodalonoff",0)
      this.setState({loading:false}) 
      console.log(json)
      }
     })
  }

  deleteproductofinvoice = (id,productid) =>{
    //console.log("delete called")   
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("productid", productid);
    console.log("ID:" + id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("invoicenumber", this.state.selectedinvoicenumber);
    urlencoded.append("totalnumberofproduct", this.state.selectedtotalnumberofproduct-1);
    urlencoded.append("totalprice", parseFloat(this.state.selectedtotalprice)-parseFloat(this.state.selectedsubtotalfinal));
    urlencoded.append("payment1value", Math.round((parseFloat(this.state.selectedtotalprice)-parseFloat(this.state.selectedsubtotalfinal))/this.state.selectedtotalprice*parseFloat(this.state.selectedpaymentmethod1value)*100)/100);
    urlencoded.append("payment2value", Math.round((parseFloat(this.state.selectedtotalprice)-parseFloat(this.state.selectedsubtotalfinal))/this.state.selectedtotalprice*parseFloat(this.state.selectedpaymentmethod2value)*100)/100);
    urlencoded.append("payment3value", Math.round((parseFloat(this.state.selectedtotalprice)-parseFloat(this.state.selectedsubtotalfinal))/this.state.selectedtotalprice*parseFloat(this.state.selectedpaymentmethod3value)*100)/100);
   
    urlencoded.append("usernamecontrol", this.state.userid);

   // urlencoded.append("invoiceid", invoiceid);


    let link = this.state.linkurl +'mode=admin-deleteproductinvoice'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Delete Success"});
          this.setState({
            endDate:new Date,
            startDate:Moment(new Date).add(-1,'d'),
          })
          this.getsaleslist();
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggledeleteproductmodal("deletemodalproductonoff",0)
          this.toggledeleteproductmodal("detailsalemodalonoff",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.toggledeleteproductmodal("deletemodalproductonoff",0)
      this.setState({loading:false}) 
      console.log(json)
      }
     })
  }

  updateeditdetailsales = (invoicenumber) =>{


    var urlencoded = new URLSearchParams();
    urlencoded.append("invoicenumber", invoicenumber);
    urlencoded.append("userref", this.state.userid);
    
    urlencoded.append("selectedcustomername", this.state.selectedcustomername);
    urlencoded.append("selectedphonenumber", this.state.selectedphonenumber);


    if(this.state.howmanypaymentmethod == "Two"){
      urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
      urlencoded.append("selectedpaymentmethod2", this.state.selectedpaymentmethod2);
      urlencoded.append("selectedpaymentmethod3", "");
      urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
      urlencoded.append("selectedpaidvalue2", this.state.selectedpaidvalue2);
      urlencoded.append("selectedpaidvalue3", 0);
    }else if(this.state.howmanypaymentmethod == "Three"){
      urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
      urlencoded.append("selectedpaymentmethod2", this.state.selectedpaymentmethod2);
      urlencoded.append("selectedpaymentmethod3", this.state.selectedpaymentmethod3);
      urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
      urlencoded.append("selectedpaidvalue2", this.state.selectedpaidvalue2);
      urlencoded.append("selectedpaidvalue3", this.state.selectedpaidvalue3);
    }
    else{
      urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
      urlencoded.append("selectedpaymentmethod2", "");
      urlencoded.append("selectedpaymentmethod3", "");
      urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
      urlencoded.append("selectedpaidvalue2", 0);
      urlencoded.append("selectedpaidvalue3", 0);
    }

    urlencoded.append("selectedremark", this.state.selectedremark);
    urlencoded.append("usernamecontrol", this.state.userid);


    console.log("Selectedsaleperson: " + this.state.selectedsaleperson);
    urlencoded.append("selectedsaleperson", this.state.selectedsaleperson);
    urlencoded.append("selectedmemberid", this.state.selectedmemberid);
    urlencoded.append("selectedmemberdiscountprice", this.state.selectedmemberdiscountprice);


    let link = this.state.linkurl +'mode=admin-updateeditdetailsales'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log("success: " + json.success)
      console.log("message: "+ json.message)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
          this.setState({
            endDate:new Date,
            startDate:Moment(new Date).add(-1,'d'),
          })
          this.getsaleslist();
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggleModaldetailinvoiceeditdetail("editdetailinvoice",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:json.message});
      this.togglemessagemodal("messageboxmodalonoff")
      this.toggleModaldetailinvoiceeditdetail("editdetailinvoice",0)
      this.setState({loading:false}) 
      console.log(json)
    }
     })
  }

  selectedquantityfunction = (value) =>{
      this.setState({
        selectedquantity:value,
        selectedsubtotal:value*this.state.selectedunitprice,
      })
  }

  selectedimeifunction = (value)=>{
    this.setState({
      selectedimei:value
    })
    var lengthofphonelist = Object.keys(this.state.stockphonelist).length;
    for(let i=0;i<lengthofphonelist;i++){
      if(this.state.stockphonelist[i].imei == value){
        this.setState({
          selectedproductname:this.state.stockphonelist[i].productname,        
          selectedunitprice:this.state.stockphonelist[i].saleprice,
          selectedproductid:this.state.stockphonelist[i].productid,
          selectedimei:this.state.stockphonelist[i].imei,
          selectedcostprice:this.state.stockphonelist[i].costprice,
          selectedquantity:1,
          selectedsubtotal:this.state.stockphonelist[i].saleprice*1,
          selectedrepairprice:this.state.stockphonelist[i].repaircost,
     // selectedmemberid:this.state.stockphonelist[i].memberid,
        //  selectedproductchangeid:this.state.stockphonelist[i].productchangeid,
         // selectedmemberdiscountprice:this.state.stockphonelist[i].memberdiscountprice,
        
        })
      }
    }  
  }

  getcustomerlist = (value) =>{     
    this.setState({selectedphoneprefix:value})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    urlencoded.append("usernamecontrol", this.state.userid);
    let link = this.state.linkurl +'mode=admin-getcustomerlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({customerlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].phoneprefix == value){
            this.setState({customerlist:[...this.state.customerlist,
              { no:i+1,                
                title:json.employee[i].Contact,
                phone:json.employee[i].Contact,
                customername:json.employee[i].Name,
                memberid:json.employee[i].memberid,
              },      
          
                 
            ]})
          }
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  selectphonenumber = (value)=>{
    this.setState({selectedphonenumber:value})
    var lengthofphonelist = Object.keys(this.state.customerlist).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.customerlist[i].phone == value){
         this.setState({
          selectedcustomername:this.state.customerlist[i].customername,
          selectedmemberid:this.state.customerlist[i].memberid,
         })
       }
      
         }  
  }

  selectedproductnamefunction = (value) =>{
    this.setState({
      selectedproductname:value
    })

    if(this.state.selectedcategory == "Accessories"){
      var lengthofaccessorieslist = Object.keys(this.state.stockaccessorieslist).length;
      for(let i=0;i<lengthofaccessorieslist;i++){
  
          var nametoshow = this.state.stockaccessorieslist[i].productname + '---' +this.state.stockaccessorieslist[i].typevalue + '---' +this.state.stockaccessorieslist[i].brandvalue +'---'+ this.state.stockaccessorieslist[i].productid
         
          if(nametoshow == value){
           this.setState({
            selectedunitprice:this.state.stockaccessorieslist[i].saleprice,       
            selectedproductid:this.state.stockaccessorieslist[i].productid,
            selectedtype:this.state.stockaccessorieslist[i].typevalue,
            selectedbrand:this.state.stockaccessorieslist[i].brandvalue,
            selectedproductnameforupload:this.state.stockaccessorieslist[i].productname,
            selectedquantitymax:this.state.stockaccessorieslist[i].quantityvalue,
            selectedquantity:1,
            selectedsubtotal:this.state.stockaccessorieslist[i].saleprice,
            selectedcostprice:this.state.stockaccessorieslist[i].costprice,
            selectedrepairprice:this.state.stockaccessorieslist[i].repaircost,
          //  selectedmemberid:this.state.stockaccessorieslist[i].memberid,
           // selectedproductchangeid:this.state.stockaccessorieslist[i].productchangeid,
           // selectedmemberdiscountprice:this.state.stockaccessorieslist[i].memberdiscountprice,
           })
         }
        
           }  
    }else{
      var lengthofaccessorieslist = Object.keys(this.state.stocksparepartlist).length;
      for(let i=0;i<lengthofaccessorieslist;i++){
  
          var nametoshow = this.state.stocksparepartlist[i].productname + '---' +this.state.stocksparepartlist[i].typevalue + '---' +this.state.stocksparepartlist[i].brandvalue +'---'+ this.state.stocksparepartlist[i].productid
         
          if(nametoshow == value){
           this.setState({
            selectedunitprice:this.state.stocksparepartlist[i].saleprice,      
            selectedproductid:this.state.stocksparepartlist[i].productid,
            selectedtype:this.state.stocksparepartlist[i].typevalue,
            selectedbrand:this.state.stocksparepartlist[i].brandvalue, 
            selectedproductnameforupload:this.state.stocksparepartlist[i].productname,
            selectedquantitymax:this.state.stocksparepartlist[i].quantityvalue,
            selectedcostprice:this.state.stocksparepartlist[i].costprice,
            selectedsubtotal:this.state.stocksparepartlist[i].saleprice,
            selectedquantity:1,
            selectedrepairprice:this.state.stocksparepartlist[i].repaircost,
            //selectedmemberid:this.state.stocksparepartlist[i].memberid,
           // selectedproductchangeid:this.state.stocksparepartlist[i].productchangeid,
           // selectedmemberdiscountprice:this.state.stocksparepartlist[i].memberdiscountprice,
           })
         }
        }  
    }
    //"select * from stocktable where productname='Housing---Black---Normal---17' and typevalue='' and brandvalue='' and statusvalue='Ready For Sell'"
  }

  editunitprice = (value) =>{
    this.setState({selectedunitprice: value})
    this.setState({selectedsubtotal:value*1})

  }

  getcustomerlistmemberid = () =>{     

    

   
      var urlencoded = new URLSearchParams();
      urlencoded.append("empty", '-');
      let link = this.state.linkurl +'mode=admin-getcustomerlistmemberid'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        //console.log(json)
        if(json.success == 1){
         
         console.log(json)
         this.setState({customerlist:[]});   
          var lengthemployee = Object.keys(json.employee).length;
          for(let i=0;i<lengthemployee;i++){
              this.setState({customerlist:[...this.state.customerlist,
                { no:i+1,                
                  title:json.employee[i].Contact,
                  phone:json.employee[i].Contact,
                  customername:json.employee[i].Name,
                  memberid:json.employee[i].memberid,
                  title2:json.employee[i].memberid,
                  phoneprefix:json.employee[i].phoneprefix,
                 },      
            
                   
              ]})
            }
          }
            
           
    
    
        // this.setState({loading:false})  
              
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  
}

selectedmemberidfunction=(value)=>{
  this.setState({selectedmemberid:value})
  var lengthofphonelist = Object.keys(this.state.customerlist).length;
  for(let i=0;i<lengthofphonelist;i++){
     if(this.state.customerlist[i].memberid == value){
       this.setState({
        selectedcustomername:this.state.customerlist[i].customername,
        selectedphonenumber:this.state.customerlist[i].phone,
        selectedphoneprefix:this.state.customerlist[i].phoneprefix,
       })
     }
    
       }  
}

  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--6" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
        
            </div>
            <div className=" col-12 ">
              <Card className=" shadow  ">
              

              
                  <Row className="marginleftcss">                
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">From: </span>
                    </Col>
                    <Col md={2}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">To: </span>
                    </Col>
                    <Col md={2}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md={2}>
                    <Button  className={classnames("mb-sm-3 mb-md-0")} color="blue" type="button" style={{marginLeft:-10}} onClick={(e) => this.getsaleslist()}>
                         Search
                      </Button>
                    </Col>
                    
                    <Col md="5 form-inline  d-md-flex">


                        {/* Searchbar is here */}
                          {/* till here */}
                          {window.innerWidth <= 760 ?
                        <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>                   
                              <i className="fas fa-search" />                     
                            </InputGroupText>
                          </InputGroupAddon>

                            <Input
                              placeholder="Search" 
                              value={this.state.searchtextvalue}
                              onChange = {(event,value) => this.searchfunctionsales(value)}
                              type="text"
                             // onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                            />
                          
                        {this.state.searchtextvalue == "" ?
                        null:
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                          
                        <Button className="buttonsearch" onClick={(e)=>this.searchfunctionsales(this.state.searchtextvalue)}>Search</Button >
                          
                        </InputGroupText>
                        </InputGroupAddon>
                        }
                            
                            
                          
                        </InputGroup>
                        :
                        <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>

                          <i className="fas fa-search" />
                          
                        </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder="Search" 
                          value={this.state.searchtextvalue}
                          onChange = {(event) => this.searchfunctionsales(event.target.value)}
                          type="text"
                        //  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                        />

                        {this.state.searchtextvalue == "" ?
                        null:
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                      
                        <span className="buttonsearch" onClick={(e)=>this.searchfunctionsales(this.state.searchtextvalue)}>Search</span >

                        </InputGroupText>
                        </InputGroupAddon>
                        }
                        
                          

                        </InputGroup>
                        }



                        </Col>

                 </Row>
          

         
             
            
         
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
               
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  
                 <tr>
                   <th>No.</th>
                   <th >Invoice No</th>
                   <th >Invoice Date</th>
                   <th  >Sales Person</th>    
                   <th  >Total Quantity Product</th>       
                   <th  >Total Price</th>  
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.rendersaleslist()}
                    {Object.keys(this.state.invoicedetailslist).length<2?
                    <>
                    <tbody>
                      <tr>
                        <td>
                        </td>
                        </tr>
                    </tbody>
                    </>
                    :null  
}

                   </Table>
                  
             
                  </TabPane>
                   
                </TabContent>
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>
        
        {/*See Detail Modal Accessories*/}
      <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailsalemodalonoff}
          toggle={() => this.setState({detailsalemodalonoff:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="detailsalemodalonoff">
              Edit Product
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailsalemodalonoff:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">   
          <h5>
         Invoice No: {this.state.selectedinvoicenumber}
            </h5>
            <h5>
         Invoice Date: {this.state.selectedinvoicedate}
            </h5>        
            <h5>
         Total Quantity: {this.state.selectedtotalnumberofproduct}
            </h5> 
            <h5>
         Total Price: {this.state.selectedtotalprice}
            </h5>
          
           

        
            <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  
                 <tr>
                   <th>No.</th>
                   <th >Product Name</th>
                   <th >Type/IMEI</th>
                   <th>Storage</th>
                   <th  >Brand</th>    
                   <th  >Unit Price</th>       
                   <th  >Quantity</th> 
                   <th  >Subtotal</th>
                   <th  >Sales Date</th>   
                   <th  >Warranty Start</th>   
                   <th  >Warranty End</th>  
                   <th  >Remark</th>
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.renderproductsaletable()}                       
                   </Table>
                  
             
                  </TabPane>
                   
                </TabContent>
              


            
        </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailsalemodalonoff:false})}
            >
              Close
            </Button>
            {/*
            <Button color="primary" type="button" onClick={(e)=>this.toggleaddnewproduct("addnewproductmodalonoff",this.state.selectedinvoicenumber)}>
              Add Product
                </Button>*/}
          </div>
        </Modal>
     

         
         {/*Edit editdetailinvoice*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editdetailinvoice}
          toggle={() => this.setState({editdetailinvoice:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="editdetailinvoice">
              Edit Detail
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editdetailinvoice:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">   
          
          <h5>
         Invoice No: {this.state.selectedinvoicenumber}
            </h5>
            <h5>
         Invoice Date: {this.state.selectedinvoicedate}
            </h5>        
            <h5>
         Total Quantity: {this.state.selectedtotalnumberofproduct}
            </h5> 
            <h5>
         Total Price: {this.state.selectedtotalprice}
            </h5>
            <h5>
         Credit Charge: {this.state.selectedcreditcharge}
            </h5>
            {this.state.tradeinphone1 !="" && this.state.tradeinphone1 !=undefined?
            <>
            <h5>
            Trade in Phone 1: {this.state.tradeinphone1}
               </h5>
               <h5>
            Trade in IMEI 1: {this.state.tradeinimei1}
               </h5>
               <h5>
            Trade in Price 1: {this.state.tradeinprice1}
               </h5>
               </>
               :
               null
                }
                {this.state.tradeinphone2 !="" && this.state.tradeinphone2 !=undefined?
            <>
            <h5>
            Trade in Phone 2: {this.state.tradeinphone2}
               </h5>
               <h5>
            Trade in IMEI 2: {this.state.tradeinimei2}
               </h5>
               <h5>
            Trade in Price 2: {this.state.tradeinprice2}
               </h5>
               </>
               :
               null
                }
                {this.state.tradeinphone3 !="" && this.state.tradeinphone3 !=undefined?
            <>
            <h5>
            Trade in Phone 3: {this.state.tradeinphone3}
               </h5>
               <h5>
            Trade in IMEI 3: {this.state.tradeinimei3}
               </h5>
               <h5>
            Trade in Price 3: {this.state.tradeinprice3}
               </h5>
               </>
               :
               null
                }
         

            <h5>
         Sales Person:
            </h5> 
            <Autocomplete
                        options={this.state.userlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedsaleperson:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedsaleperson} variant="outlined"  />}  
                       disableClearable={true}
                      />     
        

        <h5>
         Find Member Method:
            </h5>
            <Autocomplete
                        options={[{title:'Member ID'},{title:'Phone'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({findcustomer:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.findcustomer} variant="outlined"  />}  
                       disableClearable={true}
                      />   

            {this.state.findcustomer == "Phone" ?
            <>
             <h5>
         Customer Phone Prefix:
            </h5>
            <Autocomplete
                        options={[{title:'010'},{title:'011'},{title:'012'},{title:'013'},{title:'014'},{title:'015'},{title:'016'},{title:'017'},{title:'018'},{title:'019'},{title:'+65'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.getcustomerlist(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedphoneprefix} variant="outlined"  />}  
                       disableClearable={true}
                      />   
        
        <h5>
         Customer Phone Number:
            </h5>
            <Autocomplete
                        options={this.state.customerlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectphonenumber(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedphonenumber} variant="outlined"  />}  
                       disableClearable={true}
                      />  
            </>
            :
            <>
            <h5>
               Member ID:
            </h5>
            <Autocomplete
                        options={this.state.customerlist}
                        getOptionLabel={(option) => option.title2}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectedmemberidfunction(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedmemberid} variant="outlined"  />}  
                       disableClearable={true}
                      /> 
                      </>
            }
           
           <h5>
         Member Discount:
            </h5>             
            <Input
                          type="number"
                          onChange={e => this.setState({selectedmemberdiscountprice: e.target.value})}
                          value={this.state.selectedmemberdiscountprice}
                          min={0}
                        />

                            <h5>
         Customer Name:
            </h5>             
            <Input
                          type="text"
                          onChange={e => this.setState({selectedcustomername: e.target.value})}
                          value={this.state.selectedcustomername}
                          disabled
                        />


            <h5>
         How Many Payment Method:
            </h5> 
            <Autocomplete
                        options={[{title:"One"},{title:"Two"},{title:"Three"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({howmanypaymentmethod:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.howmanypaymentmethod} variant="outlined"  />}  
                       disableClearable={true}
                      />  
                      
            <h5>
         Payment Method 1:
            </h5> 
            <Autocomplete
                        options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedpaymentmethod1:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod1} variant="outlined"  />}  
                       disableClearable={true}
                      /> 
 <h5>
             Paid Value 1:
                </h5>     
                <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({selectedpaidvalue1: e.target.value})}
                          value={this.state.selectedpaidvalue1}
                          
                        />

            {this.state.howmanypaymentmethod == "Two" ?
            <div>
            
                <h5>
             Payment Method 2:
                </h5> 
                <Autocomplete
                        options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedpaymentmethod2:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod2} variant="outlined"  />}  
                       disableClearable={true}
                      />  
                <h5>
                Paid Value 2:
                </h5>  
                <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({selectedpaidvalue2: e.target.value})}
                          value={this.state.selectedpaidvalue2}
                          
                        />
                </div>
            :
            null
            }
           

           {this.state.howmanypaymentmethod == "Three" ?
            <div>
            
                <h5>
             Payment Method 2:
                </h5> 
                <Autocomplete
                        options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedpaymentmethod2:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod2} variant="outlined"  />}  
                       disableClearable={true}
                      />  
                <h5>
                Paid Value 2:
                </h5>  
                <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({selectedpaidvalue2: e.target.value})}
                          value={this.state.selectedpaidvalue2}
                          
                        />

<h5>
             Payment Method 3:
                </h5> 
                <Autocomplete
                        options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedpaymentmethod3:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod3} variant="outlined"  />}  
                       disableClearable={true}
                      />  
                <h5>
                Paid Value 3:
                </h5>  
                <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({selectedpaidvalue3: e.target.value})}
                          value={this.state.selectedpaidvalue3}
                          
                        />
                </div>
            :
            null
            }


            <h5>
         Remark:
            </h5>       
            <Input
                          type="text"
                          onChange={e => this.setState({selectedremark: e.target.value})}
                          value={this.state.selectedremark}
                          
                        />

            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editdetailinvoice:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.updateeditdetailsales.bind(this,this.state.selectedinvoicenumber)}>
              Submit Edit
            </Button>
          </div>
        </Modal>
     

             
        {/*delete confirm*/}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.deletemodalonoff}
          toggle={() => this.toggledeletemodal("deletemodalonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="deletemodalonoff">
              Confirm
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeletemodal("deletemodalonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">Are you sure you want to delete this invoice?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeletemodal("deletemodalonoff")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.deletewholeinvoice.bind(this,this.state.selectedjobid)}>
              Confirm
            </Button>
          </div>
        </Modal>
     

          
        {/*delete confirm product*/}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.deletemodalproductonoff}
          toggle={() => this.toggledeleteproductmodal("deletemodalproductonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="deletemodalproductonoff">
              Confirm
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeleteproductmodal("deletemodalproductonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">Are you sure you want to delete this product?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeleteproductmodal("deletemodalproductonoff")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.deleteproductofinvoice.bind(this,this.state.selectedref,this.state.selectedproductid)}>
              Confirm
            </Button>
          </div>
        </Modal>
     

      {/*Message*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.messageboxmodalonoff}
          toggle={() => this.togglemessagemodal("messageboxmodalonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="messageboxmodalonoff">
              Message
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.togglemessagemodal("messageboxmodalonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">{this.state.messageformessagebox}</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.togglemessagemodal("messageboxmodalonoff")}
            >
              Close
            </Button>
         
          </div>
        </Modal>
     

   



      </>
    );
  }

  }


export default Sales;

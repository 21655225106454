/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar,Doughnut,Pie  } from "react-chartjs-2";
import "assets/vendor/nucleo/css/nucleo.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import {Helmet} from "react-helmet";
import Moment from 'moment';
class Dashboard extends React.Component {

  
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    salesperson:sessionStorage.getItem('name'),
    linkurl:"https://pos.mzprotrading.com/api/mzprotrading.php?",
    todaytotalsale:0,
    yesterdaytotalsale:0,
    changepercent:0,
    totalquantityphone:0,
    userid:localStorage.getItem('userid'),
    server:localStorage.getItem('server'),
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    this.gettotalsaletoday();
    this.getphonestockremain();
  }


  getphonestockremain = () =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    //sum of phone category quantity status in ready for sell

     //
    urlencoded.append("empty", '-');
    urlencoded.append("usernamecontrol", this.state.userid);
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-getstockremainphone'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
           this.setState({
             totalquantityphone:json.todayquantity,
           })
          }
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  gettotalsaletoday = () =>{
    var enddatetimestamp = Moment().endOf('day').unix();
    console.log(enddatetimestamp);
      var startdatetimestamp = Moment().startOf('day').unix();
    this.setState({loading:true})
    console.log(startdatetimestamp);
    var urlencoded = new URLSearchParams();
    //today time
    //invoice date between this time, and sum all their total price
    //yesterday time
     //invoice date between this time, and sum all their total price

     //
     urlencoded.append("startdate",startdatetimestamp);
     urlencoded.append("enddate",enddatetimestamp);
    urlencoded.append("salesperson", this.state.salesperson);
    urlencoded.append("usernamecontrol", this.state.userid);
    urlencoded.append("server", this.state.server);
    let link = this.state.linkurl +'mode=admin-gettotalsaletoday'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
if(json.yesterdaytotal>0){
          var increasepercent = ((json.todaytotal - json.yesterdaytotal)/json.yesterdaytotal).toFixed(1)+'%'
}
else{
  var increasepercent="No Yesterday Sales"
}
          this.setState({
            todaytotalsale:json.todaytotal,
            yesterdaytotalsale:json.yesterdaytotal,
            changepercent:increasepercent,
          })
console.log(json)
          
        
      }
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }
 



  render() {
    //const {id} = this.props.location.state
  
    return (
      <>
       <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>MZ Pro Trading</title>
            </Helmet>
        </div>
        <Header  />
        {/* Card stats */}
        <Container className="mt--7" fluid>
        {this.state.loadingdashboard == true?
         <Row className="mt-9">
         <Col md="12" >
       <img 
       className="img-center"
       width="120"
       height="120"
       alt="..."
       src={require("./../assets/gif/lorrygif3.gif")}
     />
     </Col>
       </Row>
        :
        <div>
         
        <Row>
                <Col lg="12" xl="12">
                  <Card className="card-stats mb-5 mb-xl-5">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Today Sales:
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                          {this.state.todaytotalsale}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="ni ni-delivery-fast" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.changepercent < 0 ?
                              <span className="text-danger mr-2">
                              <i className="fa fa-arrow-down" /> {Math.round(this.state.percentdeliver * 100) / 100}%
                            </span>
                        :
                        <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> {this.state.changepercent}
                      </span>
                        }
                  
                        <span className="text-nowrap">Since yesterday</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="12" xl="12">
                  <Card className="card-stats mb-5 mb-xl-5">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Phone Stock Remain (Ready For Sell Phone):
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                           {this.state.totalquantityphone}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="ni ni-time-alarm" />
                          </div>
                        </Col>
                      </Row>
                   
                    </CardBody>
                  </Card>
                </Col>
               
              </Row>
      



  </div>
        }
        
        </Container>
     
     
     
      </>
    );
  }
}

export default Dashboard;
